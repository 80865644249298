בקרוב...
<!-- <div [hidden]="!isLoadDetails" class="loading ">    
    <loaders-css [loader]="dataService.loader" [scale]="2" [color]="dataService.color"></loaders-css>
    <div class="loading-txt">טוען נתונים...</div>    
</div>
<div class="container" [hidden]="isLoadDetails" >
    <div class="data-content">
        <div class="students1 card-item ">
            <div class="students1-icon icon"></div>
            <div class="count">{{recordQantity.StudentsInAccount}}</div>
            <div class="title">תלמידים מוסעים במוסד</div>
        </div>
        <div class="cars card-item ">
            <div class="cars-icon icon"></div>
            <div class="count">{{recordQantity.Cars}}</div>
            <div class="title">רכבי הסעה</div>
        </div>
        <div class="students2 card-item ">
            <div class="students2-icon icon"></div>
            <div class="count">{{recordQantity.StudentsToEdit}}</div>
            <div class="title">תלמידים מוסעים לטיוב</div>
        </div>
        <div class="rides card-item ">
            <div class="rides-icon icon"></div>
            <div class="count">{{recordQantity.DailyRides}}</div>
            <div class="title">נסיעות יומיות</div>
        </div>

    </div>

    <div class="messages-content">
        <div class="messages-title">הודעות חדשות מהמשל"ט</div>

        <div class="messages-list">        
            <div *ngIf="noResults" class="no-results-msg">אין הודעות חדשות.</div>

            <div class="list-group-item dashboard-list-record" *ngFor="let msg of dashboardData.Messages; let i = index" (click)="GoToMessage(msg.MessageID)">
                <div class="status-right col-1">
                    <div class="" [ngClass]="{'status-read':msg.IsRead,'status-unread': !msg.IsRead}"></div>
                </div>
                <div class="content-left col-10">                
                    <div class="dashboard-subject">{{msg.Subject}}</div>
                    <div class="dashboard-date">{{datepipe.transform(msg.CreatedOn,'dd/MM/yyyy')}}</div>
                    <div class="dashboard-description">{{msg.Description}}</div>
                </div>  
                <div class="file-attach col-1" *ngIf="msg.AttachedFiles.length>0">
                    <div class="attachment" ></div>
                </div>                                                  
            </div>
        </div>

    </div>
    
    
     <div class="data-content">
        <div class="card col-3 d-flex flex-column justify-content-start align-items-center">
            <img class="icon "  src="../../../assets/deshboard-icons/multiple-users-silhouette.svg" >
            <span class="count ">{{recordQantity.StudentsInAccount}}</span>
            <p class=" "> תלמידים מוסעים במוסד</p>            
        </div>
        <div class="card col-3 d-flex justify-content-start align-items-center flex-column ">            
            <img src="../../../assets/deshboard-icons/bus.svg" class="icon">
            <span class="count " >{{recordQantity.Cars}}</span>
            <p class=" " > רכבי הסעה</p>
        </div>
        <div class="card col-3 d-flex justify-content-start align-items-center flex-column">            
            <img src="../../../assets/deshboard-icons/group.svg" class="icon">
            <span  class="count ">{{recordQantity.StudentsToEdit}}</span>
            <p class="" > תלמידים מוסעים לטיוב</p>
        </div>
        <div class="card col-3 d-flex justify-content-start align-items-center flex-column">            
            <img src="../../../assets/deshboard-icons/daily-calendar.svg" class="icon">
            <span class="count ">{{recordQantity.DailyRides}}</span>
            <p class=" " > נסיעות יומיות</p>
        </div> -->
    

    <!-- <div class="first-row row d-flex justify-content-center">
        <div class="card col-3 d-flex flex-column justify-content-start align-items-center">
            <img class="icon "  src="../../../assets/deshboard-icons/multiple-users-silhouette.svg" >
            <span class="count ">{{recordQantity.StudentsInAccount}}</span>
            <p class=" "> תלמידים מוסעים במוסד</p>
            
        </div>
        <div class="card col-3 d-flex justify-content-start align-items-center flex-column ">            
            <img src="../../../assets/deshboard-icons/bus.svg" class="icon">
            <span class="count " >{{recordQantity.Cars}}</span>
            <p class=" " > רכבי הסעה</p>
        </div>
        <div class="card col-3 d-flex justify-content-start align-items-center flex-column">            
            <img src="../../../assets/deshboard-icons/group.svg" class="icon">
            <span  class="count ">{{recordQantity.StudentsToEdit}}</span>
            <p class="" > תלמידים מוסעים לטיוב</p>
        </div>
    </div>
    <div class="second-row row d-flex justify-content-center">
        <div class="card col-3 d-flex justify-content-start align-items-center flex-column">            
            <img src="../../../assets/deshboard-icons/email.svg" class="icon">
            <span class="count ">{{text}}</span>
            <p class=" " >הודעות חדשות מהמשל"ט</p>
        </div>
        <div class="card col-3 d-flex justify-content-start align-items-center flex-column">            
            <img src="../../../assets/deshboard-icons/daily-calendar.svg" class="icon">
            <span class="count ">{{recordQantity.DailyRides}}</span>
            <p class=" " > נסיעות יומיות</p>
        </div>
    </div> 
</div>


 -->
