import { InquiryEscort } from "./InquiryEscort";
import { Lookup } from "./Lookup";

export class InquiryDataLists{

     Types : Array<Lookup> ;
     Escorts : Array<InquiryEscort> ;
     Students : Array<Lookup> ;
     Supervisors : Array<Lookup> ;
     Suppluyers : Array<Lookup> ;
        
}