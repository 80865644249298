<div class="wrapping-border">

    <div class="d-flex orang-title align-items-center">
        <i class="id-icon"></i>
        <div class="orang-text">מערכת שעות למוסד</div>
    </div>

    <div id="hours" class="margin-top-10">
        <div class="tableHeader d-flex  ">
            <div class="col-2"> יום </div>
            <div class="col-5"> איסוף </div>
            <div class="col-5"> פיזור </div>
        </div>
        <ul class="list-group">
            <li *ngFor="let day of days" class="list-group-item d-flex justify-content-center align-items-center">
                <div class="col-2">{{day}}</div>
                <div class="col-5"> <input type="text" class="form-control mb-2" placeholder="7:00" maxlength="5"></div>
                <div class="col-5"> <input type="text" class="form-control mb-2" placeholder="13:00" maxlength="5">
                </div>
            </li>
        </ul>
    </div>

    <div class="d-flex flex-column margin-top-10">
        <button class="button save-button" (click)="save()" [hidden]="afterSaving" [disabled]="preserving">שמור</button>
        <button class="cancel-save-button button" (click)="close()" [disabled]="preserving">נקה</button>
    </div>

</div>