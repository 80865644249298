import { InputParams } from './InputParams';

export class StudentRequestModel {
    public constructor() {

    }
    public Action: string;
    public MultipleInputParams: Dictionary[] = new Array();
    public InputParamsDictionary: Dictionary = {};
    public additinal: string;
    //public InputParamsDictionary: any;
}

export interface Dictionary {
    [key: string]: any;
}

export interface DictionaryValue {
    value: string;
    isChange: boolean;
    _type?: string;
}