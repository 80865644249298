import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FormGroup, FormBuilder, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { Contact } from 'src/app/models/Contact';
import { StudentService } from 'src/app/services/student.service';
import { IdentityNumberValidator } from 'src/app/services/IdentityNumberValidator';

@Component({
  selector: 'app-create-contact-dialog',
  templateUrl: './create-contact-dialog.component.html',
  styleUrls: ['./create-contact-dialog.component.scss']
})
export class CreateContactDialogComponent implements OnInit {
  public showDefect: boolean = false;
  public isUpdate: boolean = false;
  public form: FormGroup;
  public isActiveEventExsist: boolean = false;
  searchContactByTz = false;
  submitted: boolean = false;
  searchStudentLoading: boolean = false;


  //public onlyHebrewPattern = "/^[א-ת\s]+$/";

  constructor(public dataService: DataService,
    private fb: FormBuilder,
    public studentService: StudentService,
    private dialogRef: MatDialogRef<CreateContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: Contact) {
    /*debugger*/
    this.isActiveEventExsist = data.isActiveEventExsist;

    this.SetFormFields(data);

  }

  private SetFormFields(data: Contact) {

    this.form = new FormGroup({
      new_contactnumber: new FormControl(data.new_contactnumber),
      new_class1: new FormControl(null, Validators.required),
      //
      //FirstName: new FormControl(data.FirstName, [Validators.required, Validators.pattern('/^[א-ת\s]+$/')]),
      FirstName: new FormControl(data.FirstName),
      LastName: new FormControl(data.LastName),
      //new_transportationnecessity: new FormControl(null),new_meunianbehasaha
      new_meunianbehasaha: new FormControl(null, Validators.required),
      new_no_want_ride_from: new FormControl(data.new_no_want_ride_from),
      new_no_want_ride_due: new FormControl(data.new_no_want_ride_due),
      BirthDate: new FormControl(data.BirthDate, Validators.required),
      new_sugasaa: new FormControl(null),
      MobilePhone: new FormControl(data.MobilePhone, Validators.required),
      Telephone1: new FormControl(data.Telephone1),
      // new_defectid: new FormControl(null, Validators.required),
      new_sughinuch: new FormControl(null, Validators.required),
      GenderCode: new FormControl(null, Validators.required),
      new_tzaharon: new FormControl(null),
      new_address1_line1: new FormControl(data.new_address1_line1, Validators.required),
      new_address1_line2: new FormControl(data.new_address1_line2, Validators.required),
      new_address1_comment: new FormControl(data.new_address1_comment),
      new_address1_city: new FormControl(data.new_address1_city),
      new_cityid1: new FormControl(null, Validators.required),
      new_address1_postalcode: new FormControl(data.new_address1_postalcode),
      new_address2_line1: new FormControl(data.new_address2_line1),
      new_address2_line2: new FormControl(data.new_address2_line2),
      new_address2_city: new FormControl(data.new_address2_city),
      new_cityid2: new FormControl(null),
      new_address2_postalcode: new FormControl(data.new_address2_postalcode),
      new_contactneedsid: new FormControl(data.new_contactneedsid),
      new_contactid: new FormControl(data.new_contactid),
      openDate: new FormControl(new Date().toJSON().slice(0, 10)),
      new_contactnewstatus: new FormControl(data.new_contactnewstatus != undefined ? data.new_contactnewstatus : "תלמיד חדש")
    });
    this.form.controls['new_meunianbehasaha'].setValue(
      this.getOption(data.new_meunianbehasaha, this.studentService.MeunianBehasahaList));

    if (data.new_meunianbehasaha == "100000006") {
      this.form.get('new_no_want_ride_from').setValidators(Validators.required);
      this.form.get('new_no_want_ride_due').setValidators(Validators.required);
      this.form.get('new_no_want_ride_from').updateValueAndValidity();
      this.form.get('new_no_want_ride_due').updateValueAndValidity();
    }



    // if (data.new_sugasaa !== undefined && data.new_sugasaa !== "") {
    //   switch (data.new_sugasaa) {
    //     case "100000006":
    //       break;
    //     case "100000007":
    //       break;
    //     default:
    //       data.new_sugasaa = "100000003"
    //       break;
    //   }
      //   this.form.controls['new_sugasaa'].disable();
   // }
    this.form.controls['new_sugasaa'].setValue(
      this.getOption(data.new_sugasaa, this.studentService.SugHasaaList));



    this.form.controls['new_sughinuch'].setValue(
      this.getOption(data.new_sughinuch, this.studentService.SugChinuchList));

    // this.form.controls['new_defectid'].setValue(
    // this.getOption(data.new_defectid, this.studentService.DeffectsList));

    // if (data.new_defectid !== undefined && data.new_defectid !== "") {
    // this.form.controls['new_defectid'].disable();
    // }

    this.form.controls['new_cityid1'].setValue(
      this.getOption(data.new_cityid1, this.studentService.CitiesList));

    this.form.controls['new_cityid2'].setValue(
      this.getOption(data.new_cityid2, this.studentService.CitiesList));

    this.form.controls['GenderCode'].setValue(
      this.getOption(data.GenderCode, this.studentService.GenderCodesList));

    this.form.controls['new_tzaharon'].setValue(
      this.getOption(data.new_tzaharon, this.studentService.TzaharonList));

    this.form.controls['new_class1'].setValue(
      this.getOption(data.new_class1, this.studentService.ClassesList));

    if (data.new_contactneedsid != null) {
      this.isUpdate = true;
      //this.myForm.get('myEmailField').setValidators(Validators.required)

    }
    else {//if it isnt update
      this.form.get('new_contactnumber').setValidators([Validators.required, IdentityNumberValidator.isValidTZ]);
      this.form.get('FirstName').setValidators(Validators.required);
      this.form.get('LastName').setValidators(Validators.required);

      //תעדכן מועד פעילות אחרון אם זה יצירה
      this.studentService.SetActive().then((res: string) => {
      }).catch(error => { debugger; })
    }
  }


  ngOnInit() {




  }
  save() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      alert("יש להזין ערכים תקינים בכל שדות החובה המודגשים")
      return;
    }
    else
      this.dialogRef.close(this.form);
  }
  sugChinuchChanged() {
    if (this.form.controls.new_sughinuch.value.value !== "100000001") {
      // this.form.get('new_defectid').clearValidators();
    }
    else {
      // this.form.get('new_defectid').setValidators(Validators.required);
    }
    // this.form.get('new_defectid').updateValueAndValidity();
  }

  meunianBehasahaChanged() {
    if (this.form.controls.new_meunianbehasaha.value.value !== "100000006") {
      this.form.get('new_no_want_ride_from').clearValidators();
      this.form.get('new_no_want_ride_due').clearValidators();
    }
    else {
      this.form.get('new_no_want_ride_from').setValidators(Validators.required);
      this.form.get('new_no_want_ride_due').setValidators(Validators.required);
    }
    this.form.get('new_no_want_ride_from').updateValueAndValidity();
    this.form.get('new_no_want_ride_due').updateValueAndValidity();
  }

  get f() { return this.form.controls; }

  close() {
    this.getFormValidationErrors()
    this.dialogRef.close();
  }
  getFormValidationErrors() {

    Object.keys(this.form.controls).forEach(key => {

      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  getOption(value: string, options: any) {
    return value != null ?
      options.filter(m => { return m.value == value })[0] :
      null;
  }

  getStudent() {
    /*debugger*/;
    if (!this.searchContactByTz) {
      this.searchContactByTz = true;
      this.studentService.request.InputParamsDictionary["new_contactnumber"] = this.form.controls.new_contactnumber.value;
      this.searchStudentLoading = true;
      this.studentService.GetStudentDetailesForCreate().then((res: string) => {
        if (res != null && !this.dataService.IsError(res)) {

          let contactToUpdate: Contact = JSON.parse(res)
          this.SetFormFields(contactToUpdate);
          //this.studentService.request.InputParamsDictionary["moveFromAnotherAccount"] = "true";
          // }
        }
        this.searchStudentLoading = false;
      });
    }
  }
  shouldDisableOption(sugasaha) {
    var res = sugasaha.value != "100000006" && sugasaha.value != "100000007" && sugasaha.value != "100000003";
    return res;
  }
  shouldShowOption(sugasaha) {
    var res = ((!this.isUpdate) && sugasaha.value == "100000006" || sugasaha.value == "100000007" || sugasaha.value == "100000003") || this.isUpdate;
    return res;
  }
}

