import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { StudentSchedule } from '../models/StudentSchedule';
import { StudentScheduleRequest } from '../models/StudentScheduleRequest';
import { OptionSet } from '../models/OptionSet';

@Injectable({
  providedIn: 'root'
})
export class StudentScheduleService {
  
  
  public rout = "StudentSchedulesActions";

request: StudentScheduleRequest;
public ScheduleHoursList: Array<OptionSet> = new Array<OptionSet>();

  constructor(private http: HttpClient,
    public dataService: DataService) {
      this.request = new StudentScheduleRequest();
     }
     SaveStudentSchedule() {
       this.request.Action = "SaveStudentSchedule";
      return this.dataService.setRequstData(this.rout, this.request);
    }

     GetModalOptions() {
      this.request.Action = "GetModalOptions";
      return this.dataService.setRequstData(this.rout, this.request);
    }
     GetStudentSchedule() {
       this.request.Action = "GetStudentSchedule";
      return this.dataService.setRequstData(this.rout, this.request);
  }
}
