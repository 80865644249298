import { StudentSchedule } from './StudentSchedule';
import { DataService } from '../services/data.service';

export class StudentScheduleRequest{
    
    public constructor( ) {
         
    }
    public studentSchedule: StudentSchedule;
    public studentScheduleId: string;
    public Action: string;
    public Token: string;

    
}