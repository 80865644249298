<div class="content">

    <div class="component-title">
        <app-component-title componentName="מסלולים" IconName="RoutesIcon">
        </app-component-title>

    </div>
    <div class="component-content">

        <div *ngIf="false">
            <!-- <div [hidden]="!isFetching" class="saveLoading">מכין נתונים...
                <loaders-css [loader]="dataService.saveLoader" [scale]="1" [color]="dataService.color"></loaders-css>
            </div>
            <div [hidden]="!isSaving" class="saveLoading">שומר...
                <loaders-css [loader]="dataService.saveLoader" [scale]="1" [color]="dataService.color"></loaders-css>
            </div> -->
        </div>
        <div class="scheduleTable">
            <table>
                <thead>
                    <tr>
                        <!-- <th>בחר</th> -->
                        <th>מזהה מסלול</th>
                        <th>שם מסלול</th>
                        <th>שם מלווה</th>
                        <th>טלפון מלווה</th>
                        <th class="last-th">תלמידים במסלול</th>
                    </tr>
                </thead>
                <div [hidden]="!isLoadRouteData" class="loading">
                    <loaders-css [loader]="dataService.loader" [scale]="2" [color]="dataService.color"></loaders-css>
                </div>
                <tbody *ngIf="!isLoadRouteData">
                    <tr *ngFor="let route of routesList">
                        <!-- <td class="first-td">
                            <label class="container checkbox d-flex justify-content-center">
                                <input type="checkbox" [(ngModel)]="vacation._checked"
                                    (change)="onSelectVacation(vacation)">
                                <span class="checkmark"></span>
                            </label> 
                        </td> -->
                        <td>{{route.Number}}</td>
                        <td>{{route.Name}}</td>
                        <td>
                            <ng-container *ngIf="route.Escort">{{route.Escort.Name}}</ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="route.Escort">{{route.Escort.Phone}}</ng-container>
                        </td>
                        <td class="last-td">{{route.Students }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>