// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  //api: "http://localhost:14899/",

   api: "https://mosadot.heseim.co.il/api/",//env
  //  api: "https://mosadottest.heseim.co.il/api/",//test
  // api: "https://test.heseim.co.il/mosadotApi/api/",//test - for developers
  // apiUrl: '/api/api/',//trustech - for developers

  routes: {
    login: { url: 'login' },

  },
  password: "Test12!"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
