import { Injectable } from '@angular/core';
import { Lookup } from '../models/Lookup';
import { OptionSet } from '../models/OptionSet';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class InstitutionDetailsService {

  SugChinuchList: OptionSet[];
  PikuachList: OptionSet[];
  MigzarList: OptionSet[];
  ShlavChinuchList: OptionSet[];
  MosadTypeList: OptionSet[];
  SchoolBusList: OptionSet[];
  DaysList: OptionSet[];
  ClassesList: OptionSet[];
  GendersList: OptionSet[];
  CitiesList: Lookup[];
  rout: string = "InstitutionDetailsActions";

  constructor(public dataService: DataService) { }

  GetInstitutionDetails() {
    let request = { Action: "GetInstitutionDetails" };
    return this.dataService.setRequstData(this.rout, request);
  }

  setLists(data) {    
    this.CitiesList = data.CitiesList;
    this.ClassesList = data.ClassesList;
    this.DaysList = data.DaysList;
    this.GendersList = data.GendersList;
    this.MigzarList = data.MigzarList;
    this.MosadTypeList = data.MosadTypeList;
    this.PikuachList = data.PikuachList;
    this.SchoolBusList = data.SchoolBusList;
    this.ShlavChinuchList = data.ShlavChinuchList;
    this.SugChinuchList = data.SugChinuchList;
  }

  saveInstitutionDetails(formValues) {    
    let request = { Action: "UpdateInstitutionDetails", Institution: formValues };
    return this.dataService.setRequstData(this.rout, request);
  }

}
