<form [formGroup]="formLogin_2" (ngSubmit)="checkSms(formLogin_2)">
    <div class="field">
        <div class="f1">מזהה נשלח לטלפון הנייד שמסתיים במספר הבא:</div>
        <div class="hashPhone ">{{dataLogin.hashPhone}}</div>
        <div class=" f5">נבקש כעת לאמת את זהותך באמצעות קוד חד-פעמי שהגיע למכשירך</div>

        <div class="title  code-title"> קוד אימות:</div>
        <input OnlyNumber="true" class="sms" type="tel" formControlName="sms" required minlength="6" maxlength="6">


    </div>

    <div class="buttom-form">
        <div class="Loader" [hidden]="! dataLogin.Loader"></div>
        <div class="submit-bottom">
            <button type="button" *ngIf="isExtraIdAvailable" (click)="sendIdentityAgain()"
                class="f5 send-extra-identity ">שלח
                מזהה נוסף</button>
            <button type="button" *ngIf="isExtraIdAvailable" (click)="sendVoiceIdentity()"
                class="f5 send-extra-identity ">שלח מזהה באמצעות הודעה
                קולית</button>
        </div>
        
        <div class="submit-bottom">
            <button type="submit" class="button-connect" [ngClass]="{'disabled' : !formLogin_2.valid }"
                [disabled]="!formLogin_2.valid">התחבר</button>
        </div>
    </div>

</form>