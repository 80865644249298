import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { error } from 'console';
import { File } from 'src/app/models/File';
import { FileToUpload } from 'src/app/models/FileToUpload';
import { InquiriesRequest } from 'src/app/models/InquiriesRequest';
import { InquiryDataLists } from 'src/app/models/InquiryDataLists';
import { DataService } from 'src/app/services/data.service';
import { InquiryService } from 'src/app/services/inquiry.service';
import { CreateContactDialogComponent } from '../update-contact-dialog/create-contact-dialog.component';

@Component({
  selector: 'app-create-inquiry-dialog',
  templateUrl: './create-inquiry-dialog.component.html',
  styleUrls: ['./create-inquiry-dialog.component.scss']
})
export class CreateInquiryDialogComponent implements OnInit {



  dataLists: InquiryDataLists = new InquiryDataLists();
  isLoadingData: boolean = false;
  inquiryForm: FormGroup;
  preserving: boolean = false;
  afterSaving: boolean = false;
  sucsssMassage: string = "";
  optValue = '';
  attachedFiles: Array<File> = [];  

  constructor(private dialogRef: MatDialogRef<CreateInquiryDialogComponent>,
    private inquiryService: InquiryService,
    public dataService: DataService,
    public datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) data: InquiryDataLists) {
    this.dataLists = data;
    this.SetFormFields();
  }

  ngOnInit() {
  }

  SetFormFields() {
    this.inquiryForm = new FormGroup({
      type: new FormControl(),
      subject: new FormControl(),
      inquiryStatus: new FormControl({value:'פתוח', disabled:true}),
      description: new FormControl(),
      escort: new FormControl(),
      student: new FormControl(),
      suppluyer: new FormControl(null),
      supervisor: new FormControl(),     
      date: new FormControl({value:this.datepipe.transform(new Date(), 'dd/MM/yyyy'), disabled:true}),
      email: new FormControl(this.dataService.userModel.ContactMail),
      phone: new FormControl({value:this.dataService.userModel.ContactPhone, disabled:true}),
      fullName: new FormControl({value:this.dataService.userModel.AcountFullName, disabled:true}),
      getUpdatesInEmail: new FormControl(true),
    });

  }

  LoadData() {
    this.inquiryService.GetDataLists().then((res: any) => {
      if (!this.dataService.IsError(res)) {
        this.dataLists = res;
      }
    }).catch(error => { console.log(error); debugger; });
  }

  close() {
    // if(this.afterSaving){
    //   this.afterSaving = false;
    // }
    this.dialogRef.close();
  }

  save() {    
    if (!this.afterSaving) {          
      this.preserving = true;
      let inquiryRequest: InquiriesRequest = new InquiriesRequest();

      inquiryRequest.CaseType = this.inquiryForm.value.subject;
      inquiryRequest.Description = this.inquiryForm.value.description;
      inquiryRequest.Escort = this.inquiryForm.value.escort;
      inquiryRequest.Student = this.inquiryForm.value.student;
      inquiryRequest.Rakaz = this.inquiryForm.value.supervisor;
      inquiryRequest.Suppluyer = this.inquiryForm.value.suppluyer;
      inquiryRequest.Email = this.inquiryForm.value.email;
      this.dataService.userModel.ContactMail = this.inquiryForm.value.email;
      inquiryRequest.UpdatesByEmail = this.inquiryForm.value.getUpdatesInEmail;
      inquiryRequest.AttachedFiles = this.attachedFiles;
      this.inquiryService.request = inquiryRequest;

      this.inquiryService.CreateInquiry().then((res: any) => {
        if (!this.dataService.IsError(res)) {
          this.preserving = false;
          this.afterSaving = true;                  
          this.sucsssMassage = res.Subject;
        }
      }).catch(error => { console.log(error); this.preserving = false; debugger; });
    }
    else {
      this.afterSaving = false;
      this.dialogRef.close();
    }
  }

  OnSwitch(event: any) {
    this.inquiryForm.value.getUpdatesInEmail = !this.inquiryForm.value.getUpdatesInEmail;
  }

  AddFile(files: Array<any>) {
    if (this.attachedFiles.length + files.length > 3) {
      alert('אין אפשרות להעלות יותר מ- 3 קבצים');
    }
    else {
      this.readAndUploadFile(files[0]);
    }
  }

  readAndUploadFile(theFile: any): any {
    let file = new File();
    
    // Set File Information    
    file.FileName = theFile.name;

    // Use FileReader() object to get file to upload    
    var reader = new FileReader();

    // Setup onload event for reader
    reader.onloadend = () => {
      // Store base64 encoded representation of file
      file.Content = reader.result.toString();
      this.attachedFiles.push(file);
    }

    // Read the file
    reader.readAsDataURL(theFile);
  }

  RemoveFile(i) {
    let tmpArr = this.attachedFiles;
    tmpArr.splice(i, 1);
    this.attachedFiles = tmpArr;
  }

}
