import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { UserModel } from '../models/User';
import { HttpClient } from '@angular/common/http';
import { StudentRequestModel } from '../models/StudentRequestModel';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { interval, Subscription, Subject, BehaviorSubject, timer, Observable } from 'rxjs';
import { LoadersCSS } from 'ngx-loaders-css';
import { promise } from 'protractor';
import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service
import { OptionSet } from '../models/OptionSet';
import { RecordQantity } from '../models/RecordQantity';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  public loader: LoadersCSS = 'ball-beat';
  public saveLoader: LoadersCSS = 'line-scale-pulse-out';
  public color = 'rgba(100, 100, 100, 0.5)';
  public notAllowedTxt1 = "שים לב! מידע לצפיה בלבד. לא ניתן להוסיף / לעדכן  "
  public notAllowedTxt2 = " בתקופה זו. למידע נוסף נא לפנות למוקד שירות ותמיכה"
  public loading: boolean = false;
  public isLoggedIn: boolean = false;
  body: string;
  public userModel: UserModel;
  readonly Hour: number = 1000 * 60 * 60;
  private isRefresh: Subject<boolean> = new BehaviorSubject(false);
  public years: Array<OptionSet> = new Array<OptionSet>();
  public institutions: Array<any>;
  selectedAccount: any;
  public recordsQantities: RecordQantity;
  public newMessagesNumber: number = 0;


  refreshPageSubject = new Subject<void>();


  constructor(private bnIdle: BnNgIdleService,
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService) {
    this.userModel = new UserModel();

  }

  UpdateMessagesNumber(n: number) {
    this.newMessagesNumber = n >= 0 ? n : 0;
  }

  IncrementMessagesNumber(n: number) {
    this.newMessagesNumber += n;

  }

  SetActivity() {
    setTimeout(() => { this.TimeOut(); },
      this.Hour)
  }

  get isRefresh$() {
    return this.isRefresh.asObservable();
  }

  SetIsRefresh(val: boolean) {
    this.isRefresh.next(val);
  }




  TimeOut() {
    this.cookieService.deleteAll();
    this.isLoggedIn = false;
    this.userModel = new UserModel();
    window.location.reload();
    this.router.navigateByUrl('');
  }

  refesh(componentName: string) {

    if (componentName == "") {
    }

  }



  SavedSuccessfuly() {
    alert("השמירה בוצעה בהצלחה")
  }

  public setRequstData(rout: string, request: any) {
    this.SetActivity();

    request.Token = this.userModel.Token;
    request.CurrentYear = this.userModel.Year.value;
    request.InstitutionNumber = this.userModel.InstitutionNumber;

    this.body = JSON.stringify(request);
    return this.http
      .post(
        environment.api + rout,
        this.body,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      .toPromise()
      .catch(error => {

        console.log(error);
      })

  }

  IsError(response: string) {
    let isError: boolean = true;
    switch (response) {
      case "error-scheduleExsist":
        alert("אחת או יותר ממערכות השעות שיצרת קיימות")
        break;
      case "error-maximumSchedules":
        alert("בקשת מערכת השעות נדחתה מאחר וסך המערכות שמאושרות עבר את המכסה")
        break;
      case "error-unknown":        
        alert("אירעה שגיאה לא ידועה")
        break;
      case "error-found3Identifies":
        alert("לאיש קשר זה נשלחו כבר 3 מזהים. יש להמתין 5 דקות לפני שליחה נוספת.")
        break;
      case "error-acountNotFound":
        // alert("מוסד לא נמצא")
        break;
      case "error-contactNotFound":
        // alert("לא נמצא איש קשר מתאים למוסד זה")
        break;
      case "error-notFound":
        alert("לא נמצא מוסד")
        break;
      case "error-preventAfter3Failures":
        alert("איש קשר חסום למשך 5 דקות, לאחר 3 ניסיונות כושלים להזנת סמס.")
        break;
      case "error-incoorect_sms":
        alert("סמס שגוי")
        break;
      case "error-timeOut":
        alert(" עברה שעה ממועד הפעילות האחרונה. מבצע התנתקות")
        this.TimeOut();
        break;
      default: isError = false;
        break;
    }
    return isError;
  }


}
