import { Injectable } from '@angular/core';
// import { LogInObj } from '../models/LogInObj';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LogInModel } from '../models/Login';

@Injectable({
  providedIn: 'root'
})
export class DataLogin {
  body: string;
  step: string = "1";
  logInObj : LogInModel;
  public Loader = false;

  public hashPhone: string;

  constructor(private http: HttpClient) { }
  
  smsSendCode() {
    return this.setLoginRequstData("LogIn");
  }

  ValidateSmsCode() {
    return this.setLoginRequstData("ValidateLogInCode");
  }

  setLoginRequstData(action: string) {
    this.body = JSON.stringify(this.logInObj);
    return this.http
        .post(
           environment.api + action,
          this.body,
          {
            headers: { 'Content-Type': 'application/json' }
          }
        )
        .toPromise()
        .catch(error=>{
          
          console.log(error);
        });
  }
  

}
