import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { error } from 'console';
import { request } from 'http';
import { interval, Subscription } from 'rxjs';
import { BasicRequest } from 'src/app/models/BasicRequest';
import { DashboardData } from 'src/app/models/DashboardData';
import { Message } from 'src/app/models/Message';
import { OptionSet } from 'src/app/models/OptionSet';
import { RecordQantity } from 'src/app/models/RecordQantity';
import { DashboardService } from 'src/app/services/dashboard.service';
import { DataService } from 'src/app/services/data.service';
import { isError } from 'util';

@Component({
  selector: 'app-home-page',
  host: {
    class:'app-home-page'
  },
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  subscription: Subscription;
  interval: any;
  text: number = 0;
  recordQantity :RecordQantity = new RecordQantity();
  recordQantityResponse : Array<OptionSet>=[];
  isLoadDetails :boolean = true;
  newMessages : Array<Message> = [];
  dashboardData : DashboardData = new DashboardData();
  noResults : boolean = false;
  constructor(private dashboardService :DashboardService,
     public dataService :DataService,
     public datepipe: DatePipe,
     private route : Router) { }
  

  ngOnInit() {    
    // if(this.dashboardService.isFirstLoad){
    //   this.dashboardService.isFirstLoad = false;
    //   this.LoadData();
    // }
    // else{
    //   this.isLoadDetails = false;
    //   this.recordQantity = this.dashboardService.recordsQantity;

    // }
    this.LoadData();
  
  this.dataService.refreshPageSubject.asObservable().subscribe(() => this.LoadData());

  }

  SetDeshboardValues(recordQantityResponse:Array<OptionSet>){      
    let step =30 ;              
    this.interval = interval(step);
    let doneItems = 0;
    this.recordQantity = new RecordQantity();
    this.subscription = this.interval.subscribe(val=>{
      
      recordQantityResponse.forEach(element => {
       if(this.recordQantity[element.viewValue]< Number(element.value) ){
        this.recordQantity[element.viewValue]++;
       } 
       if(this.recordQantity[element.viewValue]==Number(element.value)){
         doneItems++;
       }       
       if(doneItems==recordQantityResponse.length){
         this.dashboardService.recordsQantity = this.recordQantity;
         this.subscription.unsubscribe();
       }
      });    
    });
  }

  // LoadData1(){
  //   this.isLoadDetails=true;
  //   let request  = new BasicRequest(); 
  //   request.Token = this.dataService.userModel.Token;
  //   request.CurrentYear = this.dataService.userModel.Year.value;
  //   request.InstitutionNumber = this.dataService.userModel.InstitutionNumber;
  //   this.dashboardService.GetRecordsQantity(request).subscribe(data=>{
  //     if(data != null){
  //       this.recordQantityResponse = data;   
  //       this.isLoadDetails = false;                  
  //       this.SetDeshboardValues(data);      
  //     }   
  //   }, error=>{
  //     this.dataService.IsError(error.error.ExceptionMessage);
  //   });   
  // }
  LoadData(){    
    this.isLoadDetails = true;
    this.dashboardService.GetDashboardData().then((res:any)=>{
      this.isLoadDetails = false;      
      if(!this.dataService.IsError(res)){
        this.dashboardData.DataQuentity = res.DataQuentity;
        this.dashboardData.Messages = res.Messages;
        this.dataService.UpdateMessagesNumber(res.Messages.length);       
        this.SetDeshboardValues(this.dashboardData.DataQuentity);
        if(this.dashboardData.Messages.length==0){
          this.noResults = true;
        }
      }
      else{ alert('אירעה שגיאה .');}
    }).catch(error=>{console.log(error)});
  }

  GoToMessage(id : string){
    this.route.navigate(['notifications',id]);
  }
   
}
