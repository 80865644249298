import { AbstractControl, ValidationErrors } from '@angular/forms';

export class IdentityNumberValidator {
    static isValidTZ(control: AbstractControl): ValidationErrors | null {
        var id = '' + (control.value);
        while (id.length < 9) {
            id = '0' + id;
        }
        if (id.length != 9 || id=="000000000") {
            return { cannotContainSpace: true };
        }
        var sum = 0, incNum;
        let splitId = id.split('');

        for (let i = 0; i < splitId.length; i++) {
            incNum = Number(splitId[i]) * ((i % 2) + 1); 
            sum += (incNum > 9) ? incNum - 9 : incNum; 
        }
        if (sum % 10 == 0) {
            return null;
        }
        return { isValidTZ: true };
    }
  
}