import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDatepickerInputEvent } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SchedulesService } from 'src/app/services/schedules.service';
import { DataService } from 'src/app/services/data.service';
import { VacationCalendarComponent, VacationRow } from '../vacation-calendar/vacation-calendar.component';
import { timeStamp } from 'console';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-vacation-dialog',
  templateUrl: './vacation-dialog.component.html',
  styleUrls: ['./vacation-dialog.component.scss']
})
export class VacationDialogComponent implements OnInit {

  public form: FormGroup;
  minDate: Date;
  public vactionType = 0;
  public vacation = null;
  public isUpdate = false;
  constructor(public dialogRef: MatDialogRef<VacationCalendarComponent>,
    @Inject(MAT_DIALOG_DATA) public DialogData,
    private fb: FormBuilder,
    public schedulesService: SchedulesService,
    public dataService: DataService,
    public datepipe: DatePipe) {

    this.vacation = this.DialogData.vacation;
    this.isUpdate = this.vacation != null;
    this.form = new FormGroup({
      VacationType: new FormControl('', Validators.required),
      Date: new FormControl(null, Validators.required),
      Remark: new FormControl(''),
      ReturnTime: new FormControl(''),
      Reason: new FormControl(null, Validators.required),
      Period: new FormControl(null/*, Validators.required*/),
      DateTo: new FormControl(''),
    });
  }
  ngOnInit() {
    this.VacationType.valueChanges.subscribe(vactionType => {
      switch (vactionType) {
        case "1":
          this.ReturnTime.setValidators([Validators.required]);
          this.ReturnTime.updateValueAndValidity();
          this.DateTo.setValidators(null);
          this.DateTo.updateValueAndValidity();
          break;
        case "2":
          this.ReturnTime.setValidators(null);
          this.ReturnTime.updateValueAndValidity();
          this.DateTo.setValidators([Validators.required]);
          this.DateTo.updateValueAndValidity();
      }
    });
    if (this.isUpdate) {
      this.VacationType.setValue(this.vacation.VacationType);
      if (this.vacation.Period != null) {
        this.Period.setValue(this.getOption(this.vacation.Period.value, this.schedulesService.PeriodDescriptionList));
      }
      if (this.vacation.ReturnTime != null) {
        this.ReturnTime.setValue(this.getOption(this.vacation.ReturnTime.value, this.schedulesService.ScheduleHoursList));
      }

      this.Reason.setValue(this.vacation.Name);

      if (this.vacation.FromDate != null) {
        this.Date.setValue(new Date(this.vacation.FromDate));
      }
      if (this.vacation.ToDate != null) {
        this.DateTo.setValue(new Date(this.vacation.ToDate));
      }
      this.minDate = this.Date.value;
    }
  }


  get VacationType() {
    return this.form.get('VacationType') as FormControl;
  }

  get ReturnTime() {
    return this.form.get('ReturnTime') as FormControl;
  }

  get Date() {
    return this.form.get('Date') as FormControl;
  }
  get DateTo() {
    return this.form.get('DateTo') as FormControl;
  }
  get Period() {
    return this.form.get('Period') as FormControl;
  }
  get Reason() {
    return this.form.get('Reason') as FormControl;
  }

  close() {
    this.dialogRef.close();
  }
  save() {
    if (this.form.value.Date) {
      //this.form.value.Date = new Date(this.form.value.Date).toLocaleDateString();
      this.form.value.Date = this.form.value.Date.toDateString();
    }
    if (this.form.value.DateTo) {
      // this.form.value.DateTo = new Date(this.form.value.DateTo).toLocaleDateString();
      this.form.value.DateTo = this.form.value.DateTo.toDateString();
    }
    this.dialogRef.close(this.form.value);
  }

  changefromDate(event: MatDatepickerInputEvent<Date>) {
    this.minDate = event.value;
  }

  getOption(value: string, options: any) {
    return value != null ?
      options.filter(m => { return m.value == value })[0] :
      null;
  }


}
