import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { RoutesService } from 'src/app/services/routes.service';

@Component({
  selector: 'app-routes-list',
  templateUrl: './routes-list.component.html',
  styleUrls: ['./routes-list.component.scss']
})
export class RoutesListComponent implements OnInit {

  public routesList: any[];
  isLoadRouteData : boolean = false;
  constructor(public routeService: RoutesService, public dataService : DataService) { }

  ngOnInit() {    
    this.LoadRoutesData();
    this.dataService.isRefresh$.subscribe(isRefresh => {
      if (isRefresh) {
        this.LoadRoutesData();
      }
    });    
    
    this.dataService.refreshPageSubject.asObservable().subscribe(() => this.LoadRoutesData());
  }

  LoadRoutesData(){
    this.isLoadRouteData = true;
    this.routeService.GetRoutesList().then((res: string) => {      
      this.isLoadRouteData = false;
      this.routesList = JSON.parse(res);
    });
  }

}
