import { Component, OnInit } from '@angular/core';
import { EnumScheduleType, ScheduleDialogComponent } from '../schedule-dialog/schedule-dialog.component';
import { MatDialog } from '@angular/material';
import { DataService } from 'src/app/services/data.service';
import { SchedulesService } from 'src/app/services/schedules.service';
import { Schedule_ep } from 'src/app/models/Schedule_ep';
import { StudentService } from 'src/app/services/student.service';
import { Schedule } from 'src/app/models/Schedule';
import { OptionSet } from 'src/app/models/OptionSet';
import { ScheduleRow } from 'src/app/models/ScheduleRow';

@Component({
  selector: 'app-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.scss']
})
export class ScheduleListComponent implements OnInit {
  public isLoadSchedules: boolean;
  public isFetching: boolean;
  public isSaving: boolean;
  public showCreateScheduleLink: boolean = true;
  constructor(public dialog: MatDialog,
    public dataService: DataService,
    public studentService: StudentService,
    private schedulesService: SchedulesService) { }
  private _scheduleList: Array<ScheduleRow> = [];
  public selectedScheduleList: Array<ScheduleRow> = [];
  public tableName = "מערכות שעות"
  set scheduleList(value: Array<ScheduleRow>) {
    this._scheduleList = value;
  }

  get scheduleList(): Array<ScheduleRow> {
    return this._scheduleList;
  }

  ngOnInit() {
    if (!this.dataService.isLoggedIn) {
      this.dataService.TimeOut();
    }
    if (this.schedulesService.scheduleList.length == 0) {
      this.loadData();
    }
    else {
      this.scheduleList = this.schedulesService.scheduleList;
    }
    this.dataService.refreshPageSubject.asObservable().subscribe(() => this.loadData())
  }

  loadData() {
    this.isLoadSchedules = true;
    this.schedulesService.GetAllInstitutuinsSchedules().then((res: any) => {
      if (!this.dataService.IsError(res)) {
        this.isLoadSchedules = false;
        var Data = JSON.parse(res);

        this.schedulesService.scheduleList = Data.ScheduleRows;
        this.scheduleList = this.schedulesService.scheduleList;
        this.schedulesService.PeriodicSchedulesNum = Data.PeriodicSchedulesNum;
        this.schedulesService.FixedSchedulesNum = Data.FixedSchedulesNum;
        this.showCreateScheduleLink = this.scheduleList.filter(x => { return x.Status.value == "1" }).length <
          (this.schedulesService.FixedSchedulesNum + this.schedulesService.PeriodicSchedulesNum);
        this.dataService.loading = false;
      }
    }).catch(error => { /*debugger*/ });
    if (this.schedulesService.ClassesList == null) {
      this.schedulesService.GetModalOptions();
    }

  }

  onSelectSchedule(i: number, isSelect: boolean) {
    if (isSelect) {
      this.selectedScheduleList.push(this.scheduleList[i])
    }
    else {
      i = this.GetScheduleSelectedIndex(i);
      this.selectedScheduleList.splice(i, 1)
    }
  }
  GetScheduleSelectedIndex(i: number): number {
    return this.selectedScheduleList.map(function (s) { return s.Id_e }).indexOf(
      this.scheduleList[i].Id_e
    )
  }

  UpdateSchedule(): void {
    this.isFetching = true;
    let schedule: Schedule_ep;
    this.schedulesService.GetSchedule(this.selectedScheduleList[0]).then((res: string) => {
      if (!this.dataService.IsError(res)) {
        this.isFetching = false;
        schedule = JSON.parse(res);
        const dialogRef = this.dialog.open(ScheduleDialogComponent, {
          panelClass: 'full-width-dialog',
          hasBackdrop: false,
          autoFocus: true,
          disableClose: true,
          data: { data: schedule }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result != undefined) {
            let form: Schedule_ep = result;
            this.UpdateMultipleSchedules(form, this.selectedScheduleList);
          }
          this.ClearAllChecked();

        });
      }
    }).catch(error => { /*debugger*/; })

  }
  ClearAllChecked() {
    this.selectedScheduleList = []
    this.scheduleList.filter(opt => opt._checked).forEach((schedule, i) => {
      schedule._checked = false;
    });
  }
  UpdateMultipleSchedules(form: Schedule_ep, schedulesToUpdate: ScheduleRow[]) {
    /*debugger*/
    this.schedulesService.request.e_schedule = this.GetE_Schedule(form);
    this.schedulesService.request.p_schedule = this.GetP_Schedule(form);
    this.schedulesService.request.SchedulesIds_e = schedulesToUpdate.map(s => {
      return s.Id_e;
    });//מצרף למערך את מערכות שעות לאיסוף
    this.schedulesService.request.SchedulesIds_p = schedulesToUpdate.map(s => {
      return s.Id_p;
    });//מצרף למערך את מערכות שעות לפיזור
    this.isSaving = true;
    this.schedulesService.UpdateSchedules().then((res: string) => {
      //if (!this.dataService.IsError(res)) {
      this.dataService.IsError(res);
      this.isSaving = false;

      this.loadData();
      //}
    })
  }



  CreateSchedule(): void {
    const dialogRef = this.dialog.open(ScheduleDialogComponent, {
      panelClass: 'full-width-dialog',
      hasBackdrop: false,
      autoFocus: true,
      disableClose: true
      // data: {urlImg: this.urlImg}
    });

    dialogRef.afterClosed().subscribe((result: Schedule_ep) => {
      if (result != undefined) {
        this.SaveSchedules(result);
        console.log('The dialog was closed');
      }
    });
  }

  SaveSchedules(schedule: Schedule_ep) {
    this.schedulesService.request.e_schedule = this.GetE_Schedule(schedule);
    this.schedulesService.request.p_schedule = this.GetP_Schedule(schedule);
    this.isSaving = true;
    this.schedulesService.Save2Schedules().then((res: string) => {
      //if (!this.dataService.IsError(res)) {
      this.dataService.IsError(res);
      this.isSaving = false;
      this.loadData();
      //}

    })
  }

  //מקבל מערכת שעות משולבת ומפצל לשתיים
  public GetE_Schedule(schedule: Schedule_ep) {
    let e: Schedule = new Schedule();
    //בונה איסוף
    e.Class = schedule.Class;
    e.Remarks = schedule.Remarks;
    e.ScheduleType = schedule.ScheduleType;
    e.Period = schedule.Period;
    e.FromDate = schedule.FromDate;
    e.ToDate = schedule.ToDate;
    e.Sunday = schedule.Sunday_e;
    e.Monday = schedule.Monday_e;
    e.Tuesday = schedule.Tuesday_e;
    e.Wednesday = schedule.Wednesday_e;
    e.Thursday = schedule.Thursday_e;
    e.Friday = schedule.Friday_e;
    e.Saturday = schedule.Saturday_e;
    let os = new OptionSet();
    os.value = EnumDirectionType.איסוף.toString();
    e.Type = os;
    return e;
  }
  public GetP_Schedule(schedule: Schedule_ep) {
    let p: Schedule = new Schedule();
    //בונה פיזור
    p.Class = schedule.Class;
    p.Remarks = schedule.Remarks;
    p.ScheduleType = schedule.ScheduleType;
    p.Period = schedule.Period;
    p.FromDate = schedule.FromDate;
    p.ToDate = schedule.ToDate;

    p.Sunday = schedule.Sunday_p;
    p.Monday = schedule.Monday_p;
    p.Tuesday = schedule.Tuesday_p;
    p.Wednesday = schedule.Wednesday_p;
    p.Thursday = schedule.Thursday_p;
    p.Friday = schedule.Friday_p;
    p.Saturday = schedule.Saturday_p;
    let os = new OptionSet();
    os.value = EnumDirectionType.פיזור.toString();
    p.Type = os;
    return p;
  }
}

export enum EnumDirectionType {
  איסוף = 100000000,
  פיזור = 100000001
}


