import { File } from "./File";

export class Message{
     MessageID: string;
     Id:string;
     Subject: string;
     Description : string;
     CreatedOn : string;
     ExpiryDate : string;
     IsRead : boolean;    
     AttachedFiles : Array<File>;   
}