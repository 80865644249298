import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BasicRequest } from '../models/BasicRequest';
import { OptionSet } from '../models/OptionSet';
import { RecordQantity } from '../models/RecordQantity';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  recordsQantity: RecordQantity = new RecordQantity();  
  qantitiesList :Array<OptionSet> =[];
  isFirstLoad : boolean = true;

  rout = 'DashboardActions';
  request : BasicRequest = new BasicRequest();

  constructor(private dataService : DataService, private http :HttpClient) { }

  GetRecordsQantity(request : BasicRequest ):Observable<Array<OptionSet>>{
    return this.http.post<any>(environment.api+'GetRecordsQantities',request);
  } 

  GetDashboardData(){
    this.request.Action = 'GetDashboardData';
    return this.dataService.setRequstData(this.rout,this.request);
  }
}
