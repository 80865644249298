<div class="content">

    <div class="component-title">
        <app-component-title componentName="מערכת שעות" IconName="ScheduleIcon">
        </app-component-title>

    </div>
    <div class="component-content">
        <div *ngIf="dataService.userModel.IsEditScheduleAllowed" class="d-flex justify-content-end contant-iconButton">
            <button title="יש לבחור מערכת שעות לעדכון" class="d-flex iconButton" (click)="UpdateSchedule()" [ngClass]="{'disabled' : selectedScheduleList.length != 1 }" [disabled]=" selectedScheduleList.length != 1 ">
                <label [ngClass]="{'disabled' : selectedScheduleList.length != 1 }" class="txt-button">עדכן מערכת
                    שעות</label>
                <i class="updateSchedule-icon"></i>
            </button>
            <button class="d-flex iconButton" (click)="CreateSchedule()">
                <label class="txt-button">הוסף מערכת שעות</label>
                <i class="addScheduleIcon-icon"></i>
            </button>
        </div>
        <div *ngIf="! dataService.userModel.IsEditScheduleAllowed" class="d-flex justify-content-end contant-iconButton">
            <div class="alert alert-danger notAllowedAlert">

                {{dataService.notAllowedTxt1}} {{tableName}} {{dataService.notAllowedTxt2}}
                <span style="unicode-bidi: isolate; direction: ltr;">{{dataService.userModel.BuisnesUnitPhone}}</span>
            </div>
        </div>
        <div [hidden]="!isFetching" class="saveLoading">מכין נתונים...
            <loaders-css [loader]="dataService.saveLoader" [scale]="1" [color]="dataService.color"></loaders-css>
        </div>
        <div [hidden]="!isSaving" class="saveLoading">שומר...
            <loaders-css [loader]="dataService.saveLoader" [scale]="1" [color]="dataService.color"></loaders-css>
        </div>
        <div class="scheduleTable">
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>לימודים</th>
                        <th>כיתה</th>
                        <th>סוג מערכת</th>
                        <th>תקופה</th>
                        <th>סטטוס</th>
                        <th>יום א</th>
                        <th>יום ב</th>
                        <th>יום ג</th>
                        <th>יום ד</th>
                        <th>יום ה</th>
                        <th>יום ו</th>
                        <th class="last-th">יום ש</th>
                        <!-- <th class="last-th">הערות</th> -->
                    </tr>
                </thead>
                <div [hidden]="!isLoadSchedules" class="loading">
                    <loaders-css [loader]="dataService.loader" [scale]="2" [color]="dataService.color"></loaders-css>
                </div>
                <tbody *ngIf="!isLoadSchedules">
                    <tr *ngFor="let schedule of scheduleList; let i = index">
                        <td class="first-td">
                            <label class="container checkbox d-flex justify-content-center">
                                <input type="checkbox" [(ngModel)]="schedule._checked"
                                    (change)="onSelectSchedule(i, schedule._checked)">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                        <td>{{schedule.Year}}</td>
                        <td>{{schedule.Class}}</td>
                        <td>{{schedule.ScheduleType.viewValue}}</td>
                        <td>{{schedule.Period}}</td>
                        <td>{{schedule.Status.viewValue}}</td>
                        <td>{{schedule.Sunday}}</td>
                        <td>{{schedule.Monday}}</td>
                        <td>{{schedule.Tuesday}}</td>
                        <td>{{schedule.Wednesday}}</td>
                        <td>{{schedule.Thursday}}</td>
                        <td>{{schedule.Friday}}</td>
                        <td>{{schedule.Saturday}}</td>
                        <td *ngIf="false">{{schedule.Id_e}}</td>
                        <td class="last-td" *ngIf="false">{{schedule.Id_p}}</td>
                        <!-- <td class="last-td">{{schedule.Remarks}}</td> -->
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>