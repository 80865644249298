import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ContactsListComponent } from './components/contacts-list/contacts-list.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { InquiriesComponent } from './components/inquiries/inquiries.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { PersonalAreaComponent } from './components/personal-area/personal-area.component';
import { ScheduleListComponent } from './components/schedule-list/schedule-list.component';
import { VacationCalendarComponent } from './components/vacation-calendar/vacation-calendar.component';
import { InstitutionDetailsComponent } from './components/institution-details/institution-details.component';
import { RoutesListComponent } from './components/routes-list/routes-list.component';
import { InstitutionCalendarMainComponent } from './components/institution-calendar/institution-calendar-main/institution-calendar-main.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'contacts-list', component: ContactsListComponent },
  { path: 'home-page', component: HomePageComponent },
  { path: 'institution-details', component: InstitutionDetailsComponent },
  { path: 'routes-list', component: RoutesListComponent },
  { path: 'inquiries', component: InquiriesComponent },
  { path: 'notifications', component: NotificationsComponent },
  { path: 'notifications/:id', component: NotificationsComponent },
  { path: 'personal-area', component: PersonalAreaComponent },
  { path: 'schedule-list', component: ScheduleListComponent },
  { path: 'vacation-calendar', component: VacationCalendarComponent },
  { path: 'institotion-calendar', component: InstitutionCalendarMainComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
