<div class="container">

<div>
    <b>יש לבחור סיבת גריעת תלמיד\ים:</b>
</div>

<div>
    <select [(ngModel)]="selectedReason">
        <option *ngFor="let r of DeactivateReasons" [ngValue]="r">{{r}}</option>
      </select>
</div>
<div>
    <input *ngIf="selectedReason=='אחר'" class="inputText" [(ngModel)]="other" type="text" placeholder="אחר...">

</div>

  <div class="btns">
    <button class="cancel-save-button button" (click)="close()">ביטול</button>
    <button class="save-button"
     [ngClass]="{'disabled' : ( selectedReason==null && other==null ) }" 
    [disabled]="selectedReason==null && other==null " (click)="save()">שמור</button>
    
  </div>
  

</div>