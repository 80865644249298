import { Schedule } from './Schedule';

export class InstitutionRequest{
    public constructor( ) {
         
    }
    public Action:string;
    public e_schedule: Schedule;
    public p_schedule: Schedule;
    public Schedule: Schedule = new Schedule();
    public SchedulesIds_e: string[] = []; 
    public SchedulesIds_p: string[] = []; 
}