import { OptionSet } from "./OptionSet";

export class UserModel {
    public constructor() {
    this.Year=new OptionSet();
    }
    Token: string;
    AcountFullName: string;
    InstitutionName: string;
    InstitutionNumber: string;
    IsEditStudentAllowed: boolean;
    IsEditScheduleAllowed: boolean;
    BuisnesUnitPhone: string;
    Year:OptionSet;
    ContactMail : string;
    ContactPhone : string;
}