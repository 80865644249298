<div class="content">

    <div class="component-title">
        <app-component-title componentName="לוח חופשות וימים מקוצרים" IconName="ScheduleIcon">
        </app-component-title>

    </div>
    <div class="component-content">
        <div *ngIf="dataService.userModel.IsEditScheduleAllowed" class="d-flex justify-content-end contant-iconButton">
            <button title="יש לבחור לוח חופשות לעדכון" class="d-flex iconButton" (click)="UpdateVacation()" [ngClass]="{'disabled' : selectedVacation == null }" [disabled]=" selectedVacation == null">
                <label [ngClass]="{'disabled' : selectedVacation == null }" class="txt-button">עדכן לוח חופשות</label>
                <i class="updateSchedule-icon"></i>
            </button>
            <button class="d-flex iconButton" (click)="CreateVacation()">
                <label class="txt-button">הוסף יום קצר/חופשה</label>
                <i class="addScheduleIcon-icon"></i>
            </button>
        </div>
        <div *ngIf="!dataService.userModel.IsEditScheduleAllowed" class="d-flex justify-content-end contant-iconButton">
            <div class="alert alert-danger notAllowedAlert">

                {{dataService.notAllowedTxt1}} {{tableName}} {{dataService.notAllowedTxt2}}
                <!-- <span style="unicode-bidi: isolate; direction: ltr;">{{dataService.userModel.BuisnesUnitPhone}}</span> -->
            </div>
        </div>
        <!-- <div [hidden]="!isFetching" class="saveLoading">מכין נתונים...
            <loaders-css [loader]="dataService.saveLoader" [scale]="1" [color]="dataService.color"></loaders-css>
        </div> -->
        <div [hidden]="!isSaving" class="saveLoading">שומר...
            <loaders-css [loader]="dataService.saveLoader" [scale]="1" [color]="dataService.color"></loaders-css>
        </div>
        <div class="scheduleTable">
            <table>
                <thead>
                    <tr>
                        <th>בחר</th>
                        <th>שנת לימודים</th>
                        <th>סטטוס</th>
                        <th>סוג חופשה</th>
                        <th>תיאור התקופה</th>
                        <th>תאור</th>
                        <th>החל מ</th>
                        <th>עד</th>
                        <th class="last-th">שעת סיום</th>
                    </tr>
                </thead>
                <div [hidden]="!isLoadVacation" class="loading">
                    <loaders-css [loader]="dataService.loader" [scale]="2" [color]="dataService.color"></loaders-css>
                </div>
                <tbody *ngIf="!isLoadVacation">
                    <tr *ngFor="let vacation of vacatinList">
                        <td class="first-td">
                            <label class="container checkbox d-flex justify-content-center">
                                <input type="checkbox" [(ngModel)]="vacation._checked"
                                    (change)="onSelectVacation(vacation)">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                        <td>{{vacation.Year}}</td>
                        <td>{{vacation.Status}}</td>
                        <td>
                            <ng-container *ngIf="vacation.VacationType == '1'">יום קצר</ng-container>
                            <ng-container *ngIf="vacation.VacationType == '2'">יום חופשה</ng-container>
                        </td>
                        <td>{{vacation.Period.viewValue}}</td>
                        <td>{{vacation.Name}}</td>
                        <td>{{vacation.FromDate | date:'dd/MM/yyyy'}}</td>
                        <td>{{vacation.ToDate| date:'dd/MM/yyyy'}}</td>
                        <td class="last-td">
                            <ng-container *ngIf="vacation.ReturnTime">{{vacation.ReturnTime.viewValue}}</ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>