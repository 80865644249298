import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Message } from 'src/app/models/Message';
import { DataService } from 'src/app/services/data.service';
import { MessageService } from 'src/app/services/message.service';
import { MessageDisplayDialogComponent } from '../message-display-dialog/message-display-dialog.component';

@Component({
  selector: 'app-notifications',
  host: {
    class: 'app-notifications'
  },
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  messageList: Array<Message> = [];
  noResults: boolean = false;
  loadingData: boolean = false;
  selectedItemIndex: number = -1;
  nevigatedMessageID : string ='';
  @Input() dashboardDisplay = false;

  constructor(public dataService: DataService,
    public messageService: MessageService,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private activatedRoute : ActivatedRoute) { }

  ngOnInit() {
    this.nevigatedMessageID = this.activatedRoute.snapshot.paramMap.get('id');
    this.GetContactMessages();
    this.dataService.refreshPageSubject.asObservable().subscribe(() => this.GetContactMessages());     
  }

  GetContactMessages() {
    this.messageList = [];
    this.loadingData = true;
    this.noResults = false;
    this.messageService.GetContactMessages().then((res: any) => {
      if (!this.dataService.IsError(res)) {
        this.loadingData = false;
        let newMessagesCount = res.filter((m: Message)=>{ 
          if(!m.IsRead){
            return m;
          }
        }).length;
        this.dataService.UpdateMessagesNumber(newMessagesCount);
        this.messageList = res;        
        if (this.messageList.length == 0) {
          this.noResults = true;
        }              
        this.ShowMessage(this.nevigatedMessageID);  
        this.nevigatedMessageID = '';                                     
      }
      else{
        alert('אירעה שגיאה.');
      }
    }).catch(error => { console.log(error) });
  }

  OnSelectItem(i: number) {
    this.selectedItemIndex = this.selectedItemIndex == i ? -1 : i;
  }

  ShowMessage(id: string) {
    let message : Message =  this.messageList.find(msg=> msg.MessageID == id);
    if(message != undefined){
      if(!this.messageList.find(msg=> msg.MessageID == id).IsRead){
        this.MarkMesssageAsRead(message.MessageID);
      }   
      const dialogConfig = new MatDialogConfig();
  
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.hasBackdrop = true;
      dialogConfig.minWidth = '50vw';
      dialogConfig.data = message;
  
      const dialogRef = this.dialog.open(MessageDisplayDialogComponent, dialogConfig);
    }    
  }

  MarkMesssageAsRead(id: string) {
    this.messageList.find(msg=> msg.MessageID == id ).IsRead = true;
    this.dataService.IncrementMessagesNumber(-1);
    this.messageService.UpdateMessageStatus(id).then((res: any) => {
      if (!this.dataService.IsError(res)) {
      }
    });
  }

}
