import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { MatDialog } from '@angular/material';
import { VacationDialogComponent } from '../vacation-dialog/vacation-dialog.component';
import { SchedulesService } from 'src/app/services/schedules.service';
import { OptionSet } from 'src/app/models/OptionSet';

@Component({
  selector: 'app-vacation-calendar',
  templateUrl: './vacation-calendar.component.html',
  styleUrls: ['./vacation-calendar.component.scss']
})
export class VacationCalendarComponent implements OnInit {
  isSaving: boolean;

  constructor(
    public dataService: DataService,
    public dialog: MatDialog,
    private schedulesService: SchedulesService
  ) { }
  // public selectedVacationList: Array<VacationRow> = [];
  public selectedVacation: VacationRow = null;
  public isLoadVacation: boolean = false;
  public vacatinList: Array<VacationRow> = [];
  public tableName = "לוח חופשות וימים מקוצרים"

  ngOnInit() {
    if (this.schedulesService.vacatinList.length == 0) {
      this.loadVacationData();
    }
    else {
      this.vacatinList = this.schedulesService.vacatinList;
    }
    this.dataService.refreshPageSubject.asObservable().subscribe(() => this.loadVacationData())
  }

  loadVacationData() {
    this.isLoadVacation = true;

    if (this.schedulesService.PeriodDescriptionList == null || this.schedulesService.ClassesList == null) {
      this.schedulesService.GetModalOptions();
    }

    let request = { Action: "GetVacation" };
    this.dataService.setRequstData("VacationActions", request).then((res: any) => {
      if (!this.dataService.IsError(res)) {
        this.isLoadVacation = false;
        var Data = JSON.parse(res);
        this.schedulesService.vacatinList = Data;
        this.vacatinList = this.schedulesService.vacatinList;
        this.dataService.loading = false;
      }
    });
  }

  onSelectVacation(vacation: VacationRow) {

    if (vacation._checked) {
      if (this.selectedVacation != null) {
        this.selectedVacation._checked = false;
      }
      this.selectedVacation = vacation;
    }
    else {
      this.selectedVacation = null;
    }
  }
  CreateVacation(): void {//יום קצר/חופשה
    const dialogRef = this.dialog.open(VacationDialogComponent, {
      panelClass: 'full-width-dialog',
      hasBackdrop: false,
      autoFocus: true,
      disableClose: true,
      data: { vacation: null }
      // data: {urlImg: this.urlImg}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        let request = Object.assign({ Action: "SaveVacation" }, result);
        this.isSaving = true;
        this.dataService.setRequstData("VacationActions", request).then((res: string) => {
          this.isSaving = false;
          if (!this.dataService.IsError(res)) {
            this.loadVacationData();
          }
        });
      }
    });
  }


  UpdateVacation() {

    const dialogRef = this.dialog.open(VacationDialogComponent, {
      panelClass: 'full-width-dialog',
      hasBackdrop: false,
      autoFocus: true,
      disableClose: true,
      data: {
        vacation: this.selectedVacation
      }
      // data: {urlImg: this.urlImg}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        result.VacationId = this.selectedVacation.VacationId;
        let request = Object.assign({ Action: "SaveVacation" }, result);
        this.isSaving = true;
        this.dataService.setRequstData("VacationActions", request).then((res: string) => {
          this.isSaving = false;
          if (!this.dataService.IsError(res)) {
            this.loadVacationData();
          }
        });
      }
    });
  }

}


export interface VacationRow {
  VacationId: string;
  _checked: boolean;
  Year: string;
  VacationType: string;
  Name: string;
  FromDate: string;
  ToDate: string;
  ReturnTime: OptionSet;
  Period: OptionSet;
  Status: string;
}
