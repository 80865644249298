<div class="content">

    <div class="component-title">
        <app-component-title componentName="פרופיל מוסד" IconName="InstitutionIcon">
        </app-component-title>
    </div>
    <div [hidden]="!isLoadDetails" class="loading">
        <loaders-css [loader]="dataService.loader" [scale]="2" [color]="dataService.color"></loaders-css>
    </div>
    <form [hidden]="isLoadDetails" class="component-content" [formGroup]="form" (ngSubmit)="save()">
        <div class="d-flex orang-title align-items-center">
            <i class="id-icon"></i>
            <div class="orang-text">פרטי המוסד</div>
            <div class="orang-line"></div>
        </div>

        <div class="d-flex justify-content-between contant-inputs">

            <div class="d-flex col justify-content-between">
                <div class="txt">סמל מוסד
                    <div class="required">*</div>
                </div>
                <input formControlName="Number" OnlyNumber="true" class="inputText" type="text">
            </div>

            <div class="d-flex justify-content-between col ">
                <div class="txt">שם מוסד
                    <div class="required">*</div>
                </div>
                <input formControlName="Name" class="inputText" type="text">
            </div>

            <div class="d-flex col justify-content-between input-worp ">
                <div class="txt">סוג חינוך
                    <div class="required">*</div>
                </div>
                <div class="selectDiv">
                    <select formControlName="SugChinuch">
                        <option value="">בחר</option>
                        <option [ngValue]="s" *ngFor="let s of institutionService.SugChinuchList">
                            {{ s.viewValue }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="d-flex col justify-content-between input-worp ">
                <div class="txt">פיקוח
                </div>
                <div class="selectDiv">
                    <select formControlName="Pikuach">
                        <option value="">בחר</option>
                        <option [ngValue]="p" *ngFor="let p of institutionService.PikuachList">
                            {{ p.viewValue }}
                        </option>
                    </select>
                </div>
            </div>

        </div>

        <div class="d-flex justify-content-between contant-inputs">

            <div class="d-flex col justify-content-between">
                <div class="txt">מגזר למוסד
                </div>
                <div class="selectDiv">
                    <select formControlName="Migzar">
                        <option value="">בחר</option>
                        <option [ngValue]="m" *ngFor="let m of institutionService.MigzarList">
                            {{ m.viewValue }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="d-flex justify-content-between col ">
                <div class="txt">שלב חינוך
                </div>
                <div class="selectDiv">
                    <ng-multiselect-dropdown [disabled]="isReadonly" formControlName="ShlavChinuch" [placeholder]="'בחר'" [settings]="dropdownSettings" [data]="institutionService.ShlavChinuchList" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
                    </ng-multiselect-dropdown>
                </div>
            </div>

            <div class="d-flex col justify-content-between input-worp ">
                <div class="txt">מאפיין מוסד
                </div>
                <div class="selectDiv">
                    <select formControlName="MosadType">
                        <option value="">בחר</option>
                        <option [ngValue]="m" *ngFor="let m of institutionService.MosadTypeList">
                            {{ m.viewValue }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="d-flex col justify-content-between input-worp ">
                <div class="txt">סקולבס
                </div>
                <div class="selectDiv">
                    <select formControlName="SchoolBus">
                        <option value="">בחר</option>
                        <option [ngValue]="s" *ngFor="let s of institutionService.SchoolBusList">
                            {{ s.viewValue }}
                        </option>
                    </select>
                </div>
            </div>

        </div>

        <div class="d-flex justify-content-between contant-inputs">

            <div class="d-flex col justify-content-between">
                <div class="txt">ימי לימודים בשנה
                </div>
                <input formControlName="DaysForYear" OnlyNumber="true" class="inputText" type="text">
            </div>

            <div class="d-flex justify-content-between col ">
                <div class="txt">ימי לימודים בשבוע
                </div>
                <div class="selectDiv">
                    <ng-multiselect-dropdown [disabled]="isReadonly" formControlName="DaysForWeek" [placeholder]="'בחר'" [settings]="dropdownSettings" [data]="institutionService.DaysList" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
                    </ng-multiselect-dropdown>
                </div>
            </div>

            <div class="d-flex justify-content-between col ">
                <div class="txt">כיתות
                </div>
                <div class="selectDiv">
                    <ng-multiselect-dropdown [disabled]="isReadonly" formControlName="Classes" [placeholder]="'בחר'" [settings]="dropdownSettings" [data]="institutionService.ClassesList" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
                    </ng-multiselect-dropdown>
                </div>
            </div>

            <div class="d-flex col justify-content-between input-worp ">
                <div class="txt">מין
                </div>
                <div class="selectDiv">
                    <select formControlName="Genders">
                        <option value="">בחר</option>
                        <option [ngValue]="g" *ngFor="let g of institutionService.GendersList">
                            {{ g.viewValue }}
                        </option>
                    </select>
                </div>
            </div>

        </div>


        <div class="d-flex orang-title align-items-center">
            <i class="id-icon"></i>
            <div class="orang-text">פרטי קשר למוסד</div>
            <div class="orang-line"></div>
        </div>

        <div class="d-flex justify-content-between contant-inputs">

            <div class="d-flex col justify-content-between">
                <div class="txt">טלפון
                </div>
                <input formControlName="Telephone" OnlyNumber="true" class="inputText" type="text">
            </div>

            <div class="d-flex col justify-content-between">
                <div class="txt">פקס
                </div>
                <input formControlName="Fax" OnlyNumber="true" class="inputText" type="text">
            </div>
            <div class="d-flex col justify-content-between">
                <div class="txt">אימייל
                </div>
                <input formControlName="Email" class="inputText" type="text">
            </div>
            <div class="d-flex col justify-content-between">
                <div class="txt">אתר
                </div>
                <input formControlName="WebsiteUrl" class="inputText" type="text">
            </div>

        </div>


        <div class="d-flex orang-title align-items-center">
            <i class="id-icon"></i>
            <div class="orang-text">כתובת</div>
            <div class="orang-line"></div>
        </div>

        <div class="d-flex justify-content-between contant-inputs">

            <div class="d-flex col justify-content-between">
                <div class="txt">רחוב ומספר
                    <div class="required">*</div>
                </div>
                <input formControlName="StreetAndNumber" class="inputText" type="text">
            </div>

            <div class="d-flex col justify-content-between">
                <div class="txt">שכונה
                </div>
                <input formControlName="Neighborhood" class="inputText" type="text">
            </div>
            <div class="d-flex col justify-content-between">
                <div class="txt">עיר
                    <div class="required">*</div>
                </div>
                <div class="selectDiv">
                    <select formControlName="CityId">
                        <option value="">בחר</option>
                        <option [ngValue]="c" *ngFor="let c of institutionService.CitiesList">
                            {{ c.Name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex col justify-content-between">
                <div class="txt">מיקוד
                </div>
                <input formControlName="PostalCode" class="inputText" type="text">
            </div>

        </div>

        <div class="d-flex justify-content-between contant-inputs width50">

            <div class="d-flex col justify-content-between">
                <div class="txt">קו אורך
                </div>
                <input formControlName="Longitude" OnlyNumber="true" class="inputText" type="text">
            </div>

            <div class="d-flex col justify-content-between">
                <div class="txt">קו רוחב
                </div>
                <input formControlName="Latitude" OnlyNumber="true" class="inputText" type="text">
            </div>
        </div>

        <div class="contant-buttons d-flex justify-content-end">
            <button *ngIf="!isReadonly" class="cancel-save-button button" (click)="cancel()">בטל</button>
            <button *ngIf="!isReadonly" class="button save-button" type="submit">שמור</button>
            <!-- <button *ngIf="!isReadonly" class="button save-button" type="updateAddress">עדכן כתובת</button> -->
            <button *ngIf="isReadonly" class="button save-button" (click)="enableForm()">פתח לעריכה</button>
        </div>
    </form>
</div>