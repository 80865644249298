import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataLogin } from 'src/app/services/dataLogin.service';
import { logging } from 'protractor';
import { from } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import {MatDividerModule} from '@angular/material/divider';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  public isshow: true;
  constructor(public dataLogin:DataLogin, private dataService: DataService,
    private cdRef : ChangeDetectorRef) { }

  ngOnInit() {
    //debugger


  }

  

}
