import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { UserModel } from '../models/User';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { interval, Subscription, Subject, BehaviorSubject } from 'rxjs';
import { ContactRow } from '../models/ContactRow';
import { DataService } from './data.service';
import { InstitutionRequest } from '../models/InstitutionRequest';
import { Schedule } from '../models/Schedule';
import { OptionSet } from '../models/OptionSet';
import { ScheduleRow } from '../models/ScheduleRow';
import { VacationRow } from '../components/vacation-calendar/vacation-calendar.component';

@Injectable({
  providedIn: 'root'
})
export class SchedulesService {



  public rout = "SchedulesActions";
  public AccountsList: Array<ContactRow> = new Array<ContactRow>();
  request: InstitutionRequest;
  public ScheduleHoursList: Array<OptionSet> = new Array<OptionSet>();
  public ScheduleTypesList: Array<OptionSet> = null;
  public PeriodDescriptionList: Array<OptionSet> = null;
  public scheduleList: Array<ScheduleRow> = [];
  public FixedSchedulesNum: number;
  public PeriodicSchedulesNum: number;
  public vacatinList: Array<VacationRow> = [];
  public ClassesList: any = null;


  constructor(private http: HttpClient,
    private dataService: DataService) {
    this.request = new InstitutionRequest();
  }

  SetActive() {
    this.request.Action = "";
    return this.dataService.setRequstData(this.rout, this.request);
  }

  UpdateSchedules() {
    this.request.Action = "UpdateSchedules";
    return this.dataService.setRequstData(this.rout, this.request);
  }

  GetSchedule(schedule: ScheduleRow) {
    this.request.Schedule.Id_e = schedule.Id_e;
    this.request.Schedule.Id_p = schedule.Id_p;
    this.request.Action = "GetSchedule";
    return this.dataService.setRequstData(this.rout, this.request);
  }

  Save2Schedules() {
    this.request.Action = "Create2Schedules";
    return this.dataService.setRequstData(this.rout, this.request);
  }
  GetModalOptions() {
    this.request.Action = "GetModalOptions";
    return this.dataService.setRequstData(this.rout, this.request).then((res: any) => {
      if (!this.dataService.IsError(res)) {
        var Data = JSON.parse(res);
        this.ClassesList = Data.ClassesList;
        this.ScheduleHoursList = Data.ScheduleHoursList;
        this.ScheduleTypesList = Data.ScheduleTypeList;
        this.PeriodDescriptionList = Data.PeriodDescriptionList;
      }
    }).catch(error => {
      debugger
    });
  }

  GetAllInstitutuinsSchedules() {
    this.request.Action = "GetAllInstitutuinsSchedule";
    return this.dataService.setRequstData(this.rout, this.request);
  }






}

