import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { triggerAsyncId } from 'async_hooks';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Institution } from 'src/app/models/InstitutionDetails';
import { DataService } from 'src/app/services/data.service';
import { InstitutionDetailsService } from 'src/app/services/institution-details.service';

@Component({
  selector: 'app-institution-details',
  templateUrl: './institution-details.component.html',
  styleUrls: ['./institution-details.component.scss']
})
export class InstitutionDetailsComponent implements OnInit {

  form: FormGroup;
  public isLoadDetails: boolean = false;
  dropdownSettings: IDropdownSettings = {};
  institution: Institution;
  isReadonly: boolean = true;

  constructor(public institutionService: InstitutionDetailsService, public dataService: DataService) {

    this.dropdownSettings = {
      idField: 'value',
      textField: 'viewValue',
      enableCheckAll: true,
      selectAllText: 'בחר הכל',
      unSelectAllText: 'בטל הכל',
      itemsShowLimit: 1,

    };

    this.form = new FormGroup({
      Name: new FormControl('', Validators.required),
      Number: new FormControl('', Validators.required),
      SugChinuch: new FormControl(''),
      Pikuach: new FormControl(''),
      Migzar: new FormControl(''),
      ShlavChinuch: new FormControl(''),
      MosadType: new FormControl(''),
      SchoolBus: new FormControl(''),
      DaysForYear: new FormControl(''),
      DaysForWeek: new FormControl(''),
      Classes: new FormControl(''),
      Genders: new FormControl(''),
      Telephone: new FormControl(''),
      Fax: new FormControl(''),
      Email: new FormControl(''),
      WebsiteUrl: new FormControl(''),
      StreetAndNumber: new FormControl(''),
      Neighborhood: new FormControl(''),
      CityId: new FormControl(''),
      PostalCode: new FormControl(''),
      Longitude: new FormControl(''),
      Latitude: new FormControl(''),
    });
  }

  ngOnInit() {    
    if (!this.dataService.isLoggedIn) {
      this.dataService.TimeOut();
    }
    if (!this.institution) {
      this.loadData();
    }
    // else {
    //   this.scheduleList = this.schedulesService.scheduleList;
    // }
    // this.institutionService.dataService.refreshPageSubject.asObservable().subscribe(() => this.loadData())
  }

  loadData() {
    this.isLoadDetails = true;
    this.institutionService.GetInstitutionDetails().then((res: any) => {      
      if (!this.dataService.IsError(res)) {

        var data = JSON.parse(res);
        this.institution = data.institution;
        this.institutionService.setLists(data);
        this.setFormData();
        if (this.isReadonly)
          this.form.disable();
        this.isLoadDetails = false;
      }
    });

  }
  setFormData() {
    this.form.get('Name').setValue(this.institution.Name);
    this.form.get('Number').setValue(this.institution.Number);
    this.form.get('SugChinuch').setValue(this.getOption(this.institution.SugChinuch ? this.institution.SugChinuch.value : null, this.institutionService.SugChinuchList));
    this.form.get('Pikuach').setValue(this.getOption(this.institution.Pikuach ? this.institution.Pikuach.value : null, this.institutionService.PikuachList));
    this.form.get('Migzar').setValue(this.getOption(this.institution.Migzar ? this.institution.Migzar.value : null, this.institutionService.MigzarList));
    this.form.get('ShlavChinuch').setValue(this.institution.ShlavChinuch);
    this.form.get('MosadType').setValue(this.getOption(this.institution.MosadType ? this.institution.MosadType.value : null, this.institutionService.MosadTypeList));
    this.form.get('SchoolBus').setValue(this.getOption(this.institution.SchoolBus ? this.institution.SchoolBus.value : null, this.institutionService.SchoolBusList));
    this.form.get('DaysForYear').setValue(this.institution.DaysForYear);
    this.form.get('DaysForWeek').setValue(this.institution.DaysForWeek);
    this.form.get('Classes').setValue(this.institution.Classes);
    this.form.get('Genders').setValue(this.getOption(this.institution.Genders ? this.institution.Genders.value : null, this.institutionService.GendersList));
    this.form.get('Telephone').setValue(this.institution.Telephone);
    this.form.get('Fax').setValue(this.institution.Fax);
    this.form.get('Email').setValue(this.institution.Email);
    this.form.get('WebsiteUrl').setValue(this.institution.WebsiteUrl);
    this.form.get('StreetAndNumber').setValue(this.institution.StreetAndNumber);
    this.form.get('Neighborhood').setValue(this.institution.Neighborhood);
    this.form.get('CityId').setValue(this.getLookUpOption(this.institution.CityId ? this.institution.CityId.Id : null, this.institutionService.CitiesList));
    this.form.get('PostalCode').setValue(this.institution.PostalCode);
    this.form.get('Longitude').setValue(this.institution.Longitude);
    this.form.get('Latitude').setValue(this.institution.Latitude);
  }

  getOption(value: string, options: any) {
    return value != null ?
      options.filter(m => { return m.value == value })[0] :
      null;
  }

  getLookUpOption(value: string, options: any) {      
    let option = options.filter(m => { return m.Id == value })[0];
    return value != null ?option :  null;           
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  enableForm() {
    this.isReadonly = false;
    this.form.enable();
    // this.form.controls['StreetAndNumber'].disable();
    // this.form.controls['Neighborhood'].disable();
    // this.form.controls['CityId'].disable();
    // this.form.controls['PostalCode'].disable();
    // this.form.controls['Longitude'].disable();
    // this.form.controls['Latitude'].disable();
  }

  cancel() {
    // this.form.reset();
    this.setFormData();
    this.form.disable();
    this.isReadonly = true;
  }

  save() {    

    if (!this.form.valid) {

    }
    else {
      this.isLoadDetails = true;
      this.institutionService.saveInstitutionDetails(this.form.value).then((xxx: string) => {        
        if (!this.dataService.IsError(xxx)) { }
        this.isReadonly = true;
        this.loadData();
      });
    }
  }

  updateAddress() {    
  }

}
