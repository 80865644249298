import { OptionSet } from './OptionSet';

export class Schedule {
    public Year: string;
    public Class: OptionSet;
    public Period: OptionSet;
    public ScheduleType: OptionSet;
    public FromDate: string;
    public ToDate: string;
    public Sunday: OptionSet;
    public Monday: OptionSet;
    public Tuesday: OptionSet;
    public Wednesday: OptionSet;
    public Thursday: OptionSet;
    public Friday: OptionSet;
    public Saturday: OptionSet;
    public Remarks: string;
    public Type: OptionSet;
    public Id_e: string;
    public Id_p: string;
    public _checked: boolean;
}


