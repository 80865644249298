<div class="container">
    <div class="close col-2" (click)="Close()"></div>
    <div class="msg-content col-10">
        <div class="subject">{{message.Subject}}</div>
        <div class="created-on">{{datepipe.transform(message.CreatedOn,'dd/MM/yyyy')}}</div>
        <div class="description">{{message.Description}}</div>
        <div class="line "></div> 
        <div class="attached-files ">                                  
            <div class="files-list " *ngFor="let file of message.AttachedFiles; let i = index" (click)="DownloadFile(i)">                
                <div class="file-data" >{{file.FileName}}</div>
                <div class="attach-icon"></div>
            </div>
        </div>
    </div>
   
    
</div>
