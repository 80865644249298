export class Contact {
    public new_contactnumber: string;
    public BirthDate: Date;
    public FirstName: string;
    public GenderCode: string;
    public LastName: string;
    public MobilePhone: string;
    public Telephone1: string;
    public new_address1_city: string;
    public new_address1_line1: string;
    public new_address1_comment: string;
    public new_address1_line2: string;
    public new_address1_postalcode: string;
    public new_address2_city: string;
    public new_address2_line1: string;
    public new_address2_line2: string;
    public new_address2_postalcode: string;
    public new_class1: string;
    public new_cityid1: string;
    public new_cityid2: string;
    public new_defectid: string;
    public new_sughinuch :string;
    public new_sugasaa: string;
    public new_meunianbehasaha: string;
    //public new_transportationnecessity: string;
    public new_tzaharon: string;
    public new_contactneedsid: string;
    public new_contactid: string;
    public isActiveEventExsist: boolean;
    public new_no_want_ride_due:Date;
    public new_no_want_ride_from:Date;
    public new_contactnewstatus:string;
}