import { BasicRequest } from "./BasicRequest";
import { File } from "./File";
import { Lookup } from "./Lookup";

export class InquiriesRequest extends BasicRequest{
    
    Action: string;
    CaseType: Lookup;
    Student :Lookup;
    Rakaz :Lookup;
    Escort :Lookup;
    Suppluyer :Lookup;
    Account :Lookup;
    Description :string;    
    Email: string;
    UpdatesByEmail: boolean;
    AttachedFiles : Array<File>;    
}