import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sugHasaa'
})
export class SugHasaaPipe implements PipeTransform {

  transform(sugHasaaList: any[], sughasaa: any): any {
    //if (sughasaa === null || sughasaa === undefined)//אם אין ערך בסוג הסעה ניתן לבחור רק אחד מן הערכים הבאים
    //על פי דרישה של מגער סינון האפשרויות
    return sugHasaaList.filter(sug =>
      (sug.value === "100000006") ||//בקשה למונית
      (sug.value === "100000007") ||//בקשה למעלון
      (sug.value === "100000001") ||//מונית לבד
      (sug.value === "100000000") ||//מעלון לבד
      (sug.value === "100000003"));//ללא דרישה
   // return sugHasaaList;
  }

}
