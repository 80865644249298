import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dectivate-student-modal',
  templateUrl: './dectivate-student-modal.component.html',
  styleUrls: ['./dectivate-student-modal.component.scss']
})
export class DectivateStudentModalComponent implements OnInit {
  DeactivateReasons = [
    "אחר",
    "התלמיד עבר מוסד",
    "התלמיד שינה כתובת מגורים ולא צריך הסעה",
    "התלמיד מוותר על ההסעות",
    "התלמיד סיים מסגרת לימודית"]

  public selectedReason: string = null;
  public other: string = null;
  constructor(public dialogRef: MatDialogRef<DectivateStudentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  save() {
    this.dialogRef.close(this.selectedReason != null?this.selectedReason:this.other);
  }

  close() {
    this.dialogRef.close();

  }
}

