import { Injectable } from '@angular/core';
import { BasicRequest } from '../models/BasicRequest';
import { MessageRequest } from '../models/MessageRequest';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  rout = 'MessagesActions';
  request : BasicRequest = new BasicRequest();
  constructor(private dataService : DataService) { }
  
  GetContactMessages(){
    this.request.Action = 'GetContactMessages';
    return this.dataService.setRequstData(this.rout,this.request);    
  }

  UpdateMessageStatus( messageId : string){
    let messageRequest : MessageRequest = new MessageRequest();
    messageRequest.CurrentYear = this.request.CurrentYear;
    messageRequest.InstitutionNumber = this.request.InstitutionNumber;
    messageRequest.Token = this.request.Token;
    messageRequest.Action = 'UpdateMessageStatus';   
    messageRequest.MessageId = messageId;
    return this.dataService.setRequstData(this.rout,messageRequest);
  }
}
