<mat-dialog-content class="dialog-wrap" [formGroup]="inquiryForm">
<div class="dialog-content" *ngIf="!afterSaving">
         <!-- כללי  -->
    <div class="general-content ">
        <div class="general-title head-title ">
            <div class="right-side col-3">                
                <p class="title ">כללי</p>
                <i class="general-icon head-icon " ></i>
            </div>            
            <div class="head-line col-9"></div>
        </div>
        
        <div class="group-inputs">
            <div class="lable-input col-4 ">                
                <label class="col-4">נושא הפניה</label>                               
                <select class="select col-8" [(ngModel)]="inquiryForm.value.subject" formControlName="subject">
                    <option [ngValue]="null" selected>בחר</option>
                    <option *ngFor="let subject of dataLists.Types" [ngValue]="subject">{{subject.Name}}</option>
                </select>
            </div>

            <div class="lable-input col-4">                
                <label class="col-3">סטטוס</label>               
                <input class="col-8"  formControlName="inquiryStatus">              
            </div>

            <div class="lable-input col-4 ">                
                <label class="col-4">תאריך פניה</label>               
                <!-- <mat-form-field [class]="'inputText mat-form-field'" appearance="fill">
                    <input matInput [matDatepicker]="pickerTo" [value]="'20/02/2022'">
                    <mat-datepicker-toggle matSuffix [for]="pickerTo">
                        <i matDatepickerToggleIcon class="datepicker-icon"></i>
                    </mat-datepicker-toggle>
                    <mat-datepicker #pickerTo></mat-datepicker>
                </mat-form-field> -->
                <input class="date-input col-8" type="text" formControlName="date" >
            </div>
        </div>
    </div>
    <!-- פרטי הפניה -->
    <div class="inquiry-content">
        <div class="inquiry-title head-title">
            <div class="right-side col-3">                
                <p class="title ">פרטי הפניה</p>
                <i class="inquiry-icon head-icon " ></i>
            </div>            
            <div class="head-line col-9"></div>
        </div>

        <div class="inputs-content">
            <div class="inputs-line">                
                <div class="col-6 inputs">
                    <label class="right-lable col-4">מלווה</label>
                    <select class="select" [(ngModel)]="inquiryForm.value.escort" formControlName="escort">
                        <option [ngValue]="null" selected>בחר</option>
                        <option *ngFor="let escort of dataLists.Escorts" [ngValue]="escort">{{escort.Name}}</option>
                    </select>
                </div>

                <div class="col-6 inputs">
                    <label class="left-lable col-4">סמל מוסד</label>
                    <input class="left-input col-8" [value]="dataService.userModel.InstitutionNumber" [disabled]="true">
                </div>
            </div>
                           
            <div class="inputs-line">
                <div class="col-6 inputs">
                    <label class="right-lable col-4">תלמיד</label>
                    <select class="select" [(ngModel)]="inquiryForm.value.student" formControlName="student">
                        <option [ngValue]="null" selected>בחר</option>
                        <option *ngFor="let student of dataLists.Students" [ngValue]="student">{{student.Name}}</option>
                    </select>
                </div>

                <div class="col-6 inputs">
                    <label class="left-lable col-4">קבלן</label>
                    <select class="select" [(ngModel)]="inquiryForm.value.suppluyer" formControlName="suppluyer">
                        <option [ngValue]="null" selected>בחר</option>
                        <option *ngFor="let suppluyer of dataLists.Suppluyers" [ngValue]="suppluyer">{{suppluyer.Name}}</option>
                    </select>  
                </div>
            </div>

            <div class="single-input">
                <label class="col-2">תיאור</label>
                <textarea class="col-10" formControlName="description" [value]="inquiryForm.value.description"></textarea>
            </div>
        </div>
    </div>
    <!-- פרטי הפונה -->
    <div class="contact-content">
        <div class="contact-title head-title">
            <div class="right-side col-3">                
                <p class="title ">פרטי הפונה</p>
                <i class="contact-icon head-icon " ></i>
            </div>            
            <div class="head-line col-9"></div>
        </div>

        <div class="inputs-line">
            <div class="col-6 inputs">
                <label class="right-lable col-4">שם מלא</label>
                <input class=" col-8" formControlName="fullName" >
            </div>

            <div class="col-6 inputs">
                <label class="left-lable col-4">כתובת דוא"ל</label>
                <input class="left-input col-8" formControlName="email">
            </div>
        </div>

        <div class="inputs-line">
            <div class="col-6 inputs">
                <label class="right-lable col-4">טלפון נייד</label>
                <input class=" col-8" formControlName="phone" >
            </div>
                      
            <div class="col-6 inputs">
                <label class="left-lable col-4" >קבל עדכונים במייל</label>
                <label class="switch col-8">
                    <input type="checkbox" checked (change)="OnSwitch($event)" formControlName="getUpdatesInEmail">
                    <span class="slider round"></span>
                </label>                
            </div>            
        </div>        
    </div>
    <!-- <div class="big-orang-line"></div>
    <div class="add-files ">
        <div class="uploaded-file col-6" *ngFor="let file of attachedFiles; let i = index">           
            <div class="remove-file-icon icons" (click)="RemoveFile(i)"></div>
            <div class="file-name">{{file.FileName}}</div>
            <div class="file-title">קובץ</div>
        </div>
        <div class="add-file col-6">            
            <label class="add-file-icon icons" (click)="file.click()" ></label>
            <input type="file" #file  (change)="AddFile($event.target.files)" hidden >צרף קובץ
        </div>                
    </div> -->

</div>
<div class="sucsssMassage" *ngIf="afterSaving">
    <div class="sucsssMassageTxt">הפניה התקבלה, ניתן לצפות בסטטוס הפניה בכל שלב</div>
    <div class="sucsssMassageTxt">{{sucsssMassage}} :מס' הפניה </div>
</div>

   <!-- tab design -->
    <mat-tab-group class="tab-content" *ngIf="!afterSaving" >
        
        <mat-tab label="פרטים כלליים">
            <div class="general-content">                        
                <div class="">
                    <div class="tab-input">                
                        <label class="col-4">נושא הפניה</label>                      
                        <select class="select" [(ngModel)]="inquiryForm.value.subject" formControlName="subject">
                            <option [ngValue]="null" selected>בחר</option>
                            <option *ngFor="let subject of dataLists.Types" [ngValue]="subject">{{subject.Name}}</option>
                        </select>
                    </div>
        
                    <div class="tab-input">                
                        <label class="col-4">סטטוס</label>                      
                        <input class=""  formControlName="inquiryStatus">
                    </div>
        
                    <div class="tab-input ">                
                        <label class="col-4">תאריך פניה</label>                        
                        <!-- <mat-form-field [class]="'inputText mat-form-field'" appearance="fill">
                            <input matInput [matDatepicker]="pickerTo2">
                            <mat-datepicker-toggle matSuffix [for]="pickerTo2">
                                <i matDatepickerToggleIcon class="datepicker-icon"></i>
                            </mat-datepicker-toggle>
                            <mat-datepicker #pickerTo2></mat-datepicker>
                        </mat-form-field>               -->
                        <input class="date-input" type="text" formControlName="date" >
                    </div>
                </div>
            </div>
    
        </mat-tab>
       
        <mat-tab label="פרטי הפניה">            
            <div class="tab-input">                
                <label class="col-4">סמל מוסד</label>
                <input class="" [value]="dataService.userModel.InstitutionNumber" [disabled]="true">                              
            </div>            
            <div class="tab-input">                
                <label class="col-4">מלווה</label>
                <select class="select" [(ngModel)]="inquiryForm.value.escort" formControlName="escort">
                    <option [ngValue]="null" selected>בחר</option>
                    <option *ngFor="let escort of dataLists.Escorts" [ngValue]="escort">{{escort.Name}}</option>
                </select>                
            </div>
            <div class="tab-input">                
                <label class="col-4">תלמיד</label>
                <select class="select" [(ngModel)]="inquiryForm.value.student" formControlName="student">
                    <option [ngValue]="null" selected>בחר</option>
                    <option *ngFor="let student of dataLists.Students" [ngValue]="student">{{student.Name}}</option>
                </select>                
            </div>
            <div class="tab-input">                
                <label class="col-4">קבלן</label>
                <select class="select" [(ngModel)]="inquiryForm.value.suppluyer" formControlName="suppluyer">
                    <option [ngValue]="null" selected>בחר</option>
                    <option *ngFor="let suppluyer of dataLists.Suppluyers" [ngValue]="suppluyer">{{suppluyer.Name}}</option>
                </select>                
            </div>

            <div class="tab-input">                
                <label class="col-4">תיאור</label>
               <textarea formControlName="description" [value]="inquiryForm.value.description"></textarea>                
            </div>
    
        </mat-tab>
        <mat-tab label="פרטי הפונה">
            <div class="tab-input">                
                <label class="col-4">שם מלא</label>
                <input formControlName="fullName" >                
            </div>
            <div class="tab-input">                
                <label class="col-4">כתובת דוא"ל</label>
                <input formControlName="email">                  
            </div>
            <div class="tab-input">                
                <label class="col-4">טלפון נייד</label>
               <input formControlName="phone" >                
            </div>
            <div class="tab-input">                               
                <label class="col-4" >קבל עדכונים במייל</label>
                <label class="switch">
                    <input type="checkbox" checked (change)="OnSwitch($event)" formControlName="getUpdatesInEmail">
                    <span class="slider round"></span>
                </label>                                           
            </div>            
        </mat-tab>
        
    
    </mat-tab-group>

    <div class="big-orang-line"></div>
    <!-- upload files -->
    <div class="add-files ">
        <div class="uploaded-file " *ngFor="let file of attachedFiles; let i = index">           
            <div class="remove-file-icon icons" (click)="RemoveFile(i)"></div>
            <div class="file-name">{{file.FileName}}</div>
            <div class="file-title">קובץ</div>
        </div>
        <div class="add-file ">            
            <label class="add-file-icon icons" (click)="file.click()" ></label>
            <input type="file" #file  (change)="AddFile($event.target.files)" hidden >צרף קובץ
        </div>                
    </div>

</mat-dialog-content>




<!-- <div class="big-orang-line"></div> -->
<!-- <mat-dialog-actions> -->
<div class="buttons-footer"> 
    <button class="button save-button" (click)="save()" [hidden]="afterSaving" [disabled]="preserving">שמור</button>   
    <button class="cancel-save-button button" (click)="close()" [disabled]="preserving">סגור</button>      
</div>
<div [hidden]="!preserving" class="saveLoading">...שומר
    <loaders-css [loader]="dataService.saveLoader" [scale]="1" [color]="dataService.color"></loaders-css>
</div> 