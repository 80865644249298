import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { from } from 'rxjs';
import { MatSliderModule } from '@angular/material/slider';
import { HttpClient, HttpHandler, HttpClientModule } from '@angular/common/http';
import { DataLogin } from './services/dataLogin.service';
import { LoginStep1Component } from './components/login-step1/login-step1.component';
import { LoginStep2Component } from './components/login-step2/login-step2.component';
import { BasicComponent } from './components/basic/basic.component';
import { CookieService } from 'ngx-cookie-service';
import { ContactsListComponent } from './components/contacts-list/contacts-list.component';
import { ComponentTitleComponent } from './components/component-title/component-title.component';
import { ScheduleListComponent } from './components/schedule-list/schedule-list.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { PersonalAreaComponent } from './components/personal-area/personal-area.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { InquiriesComponent } from './components/inquiries/inquiries.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule, MatDialogModule, MatDatepickerModule, MatNativeDateModule, DateAdapter, MAT_DATE_LOCALE, MatDividerModule, MatSidenavModule, MatIconModule, MatTabsModule, MatCardModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SearchContactPipe } from './_pipe/search-contact.pipe';
import { CreateContactDialogComponent } from './components/update-contact-dialog/create-contact-dialog.component';
import { DataService } from './services/data.service';
import { ScheduleDialogComponent } from './components/schedule-dialog/schedule-dialog.component';
import { ScheduleContactDialogComponent } from './components/schedule-contact-dialog/schedule-contact-dialog.component';
import { OnlyNumber } from './_directive/OnlyNumber';
import { CustomDateAdapter } from './models/custom.date.adapter';
import { NgxLoadersCssModule } from 'ngx-loaders-css';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PopoverModule } from "ngx-smart-popover";
import { DectivateStudentModalComponent } from './dectivate-student-modal/dectivate-student-modal.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { VacationDialogComponent } from './components/vacation-dialog/vacation-dialog.component';
import { VacationCalendarComponent } from './components/vacation-calendar/vacation-calendar.component'; // import bn-ng-idle service
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { JwPaginationComponent, JwPaginationModule } from 'jw-angular-pagination';
import { SugHasaaPipe } from './_pipe/sug-hasaa.pipe';
import { InstitutionDetailsComponent } from './components/institution-details/institution-details.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { RoutesListComponent } from './components/routes-list/routes-list.component';
import { InstitutionCalendarMainComponent } from './components/institution-calendar/institution-calendar-main/institution-calendar-main.component';
import { InstitutionScheduleComponent } from './components/institution-calendar/institution-schedule/institution-schedule.component';
import { CalendarComponent } from './components/institution-calendar/calendar/calendar.component';
import { CreateInquiryDialogComponent } from './components/create-inquiry-dialog/create-inquiry-dialog.component';
import { MessageDisplayDialogComponent } from './components/message-display-dialog/message-display-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginStep1Component,
    LoginStep2Component,
    BasicComponent,
    ContactsListComponent,
    ComponentTitleComponent,
    ScheduleListComponent,
    HomePageComponent,
    PersonalAreaComponent,
    NotificationsComponent,
    InquiriesComponent,
    SearchContactPipe,
    CreateContactDialogComponent,
    ScheduleDialogComponent,
    ScheduleContactDialogComponent,
    OnlyNumber,
    DectivateStudentModalComponent,
    VacationDialogComponent,
    VacationCalendarComponent,
    SugHasaaPipe,
    InstitutionDetailsComponent,
    RoutesListComponent,
    InstitutionCalendarMainComponent,
    InstitutionScheduleComponent,
    CalendarComponent,
    CreateInquiryDialogComponent,
    MessageDisplayDialogComponent,    

  ],
  imports: [
    PopoverModule,
    MatTooltipModule,
    NgxLoadersCssModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatSelectModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    JwPaginationModule,
    FormsModule,
    HttpClientModule,
    MatSliderModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatSidenavModule,
    MatIconModule,
    MatTabsModule,
    MatCardModule,

    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    DatePipe,
    BnNgIdleService,
    DataService,
    DataLogin,
    CookieService,
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent],
  entryComponents:
    [CreateContactDialogComponent,
      ScheduleDialogComponent,
      ScheduleContactDialogComponent,
      DectivateStudentModalComponent,
      VacationDialogComponent,
      CreateInquiryDialogComponent,
      MessageDisplayDialogComponent]
})
export class AppModule {


}
