import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { error } from 'console';
import { Inquiry } from 'src/app/models/Inquiry';
import { DataService } from 'src/app/services/data.service';
import { InquiryService } from 'src/app/services/inquiry.service';
import { CreateContactDialogComponent } from '../update-contact-dialog/create-contact-dialog.component';
import {CreateInquiryDialogComponent } from '../create-inquiry-dialog/create-inquiry-dialog.component'

@Component({
  selector: 'app-inquiries',
  host: {
    class:'app-inquiries'
  },  
  templateUrl: './inquiries.component.html',
  styleUrls: ['./inquiries.component.scss']
})
export class InquiriesComponent implements OnInit {

  dataSource : Array<Inquiry> = [];
  isLoadingData : boolean = false;
  selectedItemIndex : number=-1;
  isLoadingDataLists : boolean = false;
  noResults : boolean = false;

  constructor(private inquiryService :InquiryService, 
    public dataService : DataService,
    public datepipe: DatePipe,
    private dialog: MatDialog) { }

  ngOnInit() {        
    this.isLoadingData=true;
    this.GetInquiries();
    this.dataService.refreshPageSubject.asObservable().subscribe(() => this.GetInquiries());
  }

  GetInquiries(){ 
    this.dataSource = [];
    this.noResults = false;
    this.isLoadingData = true;   
    this.inquiryService.GetInquiries().then((res :any)=>{
      if(!this.dataService.IsError(res)){       
        this.isLoadingData = false;
        this.ChangeDateFOrmat(res);
        if(res.length > 0){
          this.dataSource = res;
        }
        else{
          this.noResults = true;
        }               
      }
    }).catch(error=>{debugger;} );
  }

  createNewInquiry(){

  }

  ChangeDateFOrmat(arr:Array<Inquiry>){
    arr.forEach(element => {
      element.CreatedOn = new Date(element.CreatedOn);
    });
  }

  CreateInquiry(){
    this.isLoadingDataLists = true;
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    dialogConfig.minWidth = '65vw';
    dialogConfig.data = {};

    this.inquiryService.GetDataLists().then((res:any)=>{
      this.isLoadingDataLists= false;
      if(!this.dataService.IsError(res)){
        dialogConfig.data = res;
        const dialogRef = this.dialog.open(CreateInquiryDialogComponent, dialogConfig);
      }
    });        
  }

  OnSelectItem(i: number){
    this.selectedItemIndex = this.selectedItemIndex == i ? -1 : i;    
  }

}
