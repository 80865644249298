import { Injectable, Inject } from '@angular/core';
import { DataService } from './data.service';
import { StudentRequestModel } from '../models/StudentRequestModel';
import { ContactRow } from '../models/ContactRow';

@Injectable({
  providedIn: 'root'
})
export class StudentService {
  
  public MeunianBehasahaList: any;
  public ContactStatusList: any;
  public ZakautleasaaList: any;
  public SugHasaaList: any;
  public SugChinuchList: any;
  public DeffectsList: any;  
  public CitiesList: any;
  public GenderCodesList: any;
  public TzaharonList: any;
  public TransportatioNecList: any;
  public ClassesList: any;
  public ContactsList: Array<ContactRow> = new Array<ContactRow>();

  request: StudentRequestModel;
  public rout = "StudentsActions";

  constructor(private dataService: DataService) { 
    this.request = new StudentRequestModel();

  }
  SetActive() {
    this.request.Action = "";
    return this.dataService.setRequstData(this.rout, this.request);
  }
  createContact() {
    this.request.Action = "CreateContact";
    return this.dataService.setRequstData(this.rout, this.request);
  }
  GetStudentDetailes() {
    this.request.Action = "GetStudentDetailes";
    return this.dataService.setRequstData(this.rout, this.request);
  }
  GetStudentDetailesForCreate() {
    this.request.Action = "GetStudentDetailesForCreate";
    return this.dataService.setRequstData(this.rout, this.request);
  }
  DeactivateContacts() {
    this.request.Action = "DeactivateContacts";
    return this.dataService.setRequstData(this.rout, this.request);
  }

  GetModalOptions() {
    this.request.Action = "GetModalOptions";
    return this.dataService.setRequstData(this.rout, this.request);
  }

  UpdateHasaaToContact(){
    this.request.Action = "UpdateHasaaToContact";
    return this.dataService.setRequstData(this.rout, this.request);
  }

  GetMeunianbehasaha(){
    this.request.Action = "GetMeunianbehasaha";
    return this.dataService.setRequstData(this.rout, this.request);
  }

  GetZakautleasaa(){
    this.request.Action = "GetZakautleasaa";
    return this.dataService.setRequstData(this.rout, this.request);
  }

  GetAllInstitutuinsStudents() {
    this.request.Action = "GetAllInstitutuinsStudents";
    return this.dataService.setRequstData(this.rout, this.request);

  }
}
