import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDatepickerInputEvent } from '@angular/material';
import { ScheduleListComponent } from '../schedule-list/schedule-list.component';
import { StudentService } from 'src/app/services/student.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Schedule } from 'src/app/models/Schedule';
import { SchedulesService } from 'src/app/services/schedules.service';
import { Schedule_ep } from 'src/app/models/Schedule_ep';
import { OptionSet } from 'src/app/models/OptionSet';
import { ScheduleRow } from 'src/app/models/ScheduleRow';
import { DataService } from 'src/app/services/data.service';
//import { FormGroup, FormControl, FormBuilder } from '@ng-stack/forms';


@Component({
  selector: 'app-schedule-dialog',
  templateUrl: './schedule-dialog.component.html',
  styleUrls: ['./schedule-dialog.component.scss']
})
export class ScheduleDialogComponent implements OnInit {
  public form: FormGroup;
  schedule: Schedule_ep;
  isUpdate: boolean = false;
  public minDate: Date;
  constructor(public dialogRef: MatDialogRef<ScheduleListComponent>,
    @Inject(MAT_DIALOG_DATA) public DialogData,
    private fb: FormBuilder,
    public schedulesService: SchedulesService,
    public dataService: DataService) {

    this.form = new FormGroup({
      Year: new FormControl(''),
      Class: new FormControl(null, Validators.required),
      ScheduleType: new FormControl(null, Validators.required),
      Period: new FormControl(null),
      FromDate: new FormControl(null),
      ToDate: new FormControl(null),
      //איסוף
      Id_e: new FormControl(null),
      Sunday_e: new FormControl(null),
      Monday_e: new FormControl(null),
      Tuesday_e: new FormControl(null),
      Wednesday_e: new FormControl(null),
      Thursday_e: new FormControl(null),
      Friday_e: new FormControl(null),
      Saturday_e: new FormControl(null),

      //פיזור
      Id_p: new FormControl(null),
      Sunday_p: new FormControl(null),
      Monday_p: new FormControl(null),
      Tuesday_p: new FormControl(null),
      Wednesday_p: new FormControl(null),
      Thursday_p: new FormControl(null),
      Friday_p: new FormControl(null),
      Saturday_p: new FormControl(null),

      Remarks: new FormControl(null),
      Status: new FormControl(null),
      StatusReason: new FormControl(null),
    })
    /*debugger*/
    this.isUpdate = DialogData != null;
    if (this.isUpdate) {
      this.schedule = DialogData.data;
    }
  }

  ngOnInit() {

    this.form.controls['ScheduleType'].valueChanges.subscribe(scheduleType => {
      if (scheduleType != null) {
        switch ((Number)(scheduleType.value)) {
          case EnumScheduleType.fixed:
          default:
            this.form.controls['FromDate'].setValidators(null);
            this.form.controls['FromDate'].updateValueAndValidity();
            this.form.controls['ToDate'].setValidators(null);
            this.form.controls['ToDate'].updateValueAndValidity();
            this.form.controls['Period'].setValidators(null);
            this.form.controls['Period'].updateValueAndValidity();
            this.form.controls['Period'].setValue(null);
            this.form.controls['FromDate'].setValue(null);
            this.form.controls['ToDate'].setValue(null);
            break;
          case EnumScheduleType.periodic:
            this.form.controls['FromDate'].setValidators([Validators.required]);
            this.form.controls['FromDate'].updateValueAndValidity();
            this.form.controls['ToDate'].setValidators([Validators.required]);
            this.form.controls['ToDate'].updateValueAndValidity();
            this.form.controls['Period'].setValidators([Validators.required]);
            this.form.controls['Period'].updateValueAndValidity();
            break;
        }
      }


    });

    if (this.isUpdate) {
      this.BuiledFormData(this.schedule);
    }
    else {
      //תעדכן מועד פעילות אחרון אם זה יצירה
      this.schedulesService.SetActive().then((res: string) => {
      }).catch(error => { debugger; })
    }
  }

  BuiledFormData(schedule: Schedule_ep) {
    this.form.controls['Class'].setValue(this.getOption(schedule.Class, this.schedulesService.ClassesList));
    this.form.controls['Period'].setValue(this.getOption(schedule.Period, this.schedulesService.PeriodDescriptionList));
    this.form.controls['ScheduleType'].setValue(this.getOption(schedule.ScheduleType, this.schedulesService.ScheduleTypesList));
    if (this.schedule.ScheduleType != null && this.schedule.ScheduleType.value == EnumScheduleType.periodic.toString()) {
      this.form.controls['FromDate'].setValue(new Date(this.schedule.FromDate));
      this.form.controls['ToDate'].setValue(new Date(this.schedule.ToDate));
    }
    //איסוף
    this.form.controls['Sunday_e'].setValue(this.getOption(schedule.Sunday_e, this.schedulesService.ScheduleHoursList));
    this.form.controls['Monday_e'].setValue(this.getOption(schedule.Monday_e, this.schedulesService.ScheduleHoursList));
    this.form.controls['Tuesday_e'].setValue(this.getOption(schedule.Tuesday_e, this.schedulesService.ScheduleHoursList));
    this.form.controls['Wednesday_e'].setValue(this.getOption(schedule.Wednesday_e, this.schedulesService.ScheduleHoursList));
    this.form.controls['Thursday_e'].setValue(this.getOption(schedule.Thursday_e, this.schedulesService.ScheduleHoursList));
    this.form.controls['Friday_e'].setValue(this.getOption(schedule.Friday_e, this.schedulesService.ScheduleHoursList));
    this.form.controls['Saturday_e'].setValue(this.getOption(schedule.Saturday_e, this.schedulesService.ScheduleHoursList));

    this.form.controls['Id_e'].setValue(schedule.Id_e);
    //פיזור
    this.form.controls['Sunday_p'].setValue(this.getOption(schedule.Sunday_p, this.schedulesService.ScheduleHoursList));
    this.form.controls['Monday_p'].setValue(this.getOption(schedule.Monday_p, this.schedulesService.ScheduleHoursList));
    this.form.controls['Tuesday_p'].setValue(this.getOption(schedule.Tuesday_p, this.schedulesService.ScheduleHoursList));
    this.form.controls['Wednesday_p'].setValue(this.getOption(schedule.Wednesday_p, this.schedulesService.ScheduleHoursList));
    this.form.controls['Thursday_p'].setValue(this.getOption(schedule.Thursday_p, this.schedulesService.ScheduleHoursList));
    this.form.controls['Friday_p'].setValue(this.getOption(schedule.Friday_p, this.schedulesService.ScheduleHoursList));
    this.form.controls['Saturday_p'].setValue(this.getOption(schedule.Saturday_p, this.schedulesService.ScheduleHoursList));

    this.form.controls['Id_p'].setValue(schedule.Id_p);

    this.form.controls['Remarks'].setValue(schedule.Remarks);
    this.form.controls['Status'].setValue(schedule.Status);
    if (schedule.StatusReason != null) {
      this.form.controls['StatusReason'].setValue(schedule.StatusReason);
    }



  }

  getOption(value: OptionSet, options: any) {
    return value != null ?
      options.filter(m => { return m.value == value.value })[0] :
      null;
  }

  changefromDate(event: MatDatepickerInputEvent<Date>) {
    this.minDate = event.value;
  }

  save() {
    var isValid = this.CheckSameSchedulesCount();
    if (!isValid) {
      alert("בקשת מערכת השעות נדחתה מאחר וסך המערכות שמאושרות עבר את המכסה");
      return;
    }

    if (this.form.value.FromDate != null) {
      this.form.value.FromDate = this.form.value.FromDate.toDateString();
    }
    if (this.form.value.ToDate != null) {
      this.form.value.ToDate = this.form.value.ToDate.toDateString();
    }

    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }

  CheckSameSchedulesCount() {
    let result: boolean = true;
    let scheduleTypeCtrl = this.form.controls['ScheduleType'];
    let periodCtrl = this.form.controls['Period'];
    if (scheduleTypeCtrl.dirty) {


      let sameSchedules: Array<ScheduleRow> = this.schedulesService.scheduleList.filter((x) => {
        if (periodCtrl.value != null) {
          return x.ScheduleType.value == scheduleTypeCtrl.value.value && x.Status.value == "1" && periodCtrl.value.viewValue == x.Period;
        } else {
          return x.ScheduleType.value == scheduleTypeCtrl.value.value && x.Status.value == "1";
        }

      });

      debugger;
      let maximum = scheduleTypeCtrl.value.value == EnumScheduleType.fixed.toString() ? this.schedulesService.FixedSchedulesNum : this.schedulesService.PeriodicSchedulesNum;
      result = maximum > sameSchedules.length || maximum == -1;
    }
    return result;
  }

}




export enum EnumScheduleType {
  fixed = 1,
  periodic = 2
}

