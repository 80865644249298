import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {

  rout: string = "RoutesActions";

  constructor(private dataService: DataService) { }

  GetRoutesList() {
    let request = { Action: "GetRoutesList" };
    return this.dataService.setRequstData(this.rout, request);
  }
}
