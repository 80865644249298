<form [formGroup]="formLogin_1">
    <div class="field">
        <div class="title"> סמל מוסד:</div>

        <input  type="text" formControlName="acountId" required minlength="3">
        <div *ngIf="formLogin_1.controls['acountId'].errors != null">
            <!-- <div class="validation" *ngIf="formLogin_1.controls['acountId'].errors.required">
                שדה זה נדרש
            </div>  -->
            <div class="validation" *ngIf="formLogin_1.controls['acountId'].errors.minlength">
                יש להזין סמל מוסד בעל 3 תווים לפחות
            </div>
        </div>
    </div>
    <div class="field">
        <div class="title"> טלפון נייד:</div>
        <input type="text" formControlName="phone" type="tel" required minlength="10">
        <div *ngIf="formLogin_1.controls['phone'].errors != null">
            <!-- <div class="validation" *ngIf="formLogin_1.controls['phone'].errors.required">
                שדה זה נדרש
            </div> -->
            <div class="validation" *ngIf="formLogin_1.controls['phone'].errors.minlength">
                יש להזין נייד תקין באורך 10 תווים לפחות
            </div>
        </div>
    </div>

    <div class="continue-btn">
        <button type="button" class="button" (click)="sendCode('vocal')"
            [ngClass]="{'disabled' : !formLogin_1.valid }" [disabled]=" ! formLogin_1.valid ">המשך עם הודעה
            קולית</button>
        <div class="Loader" [hidden]=" !dataLogin.Loader"></div>
        <button type="button" class="button" (click)="sendCode('')" [ngClass]="{'disabled' : !formLogin_1.valid }"
            [disabled]=" ! formLogin_1.valid ">המשך עם SMS</button>

    </div>    
    <div *ngIf="showWarning" class="warning alert alert-warning alert-dismissible fade show" role="alert">
        <strong>{{warningTitle}}</strong><br>
        <!-- <div style="white-space: pre; width: 80%">{{warningMassage}}</div>   -->
        
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="this.showWarning = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
</form>