import { Injectable } from '@angular/core';
import { InquiriesRequest } from '../models/InquiriesRequest';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class InquiryService {
  rout = 'InquiriesActions';
  request : InquiriesRequest = new InquiriesRequest();

  constructor(private dataService :DataService) { }

  GetInquiries(){
    this.request.Action ='GetInquiries';
    return this.dataService.setRequstData(this.rout,this.request )
  }

  GetDataLists(){
    this.request.Action ='GetDataLists';
    return this.dataService.setRequstData(this.rout,this.request);
  }

  CreateInquiry(){
    this.request.Action ='CreateInquiry';
    return this.dataService.setRequstData(this.rout, this.request);
  }

}
