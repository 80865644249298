import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchContact'
})
export class SearchContactPipe implements PipeTransform {
/**
 *not used because of the pagination!!!!!!!!!!!!!!!!!!!
 */
  transform(searchInList: any, filters?: any): any {
    //debugger
    if (!searchInList) return null;
    if (!filters) return searchInList;

    return searchInList.filter(student => {
      return (student.FullName.indexOf(filters.searchFullName) >= 0
        && student.new_class1.indexOf(filters.searchClass) >= 0
        && student.ContactNumber.indexOf(filters.searchTZ) >= 0);


    })

  }
}
