import { Component, OnInit } from '@angular/core';
import { DataLogin } from 'src/app/services/dataLogin.service';
import { FormGroup, FormControl } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { DataService } from 'src/app/services/data.service';
// import 'rxjs/add/operator/map'
import { UserModel } from 'src/app/models/User';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';


@Component({
  selector: 'app-login-step2',
  templateUrl: './login-step2.component.html',
  styleUrls: ['./login-step2.component.scss']
})
export class LoginStep2Component implements OnInit {
  extraIdSendIn = interval(1000 * 30);//חצי דקה
  extraIdSend: Subscription = new Subscription();
  public isExtraIdAvailable = false;
  formLogin_2: FormGroup = new FormGroup({
    sms: new FormControl('',),
  })
  constructor(public dataLogin: DataLogin,
    public dataService: DataService,
    private router: Router,
    private cookieService: CookieService) { }

  ngOnInit() {
    this.SetExtraId();

  }

  SetExtraId() {
    this.isExtraIdAvailable = false;
    this.extraIdSend = this.extraIdSendIn.subscribe(c => {
      this.isExtraIdAvailable = true;
      this.extraIdSend.unsubscribe();
    })
  }

  //
  sendIdentityAgain() {    
    if (this.isExtraIdAvailable) {
      this.dataLogin.logInObj.msgType = "";
      this.dataLogin.smsSendCode().then((res: string) => {
        this.dataService.IsError(res);
      });
      this.SetExtraId();

    }
    else {
      alert("יש להמתין 30 שניות בין כל שליחת מזהה")
    }

  }

  sendVoiceIdentity() {    
    if (this.isExtraIdAvailable) {
      this.dataLogin.logInObj.msgType = "vocal";
      this.dataLogin.smsSendCode().then((res: string) => {
        this.dataService.IsError(res);
      });
      this.SetExtraId();
    }
    else {
      alert("יש להמתין 30 שניות בין כל שליחת מזהה")

    }


  }

  checkSms = (formLogin_2) => {
    this.dataLogin.Loader = true;
    this.dataLogin.logInObj.sms = formLogin_2.controls.sms.value;

    this.dataLogin.ValidateSmsCode().then((res: string) => {
      this.dataLogin.Loader = false;
      if (!this.dataService.IsError(res)) {
        var jsonRes = JSON.parse(res);
        this.dataService.years = jsonRes.Years;
        delete jsonRes.Years;
        this.dataService.institutions = jsonRes.Institutions;
        delete jsonRes.Institutions;
        debugger;
        this.dataService.userModel = jsonRes;
        this.dataService.selectedAccount = this.dataService.institutions.find(i => i.InstitutionNumber == this.dataService.userModel.InstitutionNumber);
        this.setDataInCookies();
        this.dataService.isLoggedIn = true;

        //this.router.navigateByUrl('/home-page'); - להחליף בחזרה כשיעלה ליייצור
        this.router.navigateByUrl('/contacts-list');
        this.dataLogin.step = "1";//תשים את מסך הלוגין הראשוני כדי שבהתנתקות האוטומטית הוא יגע לשם
        this.dataService.SetActivity();
        this.dataLogin.Loader = false;
      }
    }).catch(error => {

    });
  }

  setDataInCookies() {
    //var datetime = new Date();
    //datetime.setHours(datetime.getHours() + 1);
    this.cookieService.set("Token", this.dataService.userModel.Token);
    this.cookieService.set("AcountFullName", this.dataService.userModel.AcountFullName);
    this.cookieService.set("InstitutionName", this.dataService.userModel.InstitutionName);
    this.cookieService.set("InstitutionNumber", this.dataService.userModel.InstitutionNumber);
    this.cookieService.set("BuisnesUnitPhone", this.dataService.userModel.BuisnesUnitPhone);
    this.cookieService.set("Year", JSON.stringify(this.dataService.userModel.Year));
    this.cookieService.set("Years", JSON.stringify(this.dataService.years));
    this.cookieService.set("Institutions", JSON.stringify(this.dataService.institutions));
    this.cookieService.set("ContactMail", this.dataService.userModel.ContactMail);
    this.cookieService.set("ContactPhone", this.dataService.userModel.ContactPhone);
    /*debugger*/
    this.cookieService.set("IsEditStudentAllowed", String(this.dataService.userModel.IsEditStudentAllowed));
    this.cookieService.set("IsEditScheduleAllowed", String(this.dataService.userModel.IsEditScheduleAllowed));
  }
  addHours(h) {
    return h * 60 * 60 * 1000;
  }


}
