<div class="contant-dialog" [formGroup]="form">
    <div class="d-flex orang-title align-items-center">
        <i class="schedulePropeties-icon"></i>
        <div class="orang-text">פרטי מערכת שעות</div>
        <div class="orang-line"></div>
    </div>
    <div>
        <div class="d-flex justify-content-between contant-inputs">
            <div class="d-flex justify-content-between input-worp first-worp">
                <div class="txt">שנת לימודים</div>
                <div class="selectDiv">
                    <select disabled>
                        <option>{{dataService.userModel.Year.viewValue}}</option>
                    </select>
                </div>
            </div>
            <div class="d-flex justify-content-between input-worp">
                <div class="txt">תלמיד</div>
                <div>
                    <input class="inputText" formControlName="studentName" type="text" disabled>
                    <i class="search-icon"></i>
                </div>

            </div>
        </div>
        <div class="d-flex justify-content-between contant-inputs">
        </div>
    </div>
    <div class="d-flex orang-title align-items-center">
        <i class="scheduleHours-icon"></i>
        <div class="orang-text">שעות איסוף/פיזור</div>
        <div class="orang-line"></div>
    </div>
    <div>
        <div class="d-flex justify-content-between contant-inputs">
            <div class="d-flex justify-content-between input-worp first-worp">
                <div class="txt">שעת איסוף יום א</div>
                <div class="selectDiv">
                    <select formControlName="Sunday_e">
                        <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of studentScheduleService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex justify-content-between input-worp">
                <div class="txt">שעת פיזור יום א</div>
                <div class="selectDiv">
                    <select formControlName="Sunday_p">
                        <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of studentScheduleService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between contant-inputs">
            <div class="d-flex justify-content-between input-worp first-worp">
                <div class="txt">שעת איסוף יום ב</div>
                <div class="selectDiv">
                    <select formControlName="Monday_e">
                        <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of studentScheduleService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex justify-content-between input-worp">
                <div class="txt">שעת פיזור יום ב</div>
                <div class="selectDiv">
                    <select formControlName="Monday_p">
                        <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of studentScheduleService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between contant-inputs">
            <div class="d-flex justify-content-between input-worp first-worp">
                <div class="txt">שעת איסוף יום ג</div>
                <div class="selectDiv">
                    <select formControlName="Tuesday_e">
                        <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of studentScheduleService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex justify-content-between input-worp">
                <div class="txt">שעת פיזור יום ג</div>
                <div class="selectDiv">
                    <select formControlName="Tuesday_p">
                    <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of studentScheduleService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                </select>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between contant-inputs">
            <div class="d-flex justify-content-between input-worp first-worp">
                <div class="txt">שעת איסוף יום ד</div>
                <div class="selectDiv">
                    <select formControlName="Wednesday_e">
                    <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of studentScheduleService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                </select>
                </div>
            </div>
            <div class="d-flex justify-content-between input-worp">
                <div class="txt">שעת פיזור יום ד</div>
                <div class="selectDiv">
                    <select formControlName="Wednesday_p">
                    <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of studentScheduleService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                </select>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between contant-inputs">
            <div class="d-flex justify-content-between input-worp first-worp">
                <div class="txt">שעת איסוף יום ה</div>
                <div class="selectDiv">
                    <select formControlName="Thursday_e">
                    <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of studentScheduleService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                </select>
                </div>
            </div>
            <div class="d-flex justify-content-between input-worp">
                <div class="txt">שעת פיזור יום ה</div>
                <div class="selectDiv">
                    <select formControlName="Thursday_p">
                    <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of studentScheduleService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                </select>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between contant-inputs">
            <div class="d-flex justify-content-between input-worp first-worp">
                <div class="txt">שעת איסוף יום ו</div>
                <div class="selectDiv">
                    <select formControlName="Friday_e">
                    <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of studentScheduleService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                </select>
                </div>
            </div>
            <div class="d-flex justify-content-between input-worp">
                <div class="txt">שעת פיזור יום ו</div>
                <div class="selectDiv">
                    <select formControlName="Friday_p">
                    <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of studentScheduleService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                </select>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between contant-inputs">
            <div class="d-flex justify-content-between input-worp first-worp">
                <div class="txt">שעת איסוף יום ש</div>
                <div class="selectDiv">
                    <select formControlName="Saturday_e">
                    <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of studentScheduleService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                </select>
                </div>
            </div>
            <div class="d-flex justify-content-between input-worp">
                <div class="txt">שעת פיזור יום ש</div>
                <div class="selectDiv">
                    <select formControlName="Saturday_p">
                    <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of studentScheduleService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                </select>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between commentDiv">
            <div class="txt">הערות</div>
            <input formControlName="Remarks" class="inputText commentInput" type="text">
        </div>
        <div class="big-orang-line"></div>
        <div class="contant-buttons d-flex justify-content-end">

            <button class="cancel-save-button" (click)="close()">
                <span>סגור</span>
            </button>
            <button class="save-button" [ngClass]="{'disabled': !form.valid }" [disabled]="! form.valid" (click)="save()">
                <span>שמור</span>
            </button>
        </div>
    </div>
</div>