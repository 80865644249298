import { Component, OnInit } from '@angular/core';
import { DataLogin } from 'src/app/services/dataLogin.service';
import { logging } from 'protractor';
import { from } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { LogInModel } from 'src/app/models/Login';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-login-step1',
  templateUrl: './login-step1.component.html',
  styleUrls: ['./login-step1.component.scss']
})
export class LoginStep1Component implements OnInit {
  public hashPhone: string = "";
  formLogin_1: FormGroup = new FormGroup({
    phone: new FormControl('',),
    acountId: new FormControl('',)
  })
  showWarning:boolean = false;
  warningTitle = 'לא נמצא סמל מוסד';
  warningMassage ="לברורים ניתן לפנות למוקד בטלפון 2786* "+ '\n'+"שעות פעילות המוקד: ימים א'-ה' בין השעות\n 08:00-16:00";
  constructor(public dataLogin: DataLogin,
    public dataService: DataService) { }

  ngOnInit() {
    this.dataLogin.logInObj = new LogInModel();
  }


  sendCode(codeType:string){    
    this.dataLogin.Loader = true;
    this.dataLogin.logInObj.acountId = this.formLogin_1.controls.acountId.value;
    this.dataLogin.logInObj.phone = this.formLogin_1.controls.phone.value;
    this.dataLogin.logInObj.msgType = codeType;
    this.dataLogin.smsSendCode().then((res: string) => {
      if (!this.dataService.IsError(res)) {

        if (res == "success") {
          this.dataLogin.step = "2";
          var p = this.dataLogin.logInObj.phone;
          this.dataLogin.hashPhone = p.substring(0, p.length - 3).replace(/[0-9]/g, "*")
            + p.substring(p.length - 3, p.length)
        }
      }
      else{
        if(res =='error-notFound'){
          this.warningTitle = ' לא נמצא סמל מוסד';
          this.showWarning=true;
        }
        else if(res =='error-contactNotFound'){
          this.warningTitle =' המשתמש לא קיים במערכת';
          this.showWarning=true;
        }
        
        
      }
      this.dataLogin.Loader = false;

    }).catch(error => {

    });
  }
}

