<mat-dialog-content [formGroup]="form" class="contant-dialog">
    <div class="dialog-content">
        <div class="d-flex orang-title align-items-center">
            <i class="id-icon"></i>
            <div class="orang-text">מספר זהות</div>
            <div class="orang-line"></div>
        </div>
        <div class="d-flex justify-content-between contant-inputs divID">
            <div class="d-flex justify-content-between contant-input">
                <div class="txt">סוג פניה</div>
                <input class="inputText mrgInput" type="text" value="עדכון פרטים אישיים" disabled>
            </div>
            <div class="d-flex justify-content-between contant-input">
                <div class="txt">סטטוס תלמיד</div>
                <input class="mrgRightInput inputText" formControlName="new_contactnewstatus" type="text" disabled>
            </div>
            <div class="d-flex justify-content-between contant-input">
                <div class="txt">תאריך פתיחה</div>
                <input class="mrgRightInput inputText" formControlName="openDate" type="datetime" disabled>
            </div>
           
        </div>   
        <div class="d-flex orang-title align-items-center">
            <i class="student-icon"></i>
            <div class="orang-text">פרטי התלמיד</div>
            <div class="orang-line"></div>
        </div>
    
        <div>
            <div class="d-flex justify-content-between contant-inputs">
                <div class="d-flex justify-content-between input-worp first-worp">
                    <div class="txt">
                        מספר זהות/דרכון
                        <div class="required">*</div>
                    </div>
                    <input [ngClass]="{ 'required-field': submitted && f.new_contactnumber.errors }" [readonly]="isUpdate"
                        OnlyNumber="true" class="inputText" type="text" (change)="getStudent()"
                        formControlName="new_contactnumber" [value]="form.value.new_contactnumber">
                </div>
    
                <div class="d-flex justify-content-between input-worp">
                    <div class="txt">כיתה
                        <div class="required">*</div>
                    </div>
                    <div class="selectDiv">
                        <select [ngClass]="{ 'required-field': submitted && f.new_class1.errors }"
                            formControlName="new_class1" [(ngModel)]="form.value.new_class1">
                            <option style="display:none" value=""></option>
                            <option [ngValue]="class" *ngFor="let class of studentService.ClassesList">
                                {{ class.viewValue }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between contant-inputs">
                <div class="d-flex justify-content-between input-worp first-worp">
                    <div class="txt">שם פרטי
                        <div class="required">*</div>
                    </div>
                    <input [ngClass]="{ 'required-field': submitted && f.FirstName.errors }" class="inputText" type="text"
                        formControlName="FirstName" [value]="form.value.FirstName" [readonly]="isUpdate">
                    <!--  -->
                    <!-- <div *ngIf="submitted && f.FirstName.errors">שדה חובה</div> -->
                    <!-- <div *ngIf="submitted && f.FirstName.errors" class="invalid-feedback">
                    </div>  -->
                </div>
                <div class="d-flex justify-content-between input-worp">
                    <div class="txt">שם משפחה
                        <div class="required">*</div>
                    </div>
                    <input [ngClass]="{ 'required-field': submitted && f.LastName.errors }" class="inputText" type="text"
                        formControlName="LastName" [value]="form.value.LastName" [readonly]="isUpdate">
                </div>
            </div>
            <div class="d-flex justify-content-between contant-inputs">
                <div class="d-flex justify-content-between input-worp first-worp">
                    <div class="txt">מין
                        <div class="required">*</div>
                    </div>
                    <div class="selectDiv">
                        <select [ngClass]="{ 'required-field': submitted && f.GenderCode.errors }"
                            formControlName="GenderCode" [(ngModel)]="form.value.GenderCode">
                            <option style="display:none" value=""></option>
                            <option [ngValue]="gender" *ngFor="let gender of studentService.GenderCodesList">
                                {{ gender.viewValue }}
                            </option>
                        </select>
                    </div>
                </div>
    
                <div class="d-flex justify-content-between input-worp">
                    <div class="txt">תאריך לידה
                        <div class="required">*</div>
                    </div>
                    <mat-form-field [ngClass]="{ 'required-field': submitted && f.BirthDate.errors && f.BirthDate.errors }"
                        [class]="'inputText mat-form-field'" appearance="fill">
                        <input matInput [matDatepicker]="picker" formControlName="BirthDate" [value]="form.value.BirthDate">
                        <mat-datepicker-toggle matSuffix [for]="picker">
                            <i matDatepickerToggleIcon class="datepicker-icon"></i>
                            <!-- <mat-icon matDatepickerToggleIcon>arrow_drop_down</mat-icon> -->
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="d-flex justify-content-between contant-inputs">
    
                <div class="d-flex justify-content-between input-worp first-worp">
                    <div class="txt">טלפון נייד אב
                        <div class="required">*</div>
                    </div>
                    <input [ngClass]="{ 'required-field': submitted && f.MobilePhone.errors }" OnlyNumber="true"
                        class="inputText" type="text" formControlName="MobilePhone" [value]="form.value.MobilePhone">
                </div>
                <div class="d-flex justify-content-between input-worp">
                    <div class="txt">טלפון נייד אם</div>
                    <input OnlyNumber="true" class="inputText" type="text" formControlName="Telephone1" [value]="form.value.Telephone1">
                </div>
            </div>
            <div class="d-flex justify-content-between contant-inputs">
                <div class="d-flex justify-content-between input-worp first-worp">
                    <div class="txt">מעוניין בהסעה
                        <div class="required">*</div>
                    </div>
                    <div class="selectDiv">
                        <select (change)="meunianBehasahaChanged()" [ngClass]="{ 'required-field': submitted && f.new_meunianbehasaha.errors }"
                            formControlName="new_meunianbehasaha" [(ngModel)]="form.value.new_meunianbehasaha">
                            <option style="display:none" value=""></option>
                            <option [ngValue]="nec" *ngFor="let nec of studentService.MeunianBehasahaList">
                                {{ nec.viewValue }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="d-flex justify-content-between input-worp">
                    <div class="txt">סוג הסעה</div>
                    <div class="selectDiv">
                        <select formControlName="new_sugasaa" [(ngModel)]="form.value.new_sugasaa">
                            <option style="display:none" value=""></option>
                            <ng-container *ngFor="let sug of studentService.SugHasaaList">
                                <option *ngIf="shouldShowOption(sug)" [ngValue]="sug"
                                    [disabled]="shouldDisableOption(sug)">
                                    {{ sug.viewValue }}
                                </option>
                            </ng-container>
                            <!-- <option [ngValue]="sug"
                                *ngFor="let sug of studentService.SugHasaaList | sugHasaa:form.get('new_sugasaa')?.value"
                                *ngIf="shouldShowOption(sug)" [disabled]="shouldDisableOption(sug)">
                                {{ sug.viewValue }}
                            </option> -->
                        </select>
                    </div>
                </div>
            </div>
            <div *ngIf="f.new_meunianbehasaha.value?.value=='100000006'" class="d-flex justify-content-between contant-inputs">
                <div class="d-flex justify-content-between input-worp first-worp">
                    <div class="txt">מתאריך
                    <div class="required">*</div>
                    </div>
                    <mat-form-field [ngClass]="{ 'required-field': submitted && f.new_no_want_ride_from.errors }"
                        [class]="'inputText mat-form-field'" appearance="fill">
                        <input matInput [matDatepicker]="pickerFrom" formControlName="new_no_want_ride_from">
                        <mat-datepicker-toggle matSuffix [for]="pickerFrom">
                            <i matDatepickerToggleIcon class="datepicker-icon"></i>
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerFrom></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="d-flex justify-content-between input-worp">
                    <div class="txt">עד תאריך
                     <div class="required">*</div>
                    </div>
                    <mat-form-field [ngClass]="{ 'required-field': submitted && f.new_no_want_ride_due.errors }"
                        [class]="'inputText mat-form-field'" appearance="fill">
                        <input matInput [matDatepicker]="pickerTo" formControlName="new_no_want_ride_due">
                        <mat-datepicker-toggle matSuffix [for]="pickerTo">
                            <i matDatepickerToggleIcon class="datepicker-icon"></i>
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerTo></mat-datepicker>
                    </mat-form-field>
                </div>
    
            </div>
            <div class="d-flex justify-content-between contant-inputs">
                <div class="d-flex justify-content-between input-worp first-worp">
                    <div class="txt">סוג חינוך
                        <div class="required">*</div>
                    </div>
                    <div class="selectDiv">
                        <select (change)="sugChinuchChanged()"
                            [ngClass]="{ 'required-field': submitted && f.new_sughinuch.errors }"
                            formControlName="new_sughinuch" [(ngModel)]="form.value.new_sughinuch">
                            <option style="display:none" value=""></option>
                            <option [ngValue]="sug" *ngFor="let sug of studentService.SugChinuchList">
                                {{ sug.viewValue }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="d-flex justify-content-between input-worp">
                    <div class="txt">צהרון</div>
                    <div class="selectDiv">
                        <select formControlName="new_tzaharon" [(ngModel)]="form.value.new_tzaharon">
                            <option style="display:none" value=""></option>
                            <option [ngValue]="tzaharon" *ngFor="let tzaharon of studentService.TzaharonList">
                                {{ tzaharon.viewValue }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <!-- <div *ngIf="f.new_sughinuch.value?.value=='100000001'" class="d-flex justify-content-between contant-inputs">
                <div class="d-flex justify-content-between input-worp singel-input-worp">
                    <div class="txt">לקות
                        <div class="required">*</div>
                    </div>
                    <div class="selectDiv">
                        <select [disabled]="f.new_defectid!=null" [ngClass]="{ 'required-field': submitted && f.new_defectid.errors }"
                            formControlName="new_defectid">
                            <option style="display:none" value=""></option>
                            <option [ngValue]="deff" *ngFor="let deff of studentService.DeffectsList">
                                {{ deff.viewValue }}
                            </option>
                        </select>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="d-flex orang-title align-items-center">
            <i class="address-icon"></i>
            <div class="orang-text">כתובות</div>
            <div class="orang-line small"></div>
            <div class="orang-text">כתובת משנית</div>
            <div class="orang-line small min"></div>
        </div>
        <div>
            <!-- <div>כתובות</div> -->
            <!-- <div>כתובת ראשית</div> -->
            <div class="d-flex justify-content-between contant-address ">
                <div class="d-flex justify-content-between input-worp first-border">
                    <div class="txt">רחוב ומספר
                        <div class="required">*</div>
                    </div>
                    <input [ngClass]="{ 'required-field': submitted && f.new_address1_line1.errors }" class="inputText"
                        type="text" formControlName="new_address1_line1" [value]="form.value.new_address1_line1">
                </div>
                <div class="d-flex justify-content-between input-worp after-border">
                    <div class="txt">רחוב ומספר</div>
                    <input class="inputText" type="text" formControlName="new_address2_line1">
                </div>
            </div>
    
            <div class="d-flex justify-content-between contant-address ">
                <div class="d-flex justify-content-between input-worp first-border">
                    <div class="txt">שכונה
                        <div class="required">*</div>
                    </div>
                    <input [ngClass]="{ 'required-field': submitted && f.new_address1_line2.errors }" class="inputText"
                        type="text" formControlName="new_address1_line2" [value]="form.value.new_address1_line2">
                </div>
                <div class="d-flex justify-content-between input-worp after-border">
                    <div class="txt">שכונה</div>
                    <input class="inputText" type="text" formControlName="new_address2_line2" [value]="form.value.new_address2_line2">
                </div>
            </div>
    
            <div class="d-flex justify-content-between contant-address ">
                <div class="d-flex justify-content-between input-worp first-border">
                    <div class="txt">ישוב
                        <div class="required">*</div>
                    </div>
                    <div class="selectDiv">
                        <select [ngClass]="{ 'required-field': submitted && f.new_cityid1.errors }"
                            formControlName="new_cityid1" [(ngModel)]="form.value.new_cityid1">
                            <option style="display:none" value=""></option>
                            <option [ngValue]="c1" *ngFor="let c1 of studentService.CitiesList">
                                {{ c1.viewValue }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="d-flex justify-content-between input-worp after-border">
                    <div class="txt">ישוב
                    </div>
                    <div class="selectDiv">
                        <select formControlName="new_cityid2" [(ngModel)]="form.value.new_cityid2">
                            <option style="display:none" value=""></option>
                            <option [ngValue]="c2" *ngFor="let c2 of studentService.CitiesList">
                                {{ c2.viewValue }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between contant-address ">
                <div class="d-flex justify-content-between input-worp first-border">
                    <div class="txt">מיקוד</div>
                    <input OnlyNumber="true" class="inputText" type="text" formControlName="new_address1_postalcode" [value]="form.value.new_address1_postalcode">
                </div>
                <div class="d-flex justify-content-between input-worp after-border">
                    <div class="txt">מיקוד</div>
                    <input OnlyNumber="true" class="inputText" type="text" formControlName="new_address2_postalcode" [value]="form.value.new_address2_postalcode">
                </div>
            </div>
            <div class="d-flex justify-content-between contant-address ">
                <div class="d-flex justify-content-between input-worp first-border">
                    <div class="txt">הערות</div>
                    <input class="inputText" type="text" formControlName="new_address1_comment" [value]="form.value.new_address1_comment">
                </div>
    
            </div>
        </div>

    </div>


    <mat-tab-group [formGroup]="form" dynamicHeight>


        <!-- //מספר זהות -->
        <mat-tab label="מספר זהות">
    
            <div class="d-flex orang-title align-items-center">
                <i class="id-icon"></i>
                <div class="orang-text">מספר זהות</div>
                <div class="orang-line"></div>
            </div>
    
            <div class="d-flex justify-content-between contant-inputs divID">
               
                    <div class="txt">סוג פניה</div>
                    <input class="inputText mrgInput" type="text" value="עדכון פרטים אישיים" disabled>
    
                    <div class="txt">סטטוס תלמיד</div>
                    <input class="mrgRightInput inputText" formControlName="new_contactnewstatus" type="text" disabled>
    
                    <div class="txt">תאריך פתיחה</div>
                    <input class="mrgRightInput inputText" formControlName="openDate" type="datetime" disabled>
    
            </div>
        </mat-tab>
    
        <!-- //פרטי תלמיד -->
        <mat-tab label="פרטי התלמיד">
    
            <div class="d-flex orang-title align-items-center">
                <i class="student-icon"></i>
                <div class="orang-text">פרטי התלמיד</div>
                <div class="orang-line"></div>
            </div>
    
            <div class="d-flex justify-content-between contant-inputs">
                <div class="txt">
                    מספר זהות/דרכון
                    <div class="required">*</div>
                </div>
                <input [ngClass]="{ 'required-field': submitted && f.new_contactnumber.errors }" [readonly]="isUpdate"
                    OnlyNumber="true" class="inputText" type="text" (change)="getStudent()"
                    formControlName="new_contactnumber" [value]="form.value.new_contactnumber">
    
                <div class="txt">כיתה
                    <div class="required">*</div>
                </div>
                <!-- <div class="selectDiv"> -->
                    <select [ngClass]="{ 'required-field': submitted && f.new_class1.errors }" formControlName="new_class1" [(ngModel)]="form.value.new_class1">
                        <option style="display:none" value=""></option>
                        <option [ngValue]="class" *ngFor="let class of studentService.ClassesList">
                            {{ class.viewValue }}
                        </option>
                    </select>
                <!-- </div> -->
    
                <div class="txt">שם פרטי
                    <div class="required">*</div>
                </div>
                <input [ngClass]="{ 'required-field': submitted && f.FirstName.errors }" class="inputText" type="text"
                    formControlName="FirstName" [value]="form.value.FirstName" [readonly]="isUpdate">
    
                <div class="txt">שם משפחה
                    <div class="required">*</div>
                </div>
                <input [ngClass]="{ 'required-field': submitted && f.LastName.errors }" class="inputText" type="text"
                    formControlName="LastName" [value]="form.value.LastName" [readonly]="isUpdate">
    
                <div class="txt">מין
                    <div class="required">*</div>
                </div>
                <!-- <div class="selectDiv"> -->
                    <select [ngClass]="{ 'required-field': submitted && f.GenderCode.errors }" formControlName="GenderCode" [(ngModel)]="form.value.GenderCode">
                        <option style="display:none" value=""></option>
                        <option [ngValue]="gender" *ngFor="let gender of studentService.GenderCodesList">
                            {{ gender.viewValue }}
                        </option>
                    </select>
                <!-- </div> -->
    
                <div class="txt">תאריך לידה
                    <div class="required">*</div>
                </div>
                <mat-form-field [ngClass]="{ 'required-field': submitted && f.BirthDate.errors && f.BirthDate.errors }"
                    [class]="'inputText mat-form-field'" appearance="fill">
                    <input matInput [matDatepicker]="pickerTo" formControlName="BirthDate" [value]="form.value.BirthDate">
                    <mat-datepicker-toggle matSuffix [for]="pickerTo">
                        <i matDatepickerToggleIcon class="datepicker-icon"></i>
                    </mat-datepicker-toggle>
                    <mat-datepicker #pickerTo></mat-datepicker>
                </mat-form-field>
    
    
    
                <div class="txt">טלפון נייד אב
                    <div class="required">*</div>
                </div>
                <input [ngClass]="{ 'required-field': submitted && f.MobilePhone.errors }" OnlyNumber="true"
                    class="inputText" type="text" formControlName="MobilePhone" [value]="form.value.MobilePhone">
    
    
    
                <div class="txt">טלפון נייד אם</div>
                <input OnlyNumber="true" class="inputText" type="text" formControlName="Telephone1" [value]="form.value.Telephone1">
    
                <div class="txt">מעוניין בהסעה
                    <div class="required">*</div>
                </div>
                <!-- <div class="selectDiv"> -->
                    <select (change)="meunianBehasahaChanged()"
                        [ngClass]="{ 'required-field': submitted && f.new_meunianbehasaha.errors }"
                        formControlName="new_meunianbehasaha" [(ngModel)]="form.value.new_meunianbehasaha">
                        <option style="display:none" value=""></option>
                        <option [ngValue]="nec" *ngFor="let nec of studentService.MeunianBehasahaList">
                            {{ nec.viewValue }}
                        </option>
                    </select>
                <!-- </div> -->
    
    
                <div class="txt">סוג הסעה</div>
                <!-- <div class="selectDiv"> -->
                    <select formControlName="new_sugasaa" [(ngModel)]="form.value.new_sugasaa">
                        <option style="display:none" value=""></option>
                        <option [ngValue]="sug"
                            *ngFor="let sug of studentService.SugHasaaList | sugHasaa:form.get('new_sugasaa')?.value">
                            {{ sug.viewValue }}
                        </option>
                    </select>
                <!-- </div> -->
    
                <div *ngIf="f.new_meunianbehasaha.value?.value=='100000006'"
                    class="d-flex justify-content-between contant-inputs">
                    <div class="d-flex justify-content-between input-worp first-worp">
                        <div class="txt">מתאריך
                            <div class="required">*</div>
                        </div>
                        <mat-form-field [ngClass]="{ 'required-field': submitted && f.new_no_want_ride_from.errors }"
                            [class]="'inputText mat-form-field'" appearance="fill">
                            <input matInput [matDatepicker]="pickerFrom" formControlName="new_no_want_ride_from">
                            <mat-datepicker-toggle matSuffix [for]="pickerFrom">
                                <i matDatepickerToggleIcon class="datepicker-icon"></i>
                            </mat-datepicker-toggle>
                            <mat-datepicker #pickerFrom></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="d-flex justify-content-between input-worp">
                        <div class="txt">עד תאריך
                            <div class="required">*</div>
                        </div>
                        <mat-form-field [ngClass]="{ 'required-field': submitted && f.new_no_want_ride_due.errors }"
                            [class]="'inputText mat-form-field'" appearance="fill">
                            <input matInput [matDatepicker]="pickerTo" formControlName="new_no_want_ride_due">
                            <mat-datepicker-toggle matSuffix [for]="pickerTo">
                                <i matDatepickerToggleIcon class="datepicker-icon"></i>
                            </mat-datepicker-toggle>
                            <mat-datepicker #pickerTo></mat-datepicker>
                        </mat-form-field>
                    </div>
    
                </div>
    
                <div class="txt">סוג חינוך
                    <div class="required">*</div>
                </div>
                <!-- <div class="selectDiv"> -->
                    <select (change)="sugChinuchChanged()"
                        [ngClass]="{ 'required-field': submitted && f.new_sughinuch.errors }"
                        formControlName="new_sughinuch" [(ngModel)]="form.value.new_sughinuch">
                        <option style="display:none" value=""></option>
                        <option [ngValue]="sug" *ngFor="let sug of studentService.SugChinuchList">
                            {{ sug.viewValue }}
                        </option>
                    </select>
                <!-- </div> -->
    
    
                <div class="txt">צהרון</div>
                <!-- <div class="selectDiv"> -->
                    <select formControlName="new_tzaharon" [(ngModel)]="form.value.new_tzaharon">
                        <option style="display:none" value=""></option>
                        <option [ngValue]="tzaharon" *ngFor="let tzaharon of studentService.TzaharonList">
                            {{ tzaharon.viewValue }}
                        </option>
                    </select>
                <!-- </div> -->
    
            </div>
        </mat-tab>
    
        <!-- <div>כתובות</div> -->
        <mat-tab label="כתובות">
    
            <div class="d-flex orang-title align-items-center">
                <i class="address-icon"></i>
                <div class="orang-text">כתובות</div>
                <div class="orang-line"></div>
            </div>
    
            <div class="d-flex justify-content-between contant-inputs">
    
                <div class="txt">רחוב ומספר
                    <div class="required">*</div>
                </div>
                <input [ngClass]="{ 'required-field': submitted && f.new_address1_line1.errors }" class="inputText"
                    type="text" formControlName="new_address1_line1" [value]="form.value.new_address1_line1">
    
    
                <div class="txt">שכונה
                    <div class="required">*</div>
                </div>
                <input [ngClass]="{ 'required-field': submitted && f.new_address1_line2.errors }" class="inputText"
                    type="text" formControlName="new_address1_line2" [value]="form.value.new_address1_line2">
    
                <div class="txt">ישוב
                    <div class="required">*</div>
                </div>
    
                <!-- <div class="selectDiv"> -->
                    <select [ngClass]="{ 'required-field': submitted && f.new_cityid1.errors }"
                        formControlName="new_cityid1" [(ngModel)]="form.value.new_cityid1">
                        <option style="display:none" value=""></option>
                        <option [ngValue]="c1" *ngFor="let c1 of studentService.CitiesList">
                            {{ c1.viewValue }}
                        </option>
                    </select>
                <!-- </div> -->
    
    
                <div class="txt">מיקוד</div>
                <input OnlyNumber="true" class="inputText" type="text" formControlName="new_address1_postalcode" [value]="form.value.new_address1_postalcode">
    
    
                <div class="txt">הערות</div>
                <input class="inputText" type="text" formControlName="new_address1_comment" [value]="form.value.new_address1_comment">
    
            </div>
    
        </mat-tab>
    
        <!-- <div>כתובת משנית</div> -->
        <mat-tab label="כתובת משנית">
    
            <div class="d-flex orang-title align-items-center">
                <i class="address-icon"></i>
              <div class="orang-text">כתובת משנית</div>
              <div class="orang-line"></div>
            </div>
    
            <div class="d-flex justify-content-between contant-inputs">
    
                <div class="d-flex justify-content-between input-worp after-border">
                </div>
    
                <div class="txt">רחוב ומספר</div>
                <input class="inputText" type="text" formControlName="new_address2_line1" [value]="form.value.new_address2_line1">
    
                <div class="txt">שכונה</div>
                <input class="inputText" type="text" formControlName="new_address2_line2" [value]="form.value.new_address2_line2">
    
    
                <div class="txt">ישוב
                </div>
                <!-- <div class="selectDiv"> -->
                    <select formControlName="new_cityid2" [(ngModel)]="form.value.new_cityid2">
                        <option style="display:none" value=""></option>
                        <option [ngValue]="c2" *ngFor="let c2 of studentService.CitiesList">
                            {{ c2.viewValue }}
                        </option>
                    </select>
                <!-- </div> -->
    
    
                <div class="txt">מיקוד</div>
                <input OnlyNumber="true" class="inputText" type="text" formControlName="new_address2_postalcode" [value]="form.value.new_address2_postalcode">
    
                <div class="txt">הערות</div>
                <input class="inputText" type="text" formControlName="new_address1_comment" [value]="form.value.new_address1_comment">
    
            </div>
    
        </mat-tab>
    
    
    </mat-tab-group>
</mat-dialog-content>

<!-- #docregion dynamic-height -->





<div class="big-orang-line"></div>
<!-- <mat-dialog-actions> -->
<!-- <div class="contant-buttons d-flex justify-content-end"> -->
<div class="d-flex btns">
    <div *ngIf="isUpdate && isActiveEventExsist" class="alert alert-danger ">שים לב! לתלמיד זה כבר קיימת פניה לעדכון
        במערכת.
    </div>
    <div [hidden]="!searchStudentLoading" class="saveLoading">מחפש תלמיד...
        <loaders-css [loader]="dataService.saveLoader" [scale]="1" [color]="dataService.color"></loaders-css>
    </div>
    <button class="cancel-save-button button" (click)="close()">סגור</button>

    <button class="button save-button" (click)="save()">שמור</button>
</div>



<!-- </mat-dialog-actions> -->