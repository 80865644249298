<div class="contant-dialog" [formGroup]="form">
    <div class="d-flex orang-title align-items-center">
        <i class="schedulePropeties-icon"></i>
        <div class="orang-text">פרטי החופשה</div>
        <div class="orang-line"></div>
    </div>
    <div>
        <div class="d-flex justify-content-between contant-inputs">
            <div class="d-flex justify-content-between input-worp first-worp">
                <div class="txt">בחר סוג חופשה</div>
                <div class="selectDiv">
                    <select formControlName="VacationType">
                        <option value="">בחר</option>
                        <option value="1">יום קצר</option>
                        <option value="2">חופשה</option>
                    </select>
                </div>
            </div>
            <div class="d-flex justify-content-between input-worp">
                <div class="txt">שנת לימודים</div>
                <div class="selectDiv">
                    <select disabled>
                        <option>{{dataService.userModel.Year.viewValue}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between contant-inputs">
        </div>
    </div>
    <div class="d-flex orang-title align-items-center">
        <i class="scheduleHours-icon"></i>
        <div class="orang-text">זמן החופשה</div>
        <div class="orang-line"></div>
    </div>
    <div>
        <div *ngIf='form.value["VacationType"] == "1" || form.value["VacationType"] == "2"'>
            <div class="d-flex justify-content-between contant-inputs">
                <div class="d-flex justify-content-between input-worp first-worp">
                    <div class="txt">
                        <ng-container *ngIf='form.value["VacationType"] == "1"'> תאריך</ng-container>
                        <ng-container *ngIf='form.value["VacationType"] == "2"'> החל מתאריך</ng-container>
                        <div class="required">*</div>
                    </div>

                    <mat-form-field [class]="'DatePickerDiv inputText mat-form-field'" appearance="fill">
                        <input matInput [matDatepicker]="picker" formControlName="Date" (dateChange)="changefromDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker">
                            <i matDatepickerToggleIcon class="datepicker-icon"></i>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                </div>
                <div class="d-flex justify-content-between input-worp" *ngIf='form.value["VacationType"] == "1"'>
                    <div class="txt">שעת פיזור
                        <div class="required">*</div>
                    </div>
                    <div class="selectDiv">
                        <select formControlName="ReturnTime">
                            <option value=""></option>
                            <option [ngValue]="hour" *ngFor="let hour of schedulesService.ScheduleHoursList">
                                {{ hour.viewValue }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="d-flex justify-content-between input-worp" *ngIf='form.value["VacationType"] == "2"'>
                    <div class="txt">עד תאריך
                        <div class="required">*</div>
                    </div>
                    <mat-form-field [class]="' DatePickerDiv inputText mat-form-field'" appearance="fill">
                        <input matInput [min]="minDate" [matDatepicker]="picker2" formControlName="DateTo">
                        <mat-datepicker-toggle matSuffix [for]="picker2">
                            <i matDatepickerToggleIcon class="datepicker-icon"></i>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>

                </div>
            </div>

            <div class="d-flex justify-content-between contant-inputs">
                <div class="d-flex justify-content-between input-worp first-worp">
                    <div class="txt">תיאור התקופה</div>
                    <div class="selectDiv">
                        <select formControlName="Period">
                            <option value=""></option>
                            <option [ngValue]="period" *ngFor="let period of schedulesService.PeriodDescriptionList">
                                {{ period.viewValue }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="d-flex justify-content-between input-worp">
                </div>
            </div>

            <div class="d-flex justify-content-between commentDiv ReasonDiv">
                <div class="txt">סיבת החופשה
                    <div class="required">*</div>
                </div>
                <input formControlName="Reason" class="inputText commentInput" type="text">
            </div>

            <div class="d-flex justify-content-between commentDiv">
                <div class="txt">הערות</div>
                <input formControlName="Remark" class="inputText commentInput" type="text">
            </div>
        </div>
        <div class="big-orang-line"></div>
        <div class="contant-buttons d-flex justify-content-end">

            <button class="cancel-save-button" (click)="close()">
                <span>סגור</span>
            </button>
            <button *ngIf="!isUpdate||vacation.Status!='אושר'" class="save-button" [ngClass]="{'disabled': !form.valid }" [disabled]="! form.valid" (click)="save()">
                <span>שמור</span>
            </button>
        </div>
    </div>
</div>



<!-- 
<div [formGroup]="form" dir="rtl" class="main-dialog">
    <div class="d-flex justify-content-between">
        <div class="txt">בחר סוג חופשה</div>
        <select (change)="onChange($event.target.value)" formControlName="VacationType">
            <option value="">בחר</option>
            <option value="1">יום קצר</option>
            <option value="2">חופשה</option>
        </select>
    </div>
    <div class="d-flex justify-content-between input-worp first-worp">
        <div class="txt">שנת לימודים</div>
        <div class="selectDiv">
            <select disabled>
                <option>תשפא</option>
            </select>
        </div>
    </div>

<div *ngIf='form.value["VacationType"] == "1"'>
    <div class="d-flex justify-content-between">
        <div class="txt">תאריך 
            <div class="required">*</div>
        </div>
        <mat-form-field [class]="'inputText mat-form-field'" appearance="fill">
            <input matInput [matDatepicker]="picker" formControlName="Date">
            <mat-datepicker-toggle matSuffix [for]="picker">
                <i matDatepickerToggleIcon class="datepicker-icon"></i>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>
        <div class="d-flex justify-content-between commentDiv">
            <div class="txt">הערה</div>
            <input formControlName="Remark" class="inputText commentInput" type="text">
        </div>
        <div class="d-flex justify-content-between input-worp first-worp">
            <div class="txt">שעת פיזור</div>
            <div class="selectDiv">
            <select formControlName="ReturnTime">
                <option value=""></option>
                <option [ngValue]="hour" *ngFor="let hour of schedulesService.ScheduleHoursList">
                    {{ hour.viewValue }}
                </option>
            </select>
           </div>
        </div>
        <div class="d-flex justify-content-between commentDiv">
            <div class="txt">סיבת חופשה</div>
            <input formControlName="Reason" class="inputText commentInput" type="text">
        </div>     
</div>

<div *ngIf='form.value.VacationType == "2"'>
    <div class="d-flex justify-content-between">
        <div class="txt">החל מתאריך 
            <div class="required">*</div>
        </div>
        <mat-form-field [class]="'inputText mat-form-field'" appearance="fill">
            <input matInput [matDatepicker]="picker1" formControlName="Date">
            <mat-datepicker-toggle matSuffix [for]="picker1">
                <i matDatepickerToggleIcon class="datepicker-icon"></i>
            </mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="d-flex justify-content-between">
        <div class="txt">עד תאריך 
            <div class="required">*</div>
        </div>
        <mat-form-field [class]="'inputText mat-form-field'" appearance="fill">
            <input matInput [matDatepicker]="picker2" formControlName="DateTo">
            <mat-datepicker-toggle matSuffix [for]="picker2">
                <i matDatepickerToggleIcon class="datepicker-icon"></i>
            </mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
    </div>
        <div class="d-flex justify-content-between commentDiv">
            <div class="txt">הערה</div>
            <input formControlName="Remark" class="inputText commentInput" type="text">
        </div>
        <div class="d-flex justify-content-between commentDiv">
            <div class="txt">סיבת חופשה</div>
            <input formControlName="Reason" class="inputText commentInput" type="text">
        </div>     
</div>


<div class="big-orang-line"></div>
<div class="contant-buttons d-flex justify-content-end">
    <button class="cancel-save-button" (click)="close()">
        <span>סגור</span>
    </button>
    <button class="save-button" [disabled]="! form.valid" (click)="save()">
        <span>שמור</span>
    </button>
</div> 


</div>
 -->