import { Output, Component, EventEmitter, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-component-title',
  templateUrl: './component-title.component.html',
  styleUrls: ['./component-title.component.scss']
})
export class ComponentTitleComponent implements OnInit {
  @Input() componentName: string;
  @Input() IconName: string;
  @Output() 
  onClickButton: EventEmitter<void> = new EventEmitter<void>();

  constructor(public dataService: DataService) { }

  ngOnInit() {
  }

  openSearch() {
    this.onClickButton.emit();
  }
}
