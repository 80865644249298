<div class="content">

    <div class="component-title">
        <app-component-title componentName="לוח שנה למוסד" IconName="ScheduleIcon">
        </app-component-title>
    </div>

    <div class="content">
        <div class="row">
            <div class="col-3">
                <app-institution-schedule></app-institution-schedule>
            </div>
            <div class="col-9">
                <app-calendar></app-calendar>
            </div>
        </div>
    </div>

</div>