import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ContactsListComponent } from '../contacts-list/contacts-list.component';
import { ContactRow } from 'src/app/models/ContactRow';
import { StudentService } from 'src/app/services/student.service';
import { StudentScheduleService } from 'src/app/services/student-schedule.service';
import { StudentSchedule } from 'src/app/models/StudentSchedule';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OptionSet } from 'src/app/models/OptionSet';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-schedule-contact-dialog',
  templateUrl: './schedule-contact-dialog.component.html',
  styleUrls: ['./schedule-contact-dialog.component.scss']
})
export class ScheduleContactDialogComponent implements OnInit {
  public student: ContactRow;
  public studentSchedule: StudentSchedule = new StudentSchedule();
  public form: FormGroup;

  constructor(public dialogRef: MatDialogRef<ContactsListComponent>,
    public studentService: StudentService,
    public studentScheduleService: StudentScheduleService,
    public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public DialogData) {
    /*debugger*/
    this.studentSchedule = DialogData.studentData.studentSchedule;
    this.student = DialogData.studentData.student;


    this.form = new FormGroup({
      //איסוף
      studentName: new FormControl(this.student.FullName),
      Sunday_e: new FormControl(null),
      Monday_e: new FormControl(null),
      Tuesday_e: new FormControl(null),
      Wednesday_e: new FormControl(null),
      Thursday_e: new FormControl(null),
      Friday_e: new FormControl(),
      Saturday_e: new FormControl(null),

      //פיזור
      Sunday_p: new FormControl(null),
      Monday_p: new FormControl(null),
      Tuesday_p: new FormControl(null),
      Wednesday_p: new FormControl(null),
      Thursday_p: new FormControl(null),
      Friday_p: new FormControl(null),
      Saturday_p: new FormControl(null),

      Remarks: new FormControl(null),

      ScheduleStudententId: new FormControl(this.student.StudentScheduleId),
      StudententId: new FormControl(this.student.new_contactid),
      StudententNeedsId: new FormControl(this.student.new_contactneedsid)
    })
  }

  ngOnInit() {
    /*debugger*/
    if (this.studentSchedule != null) {//אם קיימת מערכת שעות לתלמיד
      this.SetFormData();
    }
  }
  SetFormData() {
    this.form.controls['Sunday_e'].setValue(this.getOption(this.studentSchedule.Sunday_e));
    this.form.controls['Monday_e'].setValue(this.getOption(this.studentSchedule.Monday_e));
    this.form.controls['Thursday_e'].setValue(this.getOption(this.studentSchedule.Thursday_e));
    this.form.controls['Wednesday_e'].setValue(this.getOption(this.studentSchedule.Wednesday_e));
    this.form.controls['Tuesday_e'].setValue(this.getOption(this.studentSchedule.Tuesday_e));
    this.form.controls['Friday_e'].setValue(this.getOption(this.studentSchedule.Friday_e));
    this.form.controls['Saturday_e'].setValue(this.getOption(this.studentSchedule.Saturday_e));

    this.form.controls['Sunday_p'].setValue(this.getOption(this.studentSchedule.Sunday_p));
    this.form.controls['Monday_p'].setValue(this.getOption(this.studentSchedule.Monday_p));
    this.form.controls['Thursday_p'].setValue(this.getOption(this.studentSchedule.Thursday_p));
    this.form.controls['Wednesday_p'].setValue(this.getOption(this.studentSchedule.Wednesday_p));
    this.form.controls['Tuesday_p'].setValue(this.getOption(this.studentSchedule.Tuesday_p));
    this.form.controls['Friday_p'].setValue(this.getOption(this.studentSchedule.Friday_p));
    this.form.controls['Saturday_p'].setValue(this.getOption(this.studentSchedule.Saturday_p));

    this.form.controls['Remarks'].setValue(this.studentSchedule.Remarks);

  }

  getOption(value: OptionSet) {
    return value != null ?
      this.studentScheduleService.ScheduleHoursList.filter(m => { return m.value == value.value })[0] :
      null;
  }

  save() {
    /*debugger*/

    this.dialogRef.close(this.form.value);
  }
  close() {
    this.dialogRef.close();
  }

}
