<div class="all">
    <div class="content">
        <div class="title-content">
            <div class="icon {{IconName}}"></div>
            <div class="title" [ngClass]="{'font-lg':componentName =='פניות'}">{{componentName}}</div>
        </div>
        <div class="btn-content">
            <div >
                <button class="refresh" [hidden]="componentName != 'תלמידים' && componentName != 'מסלולים'" (click)="dataService.SetIsRefresh(true)"></button>
            </div>
            <div >
                <button class="search" [hidden]="componentName!='תלמידים'" (click)="openSearch()"></button>
            </div>
            <div >
                <button class="close-btn" [hidden]="componentName!='תלמידים'"></button>
            </div>
        </div>                
    </div>
</div>