<div class="contant-dialog" [formGroup]="form">
    <div class="d-flex orang-title align-items-center">
        <i class="schedulePropeties-icon"></i>
        <div class="orang-text">פרטי מערכת שעות</div>
        <div class="orang-line"></div>
    </div>
    <div>
        <div class="d-flex justify-content-between contant-inputs">
            <div class="d-flex justify-content-between input-worp first-worp">
                <div class="txt">כיתה</div>
                <div class="selectDiv">
                    <select formControlName="Class">
                        <option value=""></option>
                        <option [ngValue]="class" *ngFor="let class of schedulesService.ClassesList">
                            {{ class.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex justify-content-between input-worp">
                <div class="txt">שנת לימודים</div>
                <div class="selectDiv">
                    <select disabled>
                        <option>{{dataService.userModel.Year.viewValue}}</option>
                    </select>
                </div>
                <!-- <input class="inputText" type="text"> -->
            </div>

        </div>

        <div class="d-flex justify-content-between contant-inputs">
            <div class="d-flex justify-content-between input-worp first-worp">
                <div class="txt">סוג מערכת שעות</div>
                <div class="selectDiv">
                    <select formControlName="ScheduleType">
                        <option value=""></option>
                        <option [ngValue]="scheduleType"
                            *ngFor="let scheduleType of schedulesService.ScheduleTypesList">
                            {{ scheduleType.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex justify-content-between input-worp" *ngIf="form.value.ScheduleType!=null&&form.value.ScheduleType.value=='2'">
                <div class="txt">תיאור התקופה</div>
                <div class="selectDiv">
                    <select formControlName="Period">
                        <option value=""></option>
                        <option [ngValue]="period" *ngFor="let period of schedulesService.PeriodDescriptionList">
                            {{ period.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex justify-content-between input-worp" *ngIf="form.value.ScheduleType==null||form.value.ScheduleType.value!='2'"></div>
        </div>
        <div class="d-flex justify-content-between contant-inputs" *ngIf="form.value.ScheduleType!=null&&form.value.ScheduleType.value=='2'">
            <div class="d-flex justify-content-between input-worp first-worp">
                <div class="txt">החל מתאריך
                </div>
                <mat-form-field [class]="' DatePickerDiv inputText mat-form-field'" appearance="fill">
                    <input matInput [min]="minDate" [matDatepicker]="picker" formControlName="FromDate" (dateChange)="changefromDate($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker">
                        <i matDatepickerToggleIcon class="datepicker-icon"></i>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="d-flex justify-content-between input-worp">
                <div class="txt">עד תאריך
                </div>
                <mat-form-field [class]="' DatePickerDiv inputText mat-form-field'" appearance="fill">
                    <input matInput [min]="minDate" [matDatepicker]="picker2" formControlName="ToDate">
                    <mat-datepicker-toggle matSuffix [for]="picker2">
                        <i matDatepickerToggleIcon class="datepicker-icon"></i>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="d-flex justify-content-between contant-inputs" *ngIf="isUpdate">
            <div class="d-flex justify-content-between input-worp first-worp">
                <div class="txt">סטטוס </div>
                <input class="inputText" type="text" formControlName="Status" disabled>
            </div>
            <div class="d-flex justify-content-between input-worp" *ngIf="form.value.StatusReason!=null">
                <div class="txt">סיבת הדחיה</div>
                <input class="inputText" type="text" formControlName="StatusReason" disabled>
            </div>
            <div class="d-flex justify-content-between input-worp" *ngIf="form.value.StatusReason==null">
            </div>
        </div>

    </div>
    <div class="d-flex orang-title align-items-center">
        <i class="scheduleHours-icon"></i>
        <div class="orang-text">שעות איסוף/פיזור</div>
        <div class="orang-line"></div>
    </div>
    <div>
        <div class="d-flex justify-content-between contant-inputs">
            <div class="d-flex justify-content-between input-worp first-worp">
                <div class="txt">שעת איסוף יום א</div>
                <div class="selectDiv">
                    <select formControlName="Sunday_e">
                        <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of schedulesService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex justify-content-between input-worp">
                <div class="txt">שעת פיזור יום א</div>
                <div class="selectDiv">
                    <select formControlName="Sunday_p">
                        <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of schedulesService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between contant-inputs">
            <div class="d-flex justify-content-between input-worp first-worp">
                <div class="txt">שעת איסוף יום ב</div>
                <div class="selectDiv">
                    <select formControlName="Monday_e">
                        <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of schedulesService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex justify-content-between input-worp">
                <div class="txt">שעת פיזור יום ב</div>
                <div class="selectDiv">
                    <select formControlName="Monday_p">
                        <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of schedulesService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between contant-inputs">
            <div class="d-flex justify-content-between input-worp first-worp">
                <div class="txt">שעת איסוף יום ג</div>
                <div class="selectDiv">
                    <select formControlName="Tuesday_e">
                        <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of schedulesService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex justify-content-between input-worp">
                <div class="txt">שעת פיזור יום ג</div>
                <div class="selectDiv">
                    <select formControlName="Tuesday_p">
                        <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of schedulesService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between contant-inputs">
            <div class="d-flex justify-content-between input-worp first-worp">
                <div class="txt">שעת איסוף יום ד</div>
                <div class="selectDiv">
                    <select formControlName="Wednesday_e">
                        <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of schedulesService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex justify-content-between input-worp">
                <div class="txt">שעת פיזור יום ד</div>
                <div class="selectDiv">
                    <select formControlName="Wednesday_p">
                        <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of schedulesService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between contant-inputs">
            <div class="d-flex justify-content-between input-worp first-worp">
                <div class="txt">שעת איסוף יום ה</div>
                <div class="selectDiv">
                    <select formControlName="Thursday_e">
                        <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of schedulesService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex justify-content-between input-worp">
                <div class="txt">שעת פיזור יום ה</div>
                <div class="selectDiv">
                    <select formControlName="Thursday_p">
                        <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of schedulesService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between contant-inputs">
            <div class="d-flex justify-content-between input-worp first-worp">
                <div class="txt">שעת איסוף יום ו</div>
                <div class="selectDiv">
                    <select formControlName="Friday_e">
                        <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of schedulesService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex justify-content-between input-worp">
                <div class="txt">שעת פיזור יום ו</div>
                <div class="selectDiv">
                    <select formControlName="Friday_p">
                        <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of schedulesService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between contant-inputs">
            <div class="d-flex justify-content-between input-worp first-worp">
                <div class="txt">שעת איסוף יום ש</div>
                <div class="selectDiv">
                    <select formControlName="Saturday_e">
                        <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of schedulesService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="d-flex justify-content-between input-worp">
                <div class="txt">שעת פיזור יום ש</div>
                <div class="selectDiv">
                    <select formControlName="Saturday_p">
                        <option value=""></option>
                        <option [ngValue]="hour" *ngFor="let hour of schedulesService.ScheduleHoursList">
                            {{ hour.viewValue }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between commentDiv">
            <div class="txt">הערות</div>
            <input formControlName="Remarks" class="inputText commentInput" type="text">
        </div>
        <div class="big-orang-line"></div>
        <div class="contant-buttons d-flex justify-content-end">

            <input formControlName="Id_e" [hidden]="true">
            <input formControlName="Id_p" [hidden]="true">

            <button class="cancel-save-button" (click)="close()">
                <span>סגור</span>
            </button>
            <button *ngIf="schedule?.Status!='אושר'" class="save-button" [ngClass]="{'disabled': !form.valid }" [disabled]="! form.valid" (click)="save()">
                <span>שמור</span>
            </button>
        </div>
    </div>
</div>