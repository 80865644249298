import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Message } from 'src/app/models/Message';
import { DataService } from 'src/app/services/data.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-message-display-dialog',
  templateUrl: './message-display-dialog.component.html',
  styleUrls: ['./message-display-dialog.component.scss']
})
export class MessageDisplayDialogComponent implements OnInit {

  message : Message = new Message();
  constructor(private dialogRef: MatDialogRef<MessageDisplayDialogComponent>,
    public datepipe: DatePipe,
    private dataService: DataService,
    private messageService : MessageService,
    @Inject(MAT_DIALOG_DATA) data: Message) { 
      this.message = data;
    }

  ngOnInit() {
  }

  DownloadFile(i: number){    

    //Convert file from 'base64' to 'blob'
    const byteCharacters = atob(this.message.AttachedFiles[i].Content);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: 'text/plain'});

    //Download file
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = this.message.AttachedFiles[i].FileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  
  Close(){
    this.dialogRef.close();
  }

}
