<div class="content">
    <app-component-title componentName="הודעות" IconName="NotificationsIcon"></app-component-title>    

    <div class=" list-group table-content" *ngIf="!dashboardDisplay">
        <div class="list-group-item table-header">
            <div class="col-3 col-item">סטטוס</div>
            <div class="col-title col-3 col-item">מזהה הודעה</div>
            <div class="col-3 col-item">תיאור</div>
            <div class="col-3 col-item">תאריך</div>
        </div>

        <div *ngIf="loadingData" class="loading">
            <loaders-css [loader]="dataService.loader" [scale]="2" [color]="dataService.color"></loaders-css>
        </div>

        <div *ngIf="noResults" class="no-results-msg">אין הודעות חדשות.</div>

        <div class="list-group-item table-record" *ngFor="let msg of messageList; let i = index" (click)="ShowMessage(msg.MessageID)">
            <div class="col-3 col-item " [ngClass]="{'status-read':msg.IsRead,'status-unread': !msg.IsRead}"></div>
            <div class="col-3 col-item">{{msg.Id}}</div>
            <div class="col-3 col-item">{{msg.Subject}}</div>
            <div class="col-3 col-item">{{datepipe.transform(msg.CreatedOn,'dd/MM/yyyy')}}</div>
        </div>

    </div>    

    <!-- עיצוב טבלה רספונסיבית למובייל  -->

    <div class=" list-group list-content" *ngIf="!dashboardDisplay">        

        <div *ngIf="loadingData" class="loading">
            <loaders-css [loader]="dataService.loader" [scale]="2" [color]="dataService.color"></loaders-css>
        </div>

        <div *ngIf="noResults" class="no-results-msg">אין הודעות חדשות.</div>

        <div class="list-group-item list-record" *ngFor="let msg of messageList; let i = index" (click)="ShowMessage(msg.MessageID)">
            <div class="id-and-subject col-8">
                <div class="">{{msg.Id}}</div>
                <div class="subject">{{msg.Subject}}</div>
            </div>
            <div class="date-and-status col-4">
                <div class="">{{datepipe.transform(msg.CreatedOn,'dd/MM/yyyy')}}</div>
                <div class="" [ngClass]="{'status-read':msg.IsRead,'status-unread': !msg.IsRead}"></div>                            
            </div>                        
        </div>
    </div>       

</div>
