<div class="content">
    <app-component-title [hidden]="true" (onClickButton)="showSearchSection()"></app-component-title>
    <div class="component-title">
        <app-component-title componentName="תלמידים" IconName="contactListIcon">
        </app-component-title>
    </div>

    <div class="component-content">

        <div class="search">
            <div class="search-txt">חיפוש תלמידים</div>

            <div class="search-content">
                <div class="search-fields">
                    <div class="search-warp">

                        <input type="text" [(ngModel)]="filters.searchFullName" (ngModelChange)="updateFilters()" placeholder="חפש לפי שם מלא">
                        <i class="search-icon"></i>
                    </div>
                    <div class="search-warp">
                        <i class="search-icon"></i>
                        <input type="text" [(ngModel)]="filters.searchTZ" (ngModelChange)="updateFilters()" placeholder="חפש לפי מספר זהות">
                    </div>
                    <div class="search-warp">
                        <i class="search-icon"></i>
                        <input type="text" [(ngModel)]="filters.searchClass" (ngModelChange)="updateFilters()" placeholder="חפש לפי כיתה">
                    </div>
                </div>
                <div class="search-btn">
                    <button *ngIf="true" (click)="updateFilters()">הצג</button>
                </div>
            </div>
        </div>

        <div *ngIf="dataService.userModel.IsEditStudentAllowed" class="actions-btns-content">
            <!-- <div class="actions-btns-content"> -->
            <div class="action-content ">
                <button (click)="DeactivateContact()" class="action-btn" [ngClass]="{'disabled' : selectedStudents.length == 0 }" [disabled]=" selectedStudents.length  == 0  ">גריעת תלמיד/ה</button>
                <div class="deactivate-contact-icon btn-icon">
                </div>
            </div>
            <div class="action-content ">
                <button class="action-btn" (click)="updateContactOpenModal()" [ngClass]="{'disabled' : selectedStudents.length != 1 }" [disabled]=" selectedStudents.length != 1 ">עדכן תלמיד/ה</button>

                <div class="update-contact-icon  btn-icon">
                </div>
            </div>
            <div class="action-content ">
                <button class="action-btn" (click)="createContactOpenModal()">הוסף תלמיד/ה</button>

                <div class="add-contact-icon  btn-icon">
                </div>
            </div>

        </div>
        <div *ngIf="!dataService.userModel.IsEditStudentAllowed" class="actions-btns-content">
            <div class="alert alert-danger notAllowedAlert">
                {{dataService.notAllowedTxt1}} {{tableName}} {{dataService.notAllowedTxt2}}
                <span style="unicode-bidi: isolate; direction: ltr;">{{dataService.userModel.BuisnesUnitPhone}}</span>

            </div>
        </div>
        <div [hidden]="!isFetching" class="saveLoading">מכין נתונים...
            <loaders-css [loader]="dataService.saveLoader" [scale]="1" [color]="dataService.color"></loaders-css>
        </div>
        <div [hidden]="!isSaving" class="saveLoading">שומר...
            <loaders-css [loader]="dataService.saveLoader" [scale]="1" [color]="dataService.color"></loaders-css>
        </div>
        <div class="table-content" *ngIf="studentService.ContactsList != null">

            <table class="scroll" *ngIf="studentService.ContactsList != null ">
                <thead>
                    <tr>

                        <th>
                            <div class="d-flex  flex-column th-div">
                                <span class="p-2 mt-auto">בחר</span>
                                <div class="p-2 mt-auto"></div>
                            </div>
                        </th>
                        <th>
                            <div class="d-flex align-items-start flex-column th-div">
                                <span class="p-2 mt-auto">מספר זהות</span>
                                <div class="filter-div p-2 mt-auto d-flex justify-content-center">
                                    <input [(ngModel)]="inp_id" class="filter p-2 mt-auto form-control form-control-sm" type="text" [id]="'ContactNumber'" (keyup)="inputChange($event, 'ContactNumber')">
                                    <div class="img-div"> <button class="filter-btn"><img
                                                src="../../../assets/filter-icons/filter-empty.svg"
                                                style="width: 10px; height: 100%;"></button></div>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="d-flex align-items-start flex-column th-div">
                                <span class="p-2 mt-auto">שם מלא</span>
                                <div class="filter-div p-2 mt-auto d-flex justify-content-center">
                                    <input [(ngModel)]="inp_fullname" class="p-2 mt-auto form-control form-control-sm" type="text" [id]="'FullName'" (keyup)="inputChange($event, 'FullName')">
                                    <div class="img-div"> <button class="filter-btn"><img
                                                src="../../../assets/filter-icons/filter-empty.svg"
                                                style="width: 10px; height: 100%;"></button></div>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="d-flex align-items-start flex-column th-div">
                                <span class="p-2 mt-auto">כיתה</span>
                                <div class="filter-div p-2 mt-auto d-flex justify-content-center">
                                    <input [(ngModel)]="inp_class" class="p-2 mt-auto form-control form-control-sm" type="text" [id]="'new_class1'" (keyup)="inputChange($event, 'new_class1')">
                                    <div class="img-div"> <button class="filter-btn"><img
                                                src="../../../assets/filter-icons/filter-empty.svg"
                                                style="width: 10px; height: 100%;"></button></div>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="d-flex align-items-start flex-column th-div">
                                <span class="p-2 mt-auto">זכאות להסעה</span>
                                <div class="filter-div p-2 mt-auto d-flex justify-content-center">
                                    <input [(ngModel)]="inp_zakaut" class="p-2 mt-auto form-control form-control-sm" type="text" [id]="'new_zakautleasaa'" (keyup)="inputChange($event, 'new_zakautleasaa')">
                                    <div class="img-div"> <button class="filter-btn"><img
                                                src="../../../assets/filter-icons/filter-empty.svg"
                                                style="width: 10px; height: 100%;"></button></div>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="d-flex align-items-start flex-column th-div">
                                <span class="p-2 mt-auto">מעוניין בהסעה</span>
                                <div class="filter-div p-2 mt-auto d-flex justify-content-center">
                                    <input [(ngModel)]="inp_meunyan" class="p-2 mt-auto form-control form-control-sm" type="text" [id]="'new_meunianbehasaha'" (keyup)="inputChange($event, 'new_meunianbehasaha')">
                                    <div class="img-div"> <button class="filter-btn"><img
                                                src="../../../assets/filter-icons/filter-empty.svg"
                                                style="width: 10px; height: 100%;"></button></div>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="d-flex align-items-start flex-column th-div">
                                <span class="p-2 mt-auto">נייד אב</span>
                                <div class="filter-div p-2 mt-auto d-flex justify-content-center">
                                    <input [(ngModel)]="inp_fatherphone" class="mt-auto p-2 form-control form-control-sm" type="text" [id]="'FatherPhone'" (keyup)="inputChange($event, 'FatherPhone')">
                                    <div class="img-div"> <button class="filter-btn"><img
                                                src="../../../assets/filter-icons/filter-empty.svg"
                                                style="width: 10px; height: 100%;"></button></div>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="d-flex align-items-start flex-column th-div">
                                <span class="p-2 mt-auto">נייד אם</span>
                                <div class="filter-div p-2 mt-auto d-flex justify-content-center">
                                    <input [(ngModel)]="inp_motherphone" class="p-2 mt-auto form-control form-control-sm" type="text" [id]="'MotherPhone'" (keyup)="inputChange($event, 'MotherPhone')">
                                    <div class="img-div"> <button class="filter-btn"><img
                                                src="../../../assets/filter-icons/filter-empty.svg"
                                                style="width: 10px; height: 100%;"></button></div>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="d-flex align-items-start flex-column th-div">
                                <span class="p-2 mt-auto">כתובת</span>
                                <div class="filter-div p-2 mt-auto d-flex justify-content-center">
                                    <input [(ngModel)]="inp_address" class="p-2 mt-auto form-control form-control-sm" type="text" [id]="'Address'" (keyup)="inputChange($event, 'Address')">
                                    <div class="img-div"> <button class="filter-btn"><img
                                                src="../../../assets/filter-icons/filter-empty.svg"
                                                style="width: 10px; height: 100%;"></button></div>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="d-flex align-items-start flex-column th-div">
                                <span class="p-2 mt-auto">עיר</span>
                                <div class="filter-div p-2 mt-auto d-flex justify-content-center">
                                    <input [(ngModel)]="inp_city" class="p-2 mt-auto form-control form-control-sm" type="text" [id]="'City'" (keyup)="inputChange($event, 'City')">
                                    <div class="img-div"> <button class="filter-btn"><img
                                                src="../../../assets/filter-icons/filter-empty.svg"
                                                style="width: 10px; height: 100%;"></button></div>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="d-flex align-items-start flex-column th-div">
                                <span class="p-2  mt-auto">סטטוס תלמיד</span>
                                <div class="filter-div p-2 mt-auto d-flex justify-content-center">
                                    <input [(ngModel)]="inp_status" class="p-2 mt-auto  form-control form-control-sm" type="text" [id]="'new_contactnewstatus'" (keyup)="inputChange($event, 'new_contactnewstatus')">
                                    <div class="img-div"> <button class="filter-btn"><img
                                                src="../../../assets/filter-icons/filter-empty.svg"
                                                style="width: 10px; height: 100%;"></button></div>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="d-flex align-items-start flex-column th-div">
                                <span class="p-2 mt-auto ">סוג חינוך</span>
                                <div class="filter-div p-2 mt-auto d-flex justify-content-center">
                                    <input [(ngModel)]="inp_chinuch" class="p-2 mt-auto form-control form-control-sm" type="text" [id]="'new_sughinuch'" (keyup)="inputChange($event, 'new_sughinuch')">
                                    <div class="img-div"> <button class="filter-btn"><img
                                                src="../../../assets/filter-icons/filter-empty.svg"
                                                style="width: 10px; height: 100%;"></button></div>
                                </div>
                            </div>
                        </th>
                        <th>
                            <div class="d-flex align-items-center flex-column th-div">
                                <span class="p-2 ">מערכת שעות לתלמיד</span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <div [hidden]="!isLoadStudentList" class="loading">
                    <loaders-css [loader]="dataService.loader" [scale]="2" [color]="dataService.color"></loaders-css>
                </div>
                <tbody *ngIf="!isLoadStudentList">
                    <tr [ngClass]="{ 'row-selected': s._checked == true,
                                 'even-index-row': i%2 != 0 && s._checked != true }" class=" tr-list" *ngFor="let s of pageOfItems; let i=index">
                        <td class="first-td">
                            <label class="container checkbox d-flex justify-content-center">
                                <input type="checkbox" [(ngModel)]="s._checked" (change)="onSelectStudent(s)">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                        <td>
                            <!-- מספר זהות -->
                            {{s.ContactNumber}}
                        </td>
                        <td>
                            <popover-content #myPopover placement="bottom-left" [animation]="true" [closeOnClickOutside]="true">
                                <b>{{s.CaseText}}</b>
                            </popover-content>

                            <img *ngIf="s.IsOpenedCaseExsist" class="IsCaseExsist " src="../../../assets/Events/OpenEvent.svg" [popover]="myPopover">

                            <img *ngIf="s.IsClosedCaseExsist" class="IsCaseExsist" src="../../../assets/Events/CompletedEvent.svg" [popover]="myPopover">
                            <!-- שם מלא -->
                            {{s.FullName}}
                        </td>
                        <td>
                            <!-- כיתה -->
                            {{s.new_class1}}
                        </td>

                        <td class="select-td">
                            <!-- זכאות להסעה -->
                            {{s.new_zakautleasaa }}
                        </td>
                        <td class=" select-td">
                            <!-- מעוניין בהסעה -->
                            {{s.new_meunianbehasaha}}

                        </td>
                        <td>
                            <!-- נייד אב -->
                            {{s.FatherPhone}}
                        </td>
                        <td>
                            <!-- נייד אם -->
                            {{s.MotherPhone}}
                        </td>
                        <td>
                            <!-- כתובת -->
                            {{s.Address}}
                        </td>
                        <td>
                            <!-- עיר -->
                            {{s.City}}
                        </td>
                        <td>
                            <!-- סטטוס תלמיד -->
                            {{s.new_contactnewstatus}}
                        </td>
                        <td>
                            <!-- סוג חינוך -->
                            {{s.new_sughinuch}}
                        </td>
                        <td class="last-td">
                            <img src="../../../assets/datepickerIcon.svg" class="scheduleStudent-icon" (click)="openSchedule(s)">
                        </td>
                        <td class="info">
                            <!-- מזהה תלמיד -->
                            {{s.new_contactid}}
                        </td>
                        <td class="info">
                            <!-- מזהה צרכי תלמיד -->
                            {{s.new_contactneedsid}}
                        </td>
                        <td class="info">
                            <!-- מזהה צרכי תלמיד -->
                            {{s.StudentScheduleId}}
                        </td>

                    </tr>
                </tbody>
            </table>
            <div class="tableFooter">
                <jw-pagination [items]="dataSource" (changePage)="onChangePage($event)"></jw-pagination>
                <div [hidden]="isLoadStudentList" class="sumStudents">סה"כ תלמידים במוסד: {{studentService.ContactsList.length}}
                </div>
            </div>
        </div>

        <!-- עיצוב טבלה מותאם למובייל -->
        <div class="datalist">
            <div [hidden]="!isLoadStudentList" class="loading">
                <loaders-css [loader]="dataService.loader" [scale]="2" [color]="dataService.color"></loaders-css>
            </div>
            <div class="accordion" id="accordionExample" *ngIf="!isLoadStudentList">
                <div class="" *ngFor="let s of dataSource; let i=index">
                    <div class="" id="{{'heading'+i}}" type="button" [attr.data-toggle]="'collapse'" [attr.data-target]="'#collapse'+i" aria-expanded="false" [attr.aria-controls]="'collapse'+i">

                        <li [ngClass]="{'selected-item':s._checked && selectedStudentIndex==i ,'animated':selectedStudentIndex==i,'animated-reverse':false}" class="list-group-item " (click)="onStudentSelectList(i,s)">
                            <div class="col-4">{{s.ContactNumber}}</div>
                            <div class="col-4">{{s.FullName}}</div>
                            <div class="col-1 list-item-side">...</div>
                            <div class="arrow-icon"></div>
                        </li>
                    </div>

                    <div id="{{'collapse'+i}}" class="collapse " [attr.aria-labelledby]="'heading'+i" data-parent="#accordionExample">
                        <div class="">
                            <div class="expended-data">
                                <div class="id-number expended-item">
                                    <span class="lable col-6">מספר זהות</span>
                                    <span class="col-4">{{s.ContactNumber}}</span>
                                </div>
                                <div class="full-name expended-item">
                                    <span class="lable col-6">שם מלא</span>
                                    <span class="col-4">{{s.FullName}}</span>
                                </div>
                                <div class="class expended-item">
                                    <span class="lable col-6">כיתה</span>
                                    <span>{{s.new_class1}}</span>
                                </div>
                                <div class="zakaut expended-item">
                                    <span class="lable col-6">זכאות להסעה</span>
                                    <span class="col-4">{{s.new_zakautleasaa}}</span>
                                </div>
                                <div class="intrested expended-item">
                                    <span class="lable col-6">מעוניין בהסעה</span>
                                    <span class="col-4">{{s.new_meunianbehasaha}}</span>
                                </div>
                                <div class="father-phone expended-item">
                                    <span class="lable col-6">נייד אב</span>
                                    <span class="col-4">{{s.FatherPhone}}</span>
                                </div>
                                <div class="mother-phone expended-item">
                                    <span class="lable col-6">נייד אם</span>
                                    <span class="col-4">{{s.MotherPhone}}</span>
                                </div>
                                <div class="address expended-item">
                                    <span class="lable col-6">כתובת</span>
                                    <span class="col-4">{{s.Address}}</span>
                                </div>
                                <div class="status expended-item">
                                    <span class="lable col-6">סטטוס תלמיד</span>
                                    <span class="col-4">{{s.new_contactnewstatus}}</span>
                                </div>
                                <div class="education-type expended-item">
                                    <span class="lable col-6">סוג חינוך</span>
                                    <span class="col-4">{{s.new_sughinuch}}</span>
                                </div>
                                <div class="schedule expended-item">
                                    <span class="lable col-6">מערכת שעות לתלמיד</span>
                                    <span class="col-4">{{s.ContactNumber}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>