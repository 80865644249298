import { Component, ElementRef, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { StudentRequestModel, DictionaryValue as StudentRequestValue } from 'src/app/models/StudentRequestModel';
import { Router } from '@angular/router';
import { InputParams } from 'src/app/models/InputParams';
import { SearchContactPipe } from 'src/app/_pipe/search-contact.pipe'
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { CreateContactDialogComponent } from '../update-contact-dialog/create-contact-dialog.component';
import { ContactRow } from 'src/app/models/ContactRow';
import { Contact } from 'src/app/models/Contact';
import { StudentService } from 'src/app/services/student.service';
import { ScheduleContactDialogComponent } from '../schedule-contact-dialog/schedule-contact-dialog.component';
import { StudentScheduleService } from 'src/app/services/student-schedule.service';
import { SchedulesService } from 'src/app/services/schedules.service';
import { StudentSchedule } from 'src/app/models/StudentSchedule';
import { LoadersCSS } from 'ngx-loaders-css';
import { DectivateStudentModalComponent } from 'src/app/dectivate-student-modal/dectivate-student-modal.component';


@Component({
  selector: 'app-contacts-list',
  host: {
    class: 'app-contacts-list'
  },
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss']
})
export class ContactsListComponent implements OnInit {
  public isFetching: boolean = false;
  public isSaving: boolean = false;


  requestModel: StudentRequestModel;
  c: { [key: string]: string; } = {};
  contact: Contact;
  public selectedStudents: Array<ContactRow> = new Array<ContactRow>();


  pageOfItems: Array<any>;
  filteredContactList: Array<ContactRow> = new Array<ContactRow>();


  public filters: any = {
    searchFullName: "",
    searchTZ: "",
    searchClass: ""
  };
  public selectedValue: any = "";
  componentNameFromParent: string = "";
  public isLoadStudentList: boolean;
  public tableName = "תלמידים"
  public t: string;
  initContact: Contact;
  dataSource$: any;
  filteredData: any;
  filterKeyValues: any[] = [];
  filter$: any;
  filterSubject$: any;
  dataSource: any;
  inp: string;
  inp_id: string;
  inp_fullname: string;
  inp_class: string;
  inp_zakaut: string;
  inp_meunyan: string;
  inp_fatherphone: string;
  inp_motherphone: string;
  inp_address: string;
  inp_city: string;
  inp_status: string;
  inp_chinuch: string;
  selectedStudentIndex: number;
  showSearch: boolean;


  constructor(public dataService: DataService,
    public studentService: StudentService,
    private studentScheduleService: StudentScheduleService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.showSearch = false;
    if (!this.dataService.isLoggedIn) {
      this.dataService.TimeOut();
    }
    this.dataService.isRefresh$.subscribe(isRefresh => {
      if (isRefresh) {
        this.refesh();
      }
    })
    //BuildTableColumns();
    if (this.studentService.ContactsList.length == 0) {
      this.loadData();
      this.studentService.GetModalOptions().then((res: any) => {
        if (!this.dataService.IsError(res)) {
          var data = JSON.parse(res)
          this.studentService.SugHasaaList = data.SugHasaaList;
          this.studentService.DeffectsList = data.DeffectsList;
          this.studentService.GenderCodesList = data.GenderCodesList;
          this.studentService.TzaharonList = data.TzaharonList;
          this.studentService.SugChinuchList = data.SugChinuchList;
          this.studentService.ClassesList = data.ClassesList;
          this.studentService.CitiesList = data.CitiesList;
        }

      }).catch(error => { debugger });
    }
    else{
      this.dataSource = this.studentService.ContactsList;
    }
    if (this.studentScheduleService.ScheduleHoursList.length == 0) {
      this.studentScheduleService.GetModalOptions().then((res: string) => {
        if (!this.dataService.IsError(res)) {
          var data = JSON.parse(res);
          this.studentScheduleService.ScheduleHoursList = data.ScheduleHoursList;
        }

      })
    }
    //GetAllInstitutuinsStudents
    this.dataService.refreshPageSubject.asObservable().subscribe(() => this.loadData());

  }
  onStudentSelectList(index: number, student: ContactRow) {
    if (student._checked) {
      student._checked = false;
      this.selectedStudentIndex = -1;
      this.ClearCheckedStudents();
    }
    else {
      this.ClearCheckedStudents();
      student._checked = true;
      this.selectedStudentIndex = index;
      this.selectedStudents.push(student);
    }
  }

  onSelectStudent(student: ContactRow) {
    if (student._checked) {
      this.selectedStudents.push(student);
    }
    else {
      let i: number;
      i = this.GetStudentSelectedIndex(student);
      this.selectedStudents.splice(i, 1);
    }
  }
  GetStudentSelectedIndex(student: ContactRow): number {
    return this.selectedStudents.findIndex(s => s.ContactNumber == student.ContactNumber);
  }

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }

  loadData() {
    this.isLoadStudentList = true;
    this.ClearCheckedStudents();
    this.studentService.GetAllInstitutuinsStudents().then((res: any) => {
      this.t = "test";
      if (!this.dataService.IsError(res)) {
        var Data = JSON.parse(res);
        this.studentService.MeunianBehasahaList = Data.Meunianbehasaha;
        this.studentService.ContactStatusList = Data.ContactsStatus;
        this.studentService.ZakautleasaaList = Data.ZakautLehasaa;
        this.studentService.ContactsList = Data.Students;
        this.filteredContactList = Data.Students;
        this.dataService.loading = false;
        this.dataSource = this.filteredContactList;
        //this.pageOfItems = this.filteredContactList;
        this.cleanFilters();
      }
      this.isLoadStudentList = false;

    }).catch(error => { debugger });
  }


  refesh() {
    this.loadData();
  }
  updateContact(contact, fieldToUpdate) {
    this.studentService.request.InputParamsDictionary["contatGuid"] = contact.new_contactid;
    this.studentService.request.InputParamsDictionary["new_contactneedsid"] = contact.new_contactneedsid;

    if (fieldToUpdate == "new_zakautleasaa") {
      this.studentService.request.InputParamsDictionary["new_zakautleasaa"] = contact.new_zakautleasaa;

      // this.dataService.ZakautleasaaList.filter(function (item) {
      //   return item.value == contact.new_zakautleasaa
      // })[0].viewValue;
    }
    else {
      this.studentService.request.InputParamsDictionary["new_meunianbehasaha"] = contact.new_meunianbehasaha
      // this.dataService.MeunianBehasahaList.filter(function (item) {
      //   return item.value == contact.new_meunianbehasaha
      // })[0].viewValue;

    }
    this.isSaving = true;
    this.studentService.UpdateHasaaToContact().then((res: any) => {
      if (!this.dataService.IsError(res)) {
        this.studentService.request.InputParamsDictionary = {}
      }
      this.isSaving = false;

    }).catch(error => {

    });
  }

  DeactivateContact() {
    this.OpenDeactivateStudentModal();
  }
  OpenDeactivateStudentModal() {
    const dialogRef = this.dialog.open(DectivateStudentModalComponent, {
      panelClass: 'full-width-dialog',
      hasBackdrop: true,
      autoFocus: true,
      disableClose: true,
      //data: { studentData: studentData }
    });

    dialogRef.afterClosed().subscribe((reason: string) => {
      if (reason != null) {
        this.studentService.request.additinal = reason;
        var c = {};
        this.studentService.request.MultipleInputParams = new Array();
        this.selectedStudents.forEach((contact, i) => {
          c["contatGuid"] = contact.new_contactid;
          c["new_contactneedsid"] = contact.new_contactneedsid;
          this.studentService.request.MultipleInputParams.push(c);
          c = {};
          var j = this.studentService.ContactsList.map(function (s) { return s.new_contactid })
            .indexOf(this.selectedStudents[i].new_contactid)

          this.studentService.ContactsList.splice(j, 1);
        });
        this.studentService.ContactsList = this.studentService.ContactsList
        this.isSaving = true;

        this.ClearCheckedStudents()

        this.studentService.DeactivateContacts().then((res: string) => {
          if (!this.dataService.IsError(res)) {
            this.loadData()

            alert("גריעת התלמידים בוצעה בהצלחה")
          }
          this.isSaving = false;

        }).catch(error => {

        })
      }

    });
  }

  updateFilters(): void {
    debugger;
    //this.filters = Object.assign({}, this.filters);
    //new:
    if (!this.filters || this.filters.searchFullName == "" && this.filters.searchClass == "" && this.filters.searchTZ == "") {
      this.filteredContactList = this.studentService.ContactsList;
    }
    else {
      this.filteredContactList = this.studentService.ContactsList.filter(student => {
        return (student.FullName.indexOf(this.filters.searchFullName) >= 0
          && student.new_class1.indexOf(this.filters.searchClass) >= 0
          && student.ContactNumber.indexOf(this.filters.searchTZ) >= 0);
      });
    }
    this.dataSource = this.filteredContactList;
    //this.pageOfItems = this.filteredContactList;
    debugger;
  }
  openSchedule(student: ContactRow): void {
    let studentSchedule: StudentSchedule
    if (student.StudentScheduleId != null) {
      this.isFetching = true;

      this.studentScheduleService.request.studentScheduleId = student.StudentScheduleId;
      this.studentScheduleService.GetStudentSchedule().then((res: string) => {
        this.isFetching = false;

        if (!this.dataService.IsError(res)) {

          var data = JSON.parse(res);
          studentSchedule = data;

          this.OpenScheduleModal(
            {
              student,
              studentSchedule
            }
          );
        }
      }).catch(error => {
        debugger
      })
    }
    else {
      studentSchedule = new StudentSchedule();
      this.OpenScheduleModal({ student, studentSchedule });

    }



  }
  OpenScheduleModal(studentData: any) {
    if (this.dialog.openDialogs.length > 0) {
      return;
    }
    const dialogRef = this.dialog.open(ScheduleContactDialogComponent, {
      panelClass: 'full-width-dialog',
      hasBackdrop: false,
      autoFocus: true,
      disableClose: true,
      data: { studentData: studentData }
    });

    dialogRef.afterClosed().subscribe((form: StudentSchedule) => {
      this.ClearCheckedStudents()

      if (form != undefined) {
        this.studentScheduleService.request.studentSchedule = form;
        this.isSaving = true;

        this.studentScheduleService.SaveStudentSchedule().then((res: string) => {
          this.isSaving = false;

          if (!this.dataService.IsError(res)) {

            if (res == "success") {
              alert("מערכת שעות לתלמיד עודכנה בהצלחה")
              this.loadData()
            }
            else {
              alert("אירעה שגיאה")
            }
          }
        }).catch(error => {
          debugger
        })
      }

    });
  }
  createContactOpenModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.minWidth = '70vw';
    dialogConfig.data = {};

    const dialogRef = this.dialog.open(CreateContactDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        this.ClearCheckedStudents()

        if (data != null) {
          this.setRequestParamsFromModal(data.controls, false);
          this.isSaving = true;

          this.studentService.createContact().then((res: any) => {
            this.isSaving = false
              ;

            if (!this.dataService.IsError(res)) {
              switch (res) {
                case "success":
                  this.dataService.SavedSuccessfuly();
                  this.loadData()
                  break;
                case "StudentExist-success":
                  alert("התלמיד משויך למוסד אחר, פניה נשלחה למערך ההסעות ותטופל בהקדם.")
                  break;
                default:
                  break;
              }
              this.dataService.loading = false;
            }
          }).catch(error => {

          });
          console.log("Dialog output:", data);
        }

      }
    );
  }
  updateContactOpenModal() {
    this.isFetching = true

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.minWidth = 600;

    this.studentService.request.InputParamsDictionary["new_contactnumber"] =
      this.selectedStudents[0].new_contactid;
    this.studentService.request.InputParamsDictionary["new_contactneedsid"] =
      this.selectedStudents[0].new_contactneedsid;
    //new_contactneedsid
    this.studentService.GetStudentDetailes().then((res: string) => {
      this.isFetching = false

      if (!this.dataService.IsError(res)) {

        let contactToUpdate: Contact = JSON.parse(res)
        contactToUpdate.new_contactid = this.selectedStudents[0].new_contactid;
        contactToUpdate.new_contactneedsid = this.selectedStudents[0].new_contactneedsid;
        this.initContact = contactToUpdate;

        dialogConfig.data = contactToUpdate;
        this.dataService.loading = false;

        const dialogRef = this.dialog.open(CreateContactDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
          data => {
            this.ClearCheckedStudents()
            if (data != null) {
              this.setRequestParamsFromModal(data.controls, true);
              this.isSaving = true;
              this.studentService.createContact().then((res: any) => {
                this.isSaving = false;
                if (!this.dataService.IsError(res)) {

                  this.dataService.loading = false;
                  this.dataService.SavedSuccessfuly();
                  this.loadData();
                }
              }).catch(error => {
              });
            }


          }
        );

        // }
      }
    }).catch(error => {

    });
  }

  ClearCheckedStudents() {
    this.selectedStudents = [];
    for (let i = 0; i < this.studentService.ContactsList.length; i++) {
      if (this.studentService.ContactsList[i]._checked) {
        this.studentService.ContactsList[i]._checked = false;
      }
    }
  }

  setRequestParamsFromModal(data: any, isUpdate: boolean) {
    this.studentService.request.InputParamsDictionary["new_contactnumber"] = this.ConvertToDictionaryValueJson(data.new_contactnumber.value, data.new_contactnumber.dirty, "string");
    this.studentService.request.InputParamsDictionary["BirthDate"] = this.ConvertToDictionaryValueJson(JSON.stringify(data.BirthDate.value), data.BirthDate.dirty, "string");
    this.studentService.request.InputParamsDictionary["FirstName"] = this.ConvertToDictionaryValueJson(data.FirstName.value, data.FirstName.dirty, "string");
    this.studentService.request.InputParamsDictionary["GenderCode"] = this.ConvertToDictionaryValueJson(this.getOtionValue(data.GenderCode.value), data.GenderCode.dirty, "optionSet");
    this.studentService.request.InputParamsDictionary["LastName"] = this.ConvertToDictionaryValueJson(data.LastName.value, data.LastName.dirty, "string");
    this.studentService.request.InputParamsDictionary["MobilePhone"] = this.ConvertToDictionaryValueJson(data.MobilePhone.value, data.MobilePhone.dirty, "string"), "string";
    this.studentService.request.InputParamsDictionary["Telephone1"] = this.ConvertToDictionaryValueJson(data.Telephone1.value, data.Telephone1.dirty, "string");
    this.studentService.request.InputParamsDictionary["new_cityid1"] = this.ConvertToDictionaryValueJson(this.getOtionValue(data.new_cityid1.value), data.new_cityid1.dirty, "optionSet");
    this.studentService.request.InputParamsDictionary["new_address1_line1"] = this.ConvertToDictionaryValueJson(data.new_address1_line1.value, data.new_address1_line1.dirty, "string");
    this.studentService.request.InputParamsDictionary["new_address1_line2"] = this.ConvertToDictionaryValueJson(data.new_address1_line2.value, data.new_address1_line2.dirty, "string");
    this.studentService.request.InputParamsDictionary["new_address1_postalcode"] = this.ConvertToDictionaryValueJson(data.new_address1_postalcode.value, data.new_address1_postalcode.dirty, "string");
    this.studentService.request.InputParamsDictionary["new_address1_comment"] = this.ConvertToDictionaryValueJson(data.new_address1_comment.value, data.new_address1_comment.dirty, "string");
    this.studentService.request.InputParamsDictionary["new_cityid2"] = this.ConvertToDictionaryValueJson(this.getOtionValue(data.new_cityid2.value), data.new_cityid2.dirty, "optionSet");
    this.studentService.request.InputParamsDictionary["new_address2_line1"] = this.ConvertToDictionaryValueJson(data.new_address2_line1.value, data.new_address2_line1.dirty, "string");
    this.studentService.request.InputParamsDictionary["new_address2_line2"] = this.ConvertToDictionaryValueJson(data.new_address2_line2.value, data.new_address2_line2.dirty, "string");
    this.studentService.request.InputParamsDictionary["new_address2_postalcode"] = this.ConvertToDictionaryValueJson(data.new_address2_postalcode.value, data.new_address2_postalcode.dirty, "string");
    this.studentService.request.InputParamsDictionary["new_class1"] = this.ConvertToDictionaryValueJson(this.getOtionValue(data.new_class1.value), data.new_class1.dirty, "optionSet");
    // this.studentService.request.InputParamsDictionary["new_defectid"] = this.ConvertToDictionaryValueJson(this.getOtionValue(data.new_defectid.value), data.new_defectid.dirty, "optionSet");
    this.studentService.request.InputParamsDictionary["new_sugasaa"] = this.ConvertToDictionaryValueJson(this.getOtionValue(data.new_sugasaa.value), data.new_sugasaa.dirty, "optionSet");
    this.studentService.request.InputParamsDictionary["new_meunianbehasaha"] = this.ConvertToDictionaryValueJson(this.getOtionValue(data.new_meunianbehasaha.value), data.new_meunianbehasaha.dirty, "optionSet");
    this.studentService.request.InputParamsDictionary["new_no_want_ride_from"] = this.ConvertToDictionaryValueJson(JSON.stringify(data.new_no_want_ride_from.value), data.new_no_want_ride_from.dirty, "string");
    this.studentService.request.InputParamsDictionary["new_no_want_ride_due"] = this.ConvertToDictionaryValueJson(JSON.stringify(data.new_no_want_ride_due.value), data.new_no_want_ride_due.dirty, "string");
    this.studentService.request.InputParamsDictionary["new_tzaharon"] = this.ConvertToDictionaryValueJson(this.getOtionValue(data.new_tzaharon.value), data.new_tzaharon.dirty, "optionSet");
    this.studentService.request.InputParamsDictionary["new_contactneedsid"] = this.ConvertToDictionaryValueJson(data.new_contactneedsid.value, data.new_contactneedsid.dirty, "lookup");
    this.studentService.request.InputParamsDictionary["new_contactid"] = this.ConvertToDictionaryValueJson(data.new_contactid.value, data.new_contactid.dirty, "lookup");
    this.studentService.request.InputParamsDictionary["ownerid"] = this.ConvertToDictionaryValueJson(data.new_cityid1.value.ownerId, data.new_cityid1.dirty, "string");
    this.studentService.request.InputParamsDictionary["new_sughinuch"] = this.ConvertToDictionaryValueJson(this.getOtionValue(data.new_sughinuch.value), data.new_sughinuch.dirty, "optionSet");
  }

  ConvertToDictionaryValueJson(value: string, dirty: boolean, type: string): string {
    let _value: StudentRequestValue = {
      value: value, isChange: dirty, _type: type
    };
    return JSON.stringify(_value);
  }

  getOtionValue(option: any/*, isUpdate: boolean*/) {
    // return option != null ?
    //   (isUpdate ?
    //     option.viewValue : option.value) :
    //   null;
    //return option != null ? option.value : null;

    return option != null ? JSON.stringify({ value: option.value, viewValue: option.viewValue }) : null;
  }

  inputChange(event: any, colName: any) {
    let index = this.filterKeyValues.findIndex(f => f.key == colName);
    if (index == -1) {
      this.filterKeyValues.push({ key: colName, value: event.target.value });
    }
    else {
      this.filterKeyValues[index].value = event.target.value;
    }
    this.dataSource = this.filterData(this.filteredContactList, this.filterKeyValues);
  }
  filterData(array, filters) {
    return array.filter(item => {
      return filters.every(filter => {
        return String(item[filter.key]).toLowerCase().includes((filter.value).toLowerCase());
      });
    });
  }
  cleanFilters() {
    this.inp_address = '';
    this.inp_city = '';
    this.inp_chinuch = '';
    this.inp_class = '';
    this.inp_fatherphone = '';
    this.inp_fullname = '';
    this.inp_id = '';
    this.inp_motherphone = '';
    this.inp_meunyan = '';
    this.inp_status = '';
    this.inp_zakaut = '';
    this.filterKeyValues = [];
  }

  showSearchSection() {

    this.showSearch = true;
    console.log(this.showSearch);
  }

}
