<div class="logo-mobile"></div>
<div class="form-container">
    <div class="form-parent">
        <div class="login-form">
            <div class="title1">
                <div class="round-logo contetnt1"></div>
                <div class="contetnt2">
                    <div class="title text1">פורטל מוסדות לימוד</div>
                    <div class="title ">עבור הסעות תלמידים</div>
                </div>
            </div>
            <div class="line"></div>
            <div class="profil">
                <div class="profil-logo"></div>
                <div class="title ">איזור אישי</div>
            </div>

            <div class="form" [hidden]="dataLogin.step == '2'">
                <app-login-step1></app-login-step1>
            </div>
            <div class="form" [hidden]="dataLogin.step == '1'">
                <app-login-step2></app-login-step2>
            </div>     
            <div class="support_phone">מוקד תמיכה טכני :0779998820</div>       
        </div>
         
    </div>

</div>

<div class="hand-logo"></div>


