<div class="content">
    <app-component-title componentName="פניות" IconName="InquiriesIcon"></app-component-title>

    <div class="createInquiry">
        <div class="create-img"></div>
        <button class="create-title" (click)="CreateInquiry()">צור פניה חדשה</button>
    </div>
    <div [hidden]="!isLoadingDataLists" class="saveLoading">מכין נתונים...
        <loaders-css [loader]="dataService.saveLoader" [scale]="1" [color]="dataService.color"></loaders-css>
    </div>
    <div class="list-group data-table">
        <div class="list-group-item list-header">
            <div class="col-title "></div>     
            <div class="col-title ">מספר פניה</div>                   
            <div class="col-title ">סטטוס</div>
            <div class="col-title ">סוג פניה</div>
            <div class="col-title ">שם התלמיד</div>
            <div class="col-title ">מלווה</div>
            <div class="col-title ">תאריך בקשה</div>
            <div class="col-title ">נושא</div>                                          
        </div>

        <div *ngIf="isLoadingData" class="loading">
            <loaders-css [loader]="dataService.loader" [scale]="2" [color]="dataService.color"></loaders-css>
        </div>
        
        
        

    <div *ngIf="dataSource.length > 0 ">
      
    
            <div  class="list-group-item list-content" *ngFor="let record of dataSource; let index">                            
                <div class="list-item-col status-col" [ngClass]="{'status-open':record.Status.value=='0', 'status-done':record.Status.value=='1'}"></div>                                    
                <div class="list-item-col ">{{record.Id}}</div>  
                <div class="list-item-col ">{{record.Status.viewValue}}</div>                                        
                <div class="list-item-col ">{{record.Type.Name}}</div>
                <div class="list-item-col ">{{record.Student.Name}}</div>
                <div class="list-item-col ">{{record.Escort}}</div>
                <div class="list-item-col ">{{datepipe.transform(record.CreatedOn,'dd.MM.yyyy')}}</div>
                <div class="list-item-col ">{{record.Subject.Name}}</div>                           
            </div>
        </div>
    </div>        
</div>

<div class="noResults" *ngIf="noResults">רשימת הפניות שלך ריקה.</div>

<!-- עיצוב טבלה רספונסיבית למובייל  -->
<div class="data-list">
    <div *ngIf="isLoadingData" class="loading">
        <loaders-css [loader]="dataService.loader" [scale]="2" [color]="dataService.color"></loaders-css>
    </div>
    <div class="accordion" id="accordionExample" >
        <div class="" *ngFor="let record of dataSource; let i=index">
            <div class="" id="{{'heading'+i}}" type="button" [attr.data-toggle]="'collapse'"
                [attr.data-target]="'#collapse'+i" aria-expanded="false" [attr.aria-controls]="'collapse'+i">
                
                <li class="list-group-item data-list-item" (click)="OnSelectItem(i)" [ngClass]="{'animated': selectedItemIndex == i,'selected-item':selectedItemIndex==i}">                   
                    <div class="col-1" [ngClass]="{'status-open':record.Status.value=='0', 'status-done':record.Status.value=='1'}"></div>
                    <div class="col-3 p-0">{{record.Id}}</div>                    
                    <div class="col-4 p-0">{{record.Status.viewValue}}</div>
                    <div class="col-1 list-item-side">...</div>
                    <div class="arrow-icon "></div>
                </li>
            </div>

            <div id="{{'collapse'+i}}" class="collapse " [attr.aria-labelledby]="'heading'+i"
                data-parent="#accordionExample">
                <div class="">
                    <div class="expended-data">
                        <div class="id-number expended-item">
                            <span class="lable col-5">שם התלמיד</span>
                            <span class="col-7">{{record.Student.Name}}</span>
                        </div>
                        <div class="full-name expended-item">
                            <span class="lable col-5">מלווה</span>
                            <span class="col-7">{{record.Escort}}</span>
                        </div>
                        <div class="class expended-item">
                            <span class="lable col-5">תאריך בקשה</span>
                            <span class="col-7">{{datepipe.transform(record.CreatedOn,'dd.MM.yyyy')}}</span>
                        </div>
                        <div class="zakaut expended-item">
                            <span class="lable col-5">נושא</span>
                            <span class="col-7">{{record.Subject.Name}}</span>
                        </div>                                                                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>