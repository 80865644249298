import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-institution-schedule',
  templateUrl: './institution-schedule.component.html',
  styleUrls: ['./institution-schedule.component.scss']
})
export class InstitutionScheduleComponent implements OnInit {

  days: Array<string> = ["א'", "ב'", "ג'", "ד'", "ה'", "ו'"];
  afterSaving: boolean;
  preserving: boolean;
  constructor() { }

  ngOnInit() {
  }

  save() { }

  close() { }

}
