import { Component, OnDestroy, OnInit ,HostListener} from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { OptionSet } from 'src/app/models/OptionSet';
import { UserModel } from 'src/app/models/User';
import { element } from 'protractor';

@Component({
  selector: 'app-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss']
})
export class BasicComponent implements OnInit, OnDestroy {
  public screenWidth: any;
  public toggelSideBar: boolean;
  public showFiller = false;
  constructor(public dataService: DataService,
    private cookieService: CookieService,
    private router: Router) { }

  ngOnInit() {
    this.loadData();
    this.toggelSideBar = true;

  }
  ngOnDestroy() {
    this.dataService.refreshPageSubject.unsubscribe();
  }
  ChangeYear(event) {
    this.dataService.userModel.Year.viewValue = event.target.selectedOptions[0].innerText;
    this.cookieService.set("Year", JSON.stringify(this.dataService.userModel.Year));
    this.dataService.refreshPageSubject.next();
  }
  ChangeInstitution() {    
    let account = this.dataService.institutions.find(i => i.Id == this.dataService.selectedAccount)
    this.dataService.userModel.AcountFullName = this.dataService.selectedAccount.AcountFullName;
    this.cookieService.set("AcountFullName", this.dataService.selectedAccount.AcountFullName);
    this.dataService.userModel.InstitutionName = this.dataService.selectedAccount.InstitutionName;
    this.cookieService.set("InstitutionName", this.dataService.selectedAccount.InstitutionName);
    this.dataService.userModel.InstitutionNumber = this.dataService.selectedAccount.InstitutionNumber;
    this.cookieService.set("InstitutionNumber", this.dataService.selectedAccount.InstitutionNumber);
    this.dataService.userModel.IsEditStudentAllowed = this.dataService.selectedAccount.IsEditStudentAllowed;
    this.cookieService.set("IsEditStudentAllowed", String(this.dataService.selectedAccount.IsEditStudentAllowed));
    this.dataService.userModel.IsEditScheduleAllowed = this.dataService.selectedAccount.IsEditScheduleAllowed;
    this.cookieService.set("IsEditScheduleAllowed", String(this.dataService.selectedAccount.IsEditScheduleAllowed));
    this.dataService.refreshPageSubject.next();
  }


  loadData() {

    if (document.cookie.indexOf('Token') == -1
      || document.cookie.indexOf('Token') == undefined) {

      this.router.navigateByUrl('/');

      this.dataService.isLoggedIn = false
    }

    else {     
      this.dataService.userModel.Token = this.cookieService.get('Token');
      this.dataService.userModel.AcountFullName = this.cookieService.get('AcountFullName');
      this.dataService.userModel.ContactMail = this.cookieService.get('ContactMail');
      this.dataService.userModel.ContactPhone= this.cookieService.get('ContactPhone');
      this.dataService.userModel.InstitutionName = this.cookieService.get('InstitutionName');
      this.dataService.userModel.InstitutionNumber = this.cookieService.get('InstitutionNumber');
      this.dataService.userModel.BuisnesUnitPhone = this.cookieService.get('BuisnesUnitPhone');
      this.dataService.userModel.Year = JSON.parse(this.cookieService.get('Year'));
      this.dataService.years = JSON.parse(this.cookieService.get('Years'));
      this.dataService.institutions = JSON.parse(this.cookieService.get('Institutions'));
      this.dataService.userModel.IsEditScheduleAllowed =
        (this.cookieService.get('IsEditScheduleAllowed') == "true");

      this.dataService.userModel.IsEditStudentAllowed =
        (this.cookieService.get('IsEditStudentAllowed') == "true");
      this.dataService.isLoggedIn = true;
      this.dataService.SetActivity();
    }

    if (!!(this.dataService.institutions)) {
      this.dataService.selectedAccount = this.dataService.institutions.find(i => i.InstitutionNumber == this.dataService.userModel.InstitutionNumber);
    }



  }


  HideSideBar() {
    this.toggelSideBar = false;
  }

  showSideBar() {
    this.toggelSideBar = true;
  }

  toggle()
  {
    this.screenWidth=window.innerWidth;
    if(window.innerWidth>600)
    this.toggelSideBar=true;
    else if(this.toggelSideBar==true)
    this.toggelSideBar=false;
    else
    this.toggelSideBar=true;
    
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    if(window.innerWidth>600)
    this.toggelSideBar=true;

    else
    this.toggelSideBar=false;

  }
}
