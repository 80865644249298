<div class="content-basic">

    <div class="nav-bar-content col-1" *ngIf="dataService.isLoggedIn">
        <div class="side-bar" *ngIf="dataService.isLoggedIn && toggelSideBar==true">
            <!-- <div class="side-bar-header">
                 {{'שלום,'+dataService.userModel.AcountFullName}}
                   </div> -->
            <div class="side-bar-inside" routerLinkActive="active">


                <div class="year-institution-container-mobile">
                    <div class="side-part">
                        <div class="DetailsIcon Icon"></div>
                        <div class="side-part-block">


                            <div *ngIf="dataService.isLoggedIn" id="current-institution">
                                <span>מוסד:</span>
                                <select [(ngModel)]="dataService.selectedAccount" (change)="ChangeInstitution()">
                                    <option *ngFor="let ins of dataService.institutions" [ngValue]="ins">
                                        {{ins.InstitutionNumber}} , {{ins.InstitutionName}}
                                    </option>
                                </select>
                            </div>

                            <div *ngIf="dataService.isLoggedIn" id="current-year">

                                <span>שנת לימוד:</span>
                                <select *ngIf="dataService.isLoggedIn" [(ngModel)]="dataService.userModel.Year.value" (change)="ChangeYear($event)">
                                    <option *ngFor="let year of dataService.years" [ngValue]="year.value">
                                        {{year.viewValue}}
                                    </option>
                                </select>
                            </div>



                        </div>
                    </div>
                </div>
                <mat-divider></mat-divider>


<!-- הערה בייצור -->
                <!-- <a routerLink="home-page" routerLinkActive="side-part-selected" (click)="toggle()">
                    <div class="side-part">
                        <div class="HomePageIcon Icon"></div>
                        <span matTooltip="דף הבית" class="trunc-text"> דף הבית</span>
                    </div>
                </a>



                <mat-divider></mat-divider> 
                
                <a routerLink="personal-area" routerLinkActive="side-part-selected">
                    <div class="side-part">
                        <div class="PerssonalAreaIcon Icon"></div>
                        איזור אישי
                    </div>
                </a>

                <a routerLink="institution-details" routerLinkActive="side-part-selected" (click)="toggle()">
                    <div class="side-part">
                        <div class="InstitutionIcon Icon"></div>
                        <span matTooltip="פרופיל מוסד" class="trunc-text"> פרופיל מוסד</span>
                    </div>
                </a> 
                 <mat-divider></mat-divider> 

                 <a routerLink="routes-list" routerLinkActive="side-part-selected" (click)="toggle()">
                    <div class="side-part">
                        <div class="RoutesIcon Icon"></div>
                        <span matTooltip="מסלולים" class="trunc-text">מסלולים</span>
                    </div>
                </a>

                <mat-divider></mat-divider> 

                <a routerLink="notifications" routerLinkActive="side-part-selected" (click)="toggle()">
                    <div *ngIf="this.dataService.newMessagesNumber > 0" class="msgNumber">
                        {{dataService.newMessagesNumber}}</div>
                    <div class="side-part">
                        <div class="NotificationsIcon Icon"></div>
                        <span matTooltip="הודעות" class="trunc-text">הודעות</span>
                    </div>

                </a>  -->

<!-- הערה בייצור -->
                <mat-divider></mat-divider>

                <a routerLink="contacts-list" routerLinkActive="side-part-selected" (click)="toggle()">
                    <div class="side-part ">
                        <div class="ContactsIcon Icon"></div>
                        <span matTooltip="תלמידים" class="trunc-text">תלמידים</span>
                    </div>
                </a>
                <mat-divider></mat-divider>

                <a routerLink="schedule-list" routerLinkActive="side-part-selected" (click)="toggle()">
                    <div class="side-part ">
                        <div class="ScheduleWhiteIcon Icon"></div>
                        <span matTooltip="מערכת שעות" class="trunc-text">מערכת שעות</span>
                    </div>
                </a>

                <mat-divider></mat-divider>

<!-- הערה בייצור -->
                <!-- <a routerLink="inquiries" routerLinkActive="side-part-selected" (click)="toggle()">
                    <div class="side-part ">
                        <div class="InquiriesIcon Icon"></div>
                        <span matTooltip="פניות" class="trunc-text">פניות</span>
                    </div>
                </a>

                <mat-divider></mat-divider> -->

<!-- הערה בייצור -->

<!--זמנית מוסתר בייצור -->
                <!-- <a matTooltip="לוח חופשות וימים מקוצרים" routerLink="vacation-calendar" routerLinkActive="side-part-selected" (click)="toggle()">
                    <div class="side-part ">
                        <div class="ScheduleWhiteIcon Icon"></div>
                        <span matTooltipClass="tooltip-style" class="trunc-text">לוח חופשות וימים מקוצרים</span>
                    </div>
                </a> 
                 <mat-divider></mat-divider> -->
<!--זמנית מוסתר בייצור -->
<!-- הערה בייצור -->
                <!-- <a matTooltip="מערכת שעות למוסד" routerLink="institotion-calendar" routerLinkActive="side-part-selected" (click)="toggle()">
                    <div class="side-part ">
                        <div class="ScheduleWhiteIcon Icon"></div>
                        <span matTooltipClass="tooltip-style" class="trunc-text">מערכת שעות למוסד</span>
                    </div>
                </a>
                <mat-divider></mat-divider> -->

<!-- הערה בייצור -->
                <a *ngIf="dataService.isLoggedIn" class="logoutBtn">
                    <!-- <button class="button"  (click)="dataService.TimeOut()"></button> -->
                    <div class="side-part">
                        <div class="logoutIcon Icon"></div>
                        <span> <button (click)="dataService.TimeOut()">התנתק</button></span>
                    </div>
                </a>               
                             
            </div>
            <a href="https://anydesk.com/en/downloads/thank-you?dv=win_exe" target='_blank'>
            <div  class="anydesk" >הורדת קישור לתמיכה מרחוק</div> 
            </a>
        </div>
    </div>



    <div class="content col-11">


        <div class="header">
            <div class="account-year-details">
                <!-- <div class="account-details">
                    <div *ngIf="dataService.isLoggedIn">{{dataService.userModel.InstitutionName+',
                        '+dataService.userModel.InstitutionNumber}}&nbsp; </div>
                    <div *ngIf="dataService.isLoggedIn">{{dataService.userModel.InstitutionNumber}}&nbsp;</div>

                </div> -->
                <div class="year-institution-container">

                     
                    <div *ngIf="dataService.isLoggedIn" id="current-institution">
                        <!-- <span>מוסד</span> -->
                        <select [(ngModel)]="dataService.selectedAccount" (change)="ChangeInstitution()">
                            <option *ngFor="let ins of dataService.institutions" [ngValue]="ins">
                                {{ins.InstitutionNumber}} , {{ins.InstitutionName}}
                            </option>
                        </select>
                    </div>

                    <div *ngIf="dataService.isLoggedIn" id="current-year">
                        <!-- <span>שנת לימודים</span> -->
                        <select *ngIf="dataService.isLoggedIn" [(ngModel)]="dataService.userModel.Year.value" (change)="ChangeYear($event)">
                            <option *ngFor="let year of dataService.years" [ngValue]="year.value">
                                {{year.viewValue}}
                            </option>
                        </select>
                    </div>
                    
                </div>
                



            </div>
            
            <div *ngIf="dataService.isLoggedIn" class="logo-parent">
                <div *ngIf="dataService.isLoggedIn" class="support_phone">מוקד תמיכה טכני : 0779998820</div>
                <div class="logout">
                    <button class="button" *ngIf="dataService.isLoggedIn" (click)="dataService.TimeOut()">התנתק</button>
                </div>
                <div [ngClass]="{ 'not-loggin': !dataService.isLoggedIn  }" class="profill-logo"></div>
            </div>


        </div>


        <div *ngIf="dataService.isLoggedIn" class="header-mobile">
            <button *ngIf="toggelSideBar==false" mat-button class="menu-mobile" (click)="toggle()"></button>
            <button *ngIf="toggelSideBar==true" mat-button class="menu-mobileX" (click)="toggle()"></button>

            <div class="mosad-details">
                <div class="account-details-mobile">{{dataService.userModel.InstitutionName+',' +dataService.userModel.InstitutionNumber}}
                </div>
                <div class="account-details-mobile">{{dataService.userModel.Year.viewValue}}</div>
            </div>
            <div  class="support_phone_mobile">מוקד תמיכה טכני : 0779998820</div> 
            <!-- <div class="logout-btn-mobile"><button *ngIf="dataService.isLoggedIn"
                    (click)="dataService.TimeOut()"></button></div> -->
            <div class="logo-mobile"></div>
        </div>

         
        <div [ngClass]="{'content-router-loggIn' : !dataService.isLoggedIn,
                        'content-router-loggedIn' : dataService.isLoggedIn}" class="content-router ">
            <router-outlet></router-outlet>
        </div>


    </div>
</div>