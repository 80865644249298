import { OptionSet } from './OptionSet';

export class StudentSchedule {
    public StudententNeedsId: string;
    public StudententId: string;
    public ScheduleStudententId: string;
    public Remarks: string;
    
    public Sunday_e: OptionSet;
    public Monday_e: OptionSet;
    public Tuesday_e: OptionSet;
    public Wednesday_e: OptionSet;
    public Thursday_e: OptionSet;
    public Friday_e: OptionSet;
    public Saturday_e: OptionSet;

    public Sunday_p: OptionSet;
    public Monday_p: OptionSet;
    public Tuesday_p: OptionSet;
    public Wednesday_p: OptionSet;
    public Thursday_p: OptionSet;
    public Friday_p: OptionSet;
    public Saturday_p: OptionSet;
}